import React, { useState } from 'react';
import Top10WeeklyRankedSheet from 'views/components/Top10/WeeklyRankedSheet/Top10WeeklyRankedSheet';
import { ITop10AccommodationsRegistrationModalProps } from 'views/components/Top10/Accommodations/Top10AccommodationsRegistrationModal';
import { ITop10WeeklyRankedSheetTableProps } from 'views/components/Top10/WeeklyRankedSheet/Top10WeeklyRankedSheetTable';

const Top10WeeklyRankedSheetContainer = () => {
  const [registrationModalVisible, setRegistrationModalVisible] = useState(
    false,
  );

  const showRegistrationModal = () => {
    setRegistrationModalVisible(true);
  };

  const hideRegistrationModal = () => {
    setRegistrationModalVisible(false);
  };

  const tableProps: ITop10WeeklyRankedSheetTableProps = {
    showReservationModal: showRegistrationModal,
  };

  const registrationModalProps: ITop10AccommodationsRegistrationModalProps = {
    visible: registrationModalVisible,
    hideModal: hideRegistrationModal,
  };

  return (
    <Top10WeeklyRankedSheet
      tableProps={tableProps}
      registrationModalProps={registrationModalProps}
    />
  );
};

export default Top10WeeklyRankedSheetContainer;
