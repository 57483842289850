import React, { useState, useEffect, MouseEvent } from 'react';
import VocSendFailHistory from '../../views/components/VocSendFailHistory';
import { IVocSendFailHistoryTableData } from '../../views/components/VocSendFailHistory/VocSendFailHistory';
import { fetchVocSendFailHistory, retryVocSend, resolveVocSendFailHistory } from '../../api/vocSendFailHistory';
import IVocSendFailHistory from '../../domain/vocSendFailHistory/IVocSendFailHistory';
import { message } from 'antd';
import {convertToTableData} from "../../services/vocSendFailHistory/VocSendFailHistoryConvertor";

const VocSendFailHistoryContainer = () => {
  const [tableSource, setTableSource] = useState<IVocSendFailHistoryTableData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [dataPopupShow, setDataPopupShow] = useState<boolean>(false);
  const [dataPopupContent, setDataPopupContent] = useState<string>('');
  const [retryButtonLoading, setRetryButtonLoading] = useState<boolean>(false);
  const [resolveButtonLoading, setResolveButtonLoading] = useState<boolean>(false);

  const fetchList = async () => {
    setTableLoading(true);
    try {
      const result = await fetchVocSendFailHistory();
      const response: IVocSendFailHistory[] = result.data;
      const source = response.map(it => convertToTableData(it));
      setTableSource(source);
    } catch (e) {
      console.log(e);
    }
    setTableLoading(false);
  };

  const handleShowDataPopup = (e: MouseEvent, id: number) => {
    const findSource = tableSource.find(it => it.id === id);
    if (findSource) {
      setDataPopupContent(JSON.stringify(findSource.data, null, 4));
      setDataPopupShow(true);
    }
  };

  const handleHideDataPopup = () => {
    setDataPopupShow(false);
  };

  const handleRetryVocSend = async (e: MouseEvent, id: number) => {
    setRetryButtonLoading(true);
    try {
      await retryVocSend(id);
      message.success('재전송 하였습니다.');
      await fetchList();
    } catch (e) {
      console.log(e);
    }
    setRetryButtonLoading(false);
  };

  const handleResolveHistory = async (e: MouseEvent, id: number) => {
    setResolveButtonLoading(true);
    try {
      await resolveVocSendFailHistory(id);
      message.success('삭제 하였습니다.');
      await fetchList();
    } catch (e) {
      console.log(e);
    }
    setResolveButtonLoading(false);
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <VocSendFailHistory
      source={tableSource}
      tableLoading={tableLoading}
      dataPopupShow={dataPopupShow}
      dataPopupContent={dataPopupContent}
      handleShowDataPopup={handleShowDataPopup}
      handleHideDataPopup={handleHideDataPopup}
      retryButtonLoading={retryButtonLoading}
      handleRetryVocSend={handleRetryVocSend}
      handleResolveHistory={handleResolveHistory}
      resolveButtonLoading={resolveButtonLoading}
    />
  );
};

export default VocSendFailHistoryContainer;
