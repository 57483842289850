export default {
  v2ApiHost: process.env.REACT_APP_API_HOST,
  mobileWebHost: process.env.REACT_APP_MOBILE_HOST,
  desktopWebHost: process.env.REACT_APP_DESKTOP_HOST,
  v2AdminHost: process.env.REACT_APP_V2_ADMIN_HOST,
  adminCenterHost: process.env.REACT_APP_ADMIN_CENTER_HOST,
  adminApiHost: process.env.REACT_APP_ADMIN_API_HOST,
  tourWebHost: process.env.REACT_APP_TOUR_WEB_HOST,
  v4ApiHost: process.env.REACT_APP_V4_API_HOST,
  skipLogin: process.env.REACT_APP_SKIP_LOGIN === 'true',
  accommodationWebHost: process.env.REACT_APP_ACCOMMODATION_HOST,
};
