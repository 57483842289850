import React, { useCallback, useEffect, useState } from 'react';
import { Card, Form, Input, Radio } from 'antd';
import '../../../styles/Overseas/PreReservationV2/ProductCreate.scss';
import ContentAddButton from './ContentAddButton';
import ContentForm from './ContentForm';
import { IContentType } from 'domain/preReservationV2/IProduct';
import {
  IContentForm,
  IContentGroupForm,
} from 'domain/preReservationV2/IProductCreateRequest';
import DeletePopconfirmButton from './DeletePopconfirmButton';

interface IContentGroupFormProps {
  index: number;
  defaultValue: IContentGroupForm;
  handleDelete: (index: number) => void;
  handleChange: (index: number, contentGroupForm: IContentGroupForm) => void;
}

const ContentGroupForm = ({
  index,
  defaultValue,
  handleChange,
  handleDelete,
}: IContentGroupFormProps) => {
  const [contents, setContents] = useState<IContentForm[]>(
    defaultValue.contents,
  );
  const [form] = Form.useForm();

  useEffect(() => {
    handleChangeContentGroupForm();
  }, [contents]);

  const handleChangeContentGroupForm = async () => {
    const data = await form.validateFields();
    let contentGroupForm = {
      title: data.title,
      isShowMoreButton: data.isShowMoreButton,
      contents: contents,
    };

    handleChange(index, contentGroupForm);
  };

  const handleAddContent = useCallback(
    (type: IContentType) => {
      setContents([
        ...contents,
        {
          type: type,
          source: '',
          link: '',
          text: '',
        },
      ]);
    },
    [contents],
  );

  const handleDeleteContent = useCallback(
    (index: number) => {
      const newContents = [...contents];
      newContents.splice(index, 1);
      setContents(newContents);
    },
    [contents],
  );

  const handleChangeContent = useCallback(
    (
      index: number,
      key: 'type' | 'source' | 'link' | 'text',
      value: string,
    ) => {
      const newContents = [...contents];
      newContents[index] = {
        ...newContents[index],
        [key]: value,
      };
      setContents(newContents);
    },
    [contents],
  );

  return (
    <Card
      title={`컨텐츠 그룹 - ${index + 1}`}
      extra={
        <DeletePopconfirmButton handleConfirm={() => handleDelete(index)} />
      }
      size="small"
      style={{ marginBottom: 16 }}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        initialValues={{
          isShowMoreButton: defaultValue.isShowMoreButton,
          title: defaultValue.title,
        }}
      >
        <Form.Item name="isShowMoreButton" label="더보기 버튼 활성화">
          <Radio.Group defaultValue={false} disabled>
            <Radio value={true}>사용함</Radio>
            <Radio value={false}>사용안함</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="title" label="제목">
          <Input placeholder="제목" />
        </Form.Item>
      </Form>

      {contents?.map((value, index) => {
        return (
          <ContentForm
            content={value}
            key={index}
            index={index}
            handleDelete={handleDeleteContent}
            handleChange={handleChangeContent}
          />
        );
      })}

      <ContentAddButton handleAddContent={handleAddContent} />
    </Card>
  );
};

export default ContentGroupForm;
