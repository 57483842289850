import React, { FC, MouseEvent } from 'react';
import { PageHeader, Table, Button, Modal, Popconfirm } from 'antd';
import '../../styles/VocSendFailHistory/VocSendFailHistory.scss';
import { IColumn } from '../../types/IColumn';

export interface IVocSendFailHistoryTableData {
  key: string;
  id: number;
  createdAt: string;
  category: string;
  reason: string;
  data: Map<string, any>;
}

interface IVocSendFailHistoryProps {
  source: IVocSendFailHistoryTableData[];
  tableLoading: boolean;
  dataPopupShow: boolean;
  dataPopupContent: string;
  handleShowDataPopup: (e: MouseEvent, id: number) => void;
  handleHideDataPopup: (e: MouseEvent) => void;
  retryButtonLoading: boolean;
  handleRetryVocSend: (e: MouseEvent, id: number) => void;
  handleResolveHistory: (e: MouseEvent, id: number) => void;
  resolveButtonLoading: boolean;
}

const generateColumns = (
  handleShowDataPopup: (e: React.MouseEvent, id: number) => void,
  handleRetryVocSend: (e: React.MouseEvent, id: number) => void,
  retryButtonLoading: boolean,
  handleResolveHistory: (e: React.MouseEvent, id: number) => void,
  resolveButtonLoading: boolean,
) => {
  const columns: IColumn<IVocSendFailHistoryTableData>[] = [
    {
      key: 'id',
      title: '#',
      dataIndex: 'id',
      render: text => <p>{text}</p>,
      width: 10,
    },
    {
      key: 'category',
      title: '전송유형',
      dataIndex: 'category',
      render: text => <p>{text}</p>,
      width: 15,
    },
    {
      key: 'createdAt',
      title: '실패일시',
      dataIndex: 'createdAt',
      render: text => <p>{text}</p>,
      width: 15,
    },
    {
      key: 'reason',
      title: '실패사유',
      dataIndex: 'reason',
      render: text => <p>{text}</p>,
      width: 50,
    },
    {
      key: 'data',
      title: '데이터',
      dataIndex: 'data',
      render: (text, recode) => <Button onClick={e => handleShowDataPopup(e, recode.id)}>데이터 보기</Button>,
      width: 5,
    },
    {
      key: 'retry-button',
      title: '재전송',
      dataIndex: 'retry-button',
      render: (text, recode) => (
        <Popconfirm
          title="정말 재전송하시겠습니까?"
          onConfirm={e => handleRetryVocSend(e!!, recode.id)}
          okText="네"
          cancelText="아니오"
        >
          <Button loading={retryButtonLoading}>재전송</Button>
        </Popconfirm>
      ),
      width: 5,
    },
    {
      key: 'resolve-button',
      title: '삭제',
      dataIndex: 'resolve-button',
      render: (text, recode) => (
        <Popconfirm
          title="정말 삭제하시겠습니까?"
          onConfirm={e => handleResolveHistory(e!!, recode.id)}
          okText="네"
          cancelText="아니오"
        >
          <Button ghost danger loading={resolveButtonLoading}>
            삭제
          </Button>
        </Popconfirm>
      ),
      width: 5,
    },
  ];
  return columns;
};

const VocSendFailHistory: FC<IVocSendFailHistoryProps> = ({
  source,
  tableLoading,
  dataPopupShow,
  dataPopupContent,
  handleShowDataPopup,
  handleHideDataPopup,
  retryButtonLoading,
  handleRetryVocSend,
  handleResolveHistory,
  resolveButtonLoading,
}) => {
  const columns = generateColumns(
    handleShowDataPopup,
    handleRetryVocSend,
    retryButtonLoading,
    handleResolveHistory,
    resolveButtonLoading,
  );

  return (
    <div className="VocSendFailHistory">
      <PageHeader className="page-header" title={<h1>VOC 전송 실패 목록</h1>} />
      <Table<IVocSendFailHistoryTableData> columns={columns} dataSource={source} loading={tableLoading} />
      <Modal
        title="전송 데이터"
        visible={dataPopupShow}
        onCancel={handleHideDataPopup}
        footer={[
          <Button key="close" onClick={handleHideDataPopup}>
            닫기
          </Button>,
        ]}
      >
        <pre>{dataPopupContent}</pre>
      </Modal>
    </div>
  );
};

export default VocSendFailHistory;
