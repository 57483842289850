import IPagination from '../../../../types/IPagination';
import React, { FC } from 'react';
import { Button, Col, Form, Input, Row, Table } from 'antd';
import IListRequest from 'domain/IListRequest';
import IOptionsFilter from 'domain/preReservationV2/IOptionsFilter';
import OptionTableColumns from './OptionTableColumns';
import { IOptionItemForm } from 'domain/preReservationV2/IProductCreateRequest';
import { IOption } from 'domain/preReservationV2/IOption';
import { updateOptionItems } from 'api/overseas/preReservationV2/option';
import IOptionItemUpdateRequest from 'domain/preReservationV2/IOptionItemUpdateRequest';

export interface IOptionTableData {
  key: string;
  option: IOption;
}

export interface IOptionTableProps {
  source: IOptionTableData[];
  pagination: IPagination;
  tableLoading: boolean;
  fetchList: (param: IListRequest<IOptionsFilter>) => void;
}

const OptionTable: FC<IOptionTableProps> = ({
  source,
  pagination,
  tableLoading,
  fetchList,
}) => {
  const handleOptionItemChange = async (
    id: number,
    optionItems: IOptionItemForm[],
  ) => {
    try {
      const request: IOptionItemUpdateRequest = {
        optionItems: optionItems,
      };
      await updateOptionItems(id, request);
      alert('수정완료');
    } catch (e) {
      console.log(e);
    }
  };

  const columns = OptionTableColumns({
    handleOptionItemChange: handleOptionItemChange,
  });
  const [form] = Form.useForm();
  async function handleSearch() {
    try {
      const values = await form.validateFields();
      const pageRequest: IListRequest<IOptionsFilter> = {
        filter: {
          displayName: values.displayName,
        },
      };
      fetchList(pageRequest);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Form
        form={form}
        className="PreReservationV2OptionTable"
        onFinish={handleSearch}
        initialValues={{
          displayName: '',
        }}
        style={{ marginBottom: 30 }}
      >
        <Row gutter={4} justify="end" align="top">
          <Form.Item name="displayName" style={{ margin: 3.5 }}>
            <Input placeholder="옵션명" />
          </Form.Item>
          <Col>
            <Button type="primary" htmlType="submit" style={{ margin: 3.5 }}>
              검색
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={source}
        pagination={pagination}
        loading={tableLoading}
      />
    </>
  );
};

export default OptionTable;
