import React, { ChangeEventHandler, FC } from 'react';
import { Card, Form, Input } from 'antd';
import { Editor, EditorState } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import '../../../styles/Overseas/PreReservationV2/ProductCreate.scss';
import { IContentType } from 'domain/preReservationV2/IProduct';
import { IContentForm } from 'domain/preReservationV2/IProductCreateRequest';

import draftToHtml from 'draftjs-to-html';
import DeletePopconfirmButton from './DeletePopconfirmButton';
import { convertToRaw } from 'draft-js';

interface IContentFormProps {
  index: number;
  content: IContentForm;
  handleDelete: (index: number) => void;
  handleChange: (
    index: number,
    key: 'type' | 'source' | 'link' | 'text',
    value: string,
  ) => void;
}

const ContentForm: FC<IContentFormProps> = ({
  index,
  content,
  handleDelete,
  handleChange,
}) => {
  const [form] = Form.useForm();

  const onEditorStateChange = (editorState: EditorState) => {
    handleChange(
      index,
      'text',
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
    );
  };

  const handleChangeSource = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(index, 'source', e.target.value);
  };

  const handleChangeLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(index, 'link', e.target.value);
  };

  return (
    <Card
      className="content"
      title={`컨텐츠 - ${index + 1} / type : ${content.type}`}
      extra={
        <DeletePopconfirmButton handleConfirm={() => handleDelete(index)} />
      }
      size="small"
    >
      <Form
        form={form}
        initialValues={{
          text: content.text,
          source: content.source,
          link: content.link,
        }}
      >
        {content.type == IContentType.TEXT ? (
          <Form.Item name="text" label="text">
            <Editor onEditorStateChange={onEditorStateChange} />
          </Form.Item>
        ) : null}
        {content.type == IContentType.VIDEO ||
        content.type == IContentType.IMAGE ? (
          <Form.Item name="source" label="소스">
            <Input placeholder="소스" onChange={handleChangeSource} />
          </Form.Item>
        ) : null}
        <Form.Item name="link" label="링크">
          <Input placeholder="링크" onChange={handleChangeLink} />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ContentForm;
