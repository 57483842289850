import React, { FC, MouseEvent, useState, useEffect, ChangeEvent } from 'react';
import IListRequest from '../../domain/IListRequest';
import ITripPlannerReservationListFilter from '../../domain/tripPlannerReservation/ITripPlannerReservationListFilter';
import { TripPlannerReservationHistoryModal } from '../../views/components/TripPlannerReservation/TripPlannerReservationModal';
import ITripPlannerReservationHistoryUpdateRequest from '../../domain/tripPlannerReservation/ITripPlannerReservationHistoryUpdateRequest';
import { message } from 'antd';
import { updateTripPlannerHistory } from '../../api/tripPlannerReservation';

export interface ITripPlannerHistoryModalContainerProp {
  visible: boolean;
  data: ITripPlannerReservationHistoryUpdateRequest;
  handleCancel: (e: MouseEvent) => void;
  fetchList: (param?: IListRequest<ITripPlannerReservationListFilter>) => void;
}

const TripPlannerHistoryModalContainer: FC<ITripPlannerHistoryModalContainerProp> = ({
  visible,
  data,
  handleCancel,
  fetchList,
}) => {
  const [text, setText] = useState<string>(data.counselorMemo);
  const [saveButtonLoadingVisible, setSaveButtonLoadingVisible] = useState<boolean>(false);

  const handleSave = async (e: MouseEvent) => {
    setSaveButtonLoadingVisible(true);
    try {
      const param: ITripPlannerReservationHistoryUpdateRequest = {
        id: data.id,
        counselorMemo: text,
      };
      await updateTripPlannerHistory(param);
      message.success('상담이력을 저장하였습니다.');
      fetchList();
      handleCancel(e);
    } catch (e) {
      console.log(e);
      message.error('상담이력 저장 시 오류가 발생하였습니다.');
    }
    setSaveButtonLoadingVisible(false);
  };
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  useEffect(() => {
    setText(data.counselorMemo);
  }, [data]);

  return (
    <TripPlannerReservationHistoryModal
      visible={visible}
      handleCancel={handleCancel}
      handleSave={handleSave}
      content={text}
      handleChange={handleChange}
      saveButtonLoadingVisible={saveButtonLoadingVisible}
    />
  );
};

export default TripPlannerHistoryModalContainer;
