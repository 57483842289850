import ICouponPeriod from '../ICouponPeriod';
import CouponIssuePlanViewModel from 'services/coupon/couponIssuePlan/CouponIssuePlanViewModel';
import TimezoneConvertor from 'infra/TimezoneConvertor';

export type IAddCouponIssuePlan = AddCouponIssuePlanToClip | AddCouponIssuePlanToPresent

export class AddCouponIssuePlanToClip {
  unitOfIssue: number;
  maximumIssueCount: number;
  issuePeriod: ICouponPeriod;

  private constructor(unitOfIssue: number, maximumIssueCount: number, issuePeriod: ICouponPeriod) {
    this.unitOfIssue = unitOfIssue;
    this.maximumIssueCount = maximumIssueCount;
    this.issuePeriod = issuePeriod;
  }

  public static fromUiData(uiData: CouponIssuePlanViewModel): AddCouponIssuePlanToClip {
    const utcTimes = TimezoneConvertor.localToUtc(uiData.issuePeriod[0], uiData.issuePeriod[1]);

    return new AddCouponIssuePlanToClip(
      uiData.unitOfIssue,
      uiData.maximumIssueCount,
      {
        inclusiveStartTimeUtc: utcTimes[0].format(),
        exclusiveEndTimeUtc: utcTimes[1].format(),
      },
    );
  }
}

export class AddCouponIssuePlanToPresent {
  unitOfIssue: number;
  presentScheduleUtc: string;
  userExcel: File;

  private constructor(
    unitOfIssue: number,
    presentScheduleUtc: string,
    userExcel: File,
  ) {
    this.unitOfIssue = unitOfIssue;
    this.presentScheduleUtc = presentScheduleUtc;
    this.userExcel = userExcel;
  }

  public static fromUiData(uiData: CouponIssuePlanViewModel): AddCouponIssuePlanToPresent {
    if(uiData.userExcelFile){
      return new AddCouponIssuePlanToPresent(
        uiData.unitOfIssue,
        TimezoneConvertor.localToUtc(uiData.presentScheduleUtc!)[0].format(),
        uiData.userExcelFile,
      );
    }
    else{
      throw "엑셀 파일을 업로드 해주세요";
    }

  }

}