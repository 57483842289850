import React, { useEffect, useState } from 'react';

type MaskTextProps = {
  maskType: MaskType;
  text: string;
};

export enum MaskType {
  NAME,
  MOBILE,
  EMAIL,
}

const MaskText = ({ maskType, text }: MaskTextProps) => {
  const [displayText, setDisplayText] = useState('');
  const [originalText, setOriginalText] = useState('');

  const maskText = (text: string) => {
    return text.replace(getRegByMaskType(), '*');
  };

  const getRegByMaskType = () => {
    let reg = null;
    switch (maskType) {
      case MaskType.NAME:
        reg = /(?<!^)./g;
        break;
      case MaskType.MOBILE:
        reg = /(?<=.{7})./g;
        break;
      case MaskType.EMAIL:
        reg = /(?<=.{2})./g;
        break;
    }
    return reg;
  };

  useEffect(() => {
    setOriginalText(text);
    setDisplayText(maskText(text));
  }, []);

  const handleMouseUp = () => {
    setDisplayText(originalText);
  };

  const handleMouseOut = () => {
    setDisplayText(maskText(originalText));
  };

  return (
    <span onMouseUp={handleMouseUp} onMouseOut={handleMouseOut}>
      {displayText}
    </span>
  );
};

export default MaskText;
