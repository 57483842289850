import { v2, admin } from '../infra/axios';
import { AxiosResponse } from 'axios';
import { ISupplier } from 'domain/Package';

export const fetchAgencies = () => v2.get('/admin/agencies');

export const fetchSuppliersRequest = (): Promise<AxiosResponse<ISuppliers>> => admin.get('/api/suppliers');

interface ISuppliers {
  items: ISupplier[];
}
