import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from "react-router";
import SupplierDetail from "views/components/Partners/Suppliers/SupplierDetail";

export interface MatchParams {
  supplierId: string;
}

const supplierDetailContainer: FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  return (
    <SupplierDetail
     supplierId={match.params.supplierId}/>
  );
};

export default withRouter(supplierDetailContainer);
