export default interface IProduct {
  id: string;
  isActive: boolean;
  elements: IElements;
  place: string;
  displayName: string;
  category: string;
  image: string;
  tags: string[];
  contentGroups: IContentGroup[];
  optionDescription: string;
  options: IOption[];
  log: ILog;
}

export interface IElements {
  head: IHead;
  toast: IToast;
}

export interface IHead {
  title: string;
  meta: IMeta;
}

export interface IMeta {
  description: string;
  og: IOg;
}

export interface IOg {
  image: string;
  title: string;
  description: string;
  url: string;
}

export interface IToast {
  title: string;
}

export interface IContentGroup {
  title: string;
  isShowMoreButton: boolean;
  contents: IContent[];
}

export interface IContent {
  type: IContentType;
  source: string | null;
  link: string | null;
  text: string | null;
}

export enum IContentType {
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export interface IOption {
  thumbnail: string;
  displayName: string;
  supplier: ISupplier;
  additionalInformation: string;
  items: IOptionItem[];
}

export interface ISupplier {
  name: string;
}

interface IOptionItem {
  departureDate: string;
  priceAdult: number;
  priceChild: number;
  priceBaby: number;
}

export interface ILog {
  campaign: string;
}
