import React from 'react';
import { IColumn } from 'views/types/IColumn';
import { ICouponSchemeTableData } from "views/components/Coupon/CouponSchema/CouponSchemaTable/CouponSchemaTable";

const columns: IColumn<ICouponSchemeTableData>[] = [
  {
    key: 'sequence',
    title: '스키마ID',
    dataIndex: 'sequence',
    render: text => <p>{text}</p>,
  },
  {
    key: 'name',
    title: '쿠폰스키마명',
    dataIndex: 'name',
    render: text => <p>{text}</p>,
  },
  {
    key: 'displayName',
    title: '쿠폰표시명',
    dataIndex: 'displayName',
    render: text => <p>{text}</p>,
  },
  {
    key: 'applicableItem',
    title: '쿠폰종류',
    dataIndex: 'applicableItem',
    render: text => <p>{text}</p>,
  },
  {
    key: 'discountKind',
    title: '할인방식',
    dataIndex: 'discountKind',
    render: text => <p>{text}</p>,
  },
  {
    key: 'discountFunction',
    title: '할인액/율',
    dataIndex: 'discountFunction',
    render: text => <p>{text}</p>,
  },
  {
    key: 'offeringPeriod',
    title: '유효기간',
    dataIndex: 'offeringPeriod',
    render: text => <p>{text}</p>,
  },
  {
    key: 'coverage',
    title: '발행범위',
    dataIndex: 'coverage',
    render: text => <p>{text}</p>,
  },
  {
    key: 'issueTarget',
    title: '발급대상',
    dataIndex: 'issueTarget',
    render: text => <p>{text}</p>,
  },
  {
    key: 'issueMethod',
    title: '발행방식',
    dataIndex: 'issueMethod',
    render: text => <p>{text}</p>,
  },
];

export default columns;
