import React, {
  createRef,
  forwardRef,
  RefObject,
  useImperativeHandle,
  useState,
} from 'react';
import { Button } from 'antd';
import '../../../styles/Overseas/PreReservationV2/ProductCreate.scss';
import { IProductGroupSectionForm } from 'domain/preReservationV2/IThemeCreateRequest';
import ProductGroupSectionForm, {
  IProductGroupSectionFormRefObject,
} from './ProductGroupSectionForm';

export interface IProductGroupSectionRefObject {
  getForm: () => Promise<IProductGroupSectionForm[]>;
}

const ProductGroupSection = forwardRef(({}, ref) => {
  const [productGroupSections, setProductGroupSections] = useState<
    RefObject<IProductGroupSectionFormRefObject>[]
  >([]);

  useImperativeHandle(ref, () => ({
    async getForm(): Promise<IProductGroupSectionForm[]> {
      return Promise.all(
        productGroupSections
          .filter(it => it.current !== undefined)
          .map(async it => await it.current!!.getForm()),
      );
    },
  }));

  const handleAddProductGroupSection = () => {
    setProductGroupSections([...productGroupSections, createRef()]);
  };

  const handleDeleteProductGroupSection = (
    ref: RefObject<IProductGroupSectionFormRefObject>,
  ) => {
    setProductGroupSections(productGroupSections.filter(it => it !== ref));
  };

  return (
    <>
      {productGroupSections.map((it, index) => (
        <ProductGroupSectionForm
          ref={it}
          key={index}
          index={index}
          handleDelete={handleDeleteProductGroupSection}
        />
      ))}

      <div className="center">
        <Button size="large" onClick={handleAddProductGroupSection}>
          상품그룹 추가
        </Button>
      </div>
    </>
  );
});

export default ProductGroupSection;
