import { v2 } from 'infra/axios';
import IListRequest from 'domain/IListRequest';
import IOndaReservationsFilter from 'domain/reservation/onda/IOndaReservationsFilter';
import IOndaCancelReservationRequest from 'domain/reservation/onda/IOndaCancelReservationRequest';

const fetchOndaReservation = (params: IListRequest<IOndaReservationsFilter>) =>
  v2.post(
    '/admin/api/reservations/domestic/accommodation/process-query/find',
    params,
  );

const apiCancelReservation = (params: IOndaCancelReservationRequest) =>
  v2.post(
    '/admin/api/reservations/domestic/accommodation/execute-command/cancel',
    params,
  );

const apiGetCancellationFee = (reservationCode: string) =>
  v2.get(
    `/admin/api/reservations/domestic/accommodation/${reservationCode}/cancellation-fee`,
  );

export { fetchOndaReservation, apiCancelReservation, apiGetCancellationFee };
