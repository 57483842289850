import React, { FunctionComponent } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Button } from 'antd';
import 'views/styles/Partners/Suppliers.scss';

const SuppliersSearchForm: FunctionComponent = prop => {
  return (
    <Form className="search-box">
      <Row>
        <Col span={4}>
          <Input placeholder="여행사" />
        </Col>
        <Col span={4}>
          <Input placeholder="대표관리자" />
        </Col>
        <Col span={4}>
          <Input placeholder="이메일" />
        </Col>
        <Col span={2}>
          <Button type="primary" icon={<SearchOutlined />}>
            검색
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SuppliersSearchForm;
