import React, { FC, FormEvent, useState } from 'react';
import { Input, Radio, DatePicker, message, Modal, Button } from 'antd';
import CouponSchemaRegistration from 'views/components/Coupon/CouponSchema/CouponSchemaRegistration';
import Form, { GetFieldDecoratorOptions, WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import IFormItem from 'views/types/IFormItem';
import moment, { Moment } from 'moment';
import { addCouponSchema } from 'api/couponSchema';
import ICouponSchemaAddData from 'domain/coupon/couponSchema/ICouponSchemaAddData';
import ICouponSchemaErrorBody from 'domain/coupon/couponSchema/ICouponSchemaErrorBody';
import ICouponListRequestParams from 'domain/coupon/ICouponListRequestParams';
import TimezoneConvertor from 'infra/TimezoneConvertor';
import { IssueMethod } from 'domain/coupon/couponSchema/IssueMethod';
import '@ant-design/compatible/assets/index.css';

const { RangePicker } = DatePicker;

interface ICouponSchemaRegistration {
  name: string;
  displayName: string;
  couponKind: string;
  discountKind: string;
  discountAmount: string;
  rowerLimit: string;
  offeringType: string;
  offeringPeriod: [Moment, Moment];
  coverage: string;
  target: string;
  issueMethod: IssueMethod;
}

//todo should be open this option, when server is standby.
const getFormItems = (
  getFieldDecorator: (id: string, options?: GetFieldDecoratorOptions) => (node: React.ReactNode) => React.ReactNode,
): IFormItem[] => {
  return [
    {
      label: '쿠폰 스키마명',
      span: 12,
      labelSpan: 6,
      wrapperSpan: 17,
      itemNode: getFieldDecorator('name', {
        rules: [{ required: true, message: '쿠폰 스키마명을 입력해주세요' }],
      })(<Input />),
    },
    {
      label: '쿠폰 표시명',
      span: 12,
      labelSpan: 6,
      wrapperSpan: 17,
      itemNode: getFieldDecorator('displayName', {
        rules: [{ required: true, message: '쿠폰 표시명을 입력해주세요' }],
      })(<Input />),
    },
    {
      label: '쿠폰 종류',
      span: 12,
      labelSpan: 6,
      wrapperSpan: 17,
      itemNode: getFieldDecorator('couponKind', {
        initialValue: 'RESERVATION',
      })(
        <Radio.Group>
          <Radio key="RESERVATION" value="RESERVATION">
            예약
          </Radio>
        </Radio.Group>,
      ),
    },
    {
      label: '할인 방식',
      span: 12,
      labelSpan: 6,
      wrapperSpan: 17,
      itemNode: getFieldDecorator('discountKind', {
        initialValue: 'Amount',
      })(
        <Radio.Group>
          <Radio key="AMOUNT" value="Amount">
            정액할인
          </Radio>
        </Radio.Group>,
      ),
    },
    {
      label: '할인 금액',
      span: 12,
      labelSpan: 6,
      wrapperSpan: 17,
      itemNode: getFieldDecorator('discountAmount', {
        rules: [{ required: true, message: '할인 금액을 입력해주세요' }],
      })(<Input type="number" min={0} addonAfter="원 할인" />),
    },
    {
      label: '최소결제금액',
      span: 12,
      labelSpan: 6,
      wrapperSpan: 17,
      itemNode: getFieldDecorator('rowerLimit', {
        rules: [{ required: true, message: '할인 금액을 입력해주세요' }],
      })(<Input type="number" min={0} addonBefore="최소" addonAfter="만원" />),
    },
    {
      label: '유효 기간',
      span: 6,
      labelSpan: 13,
      wrapperOffset: 2,
      wrapperSpan: 9,
      itemNode: getFieldDecorator('offeringType', {
        initialValue: 'PERIOD',
      })(
        <Radio.Group>
          <Radio key="PERIOD" value="PERIOD">
            사용기간 지정
          </Radio>
        </Radio.Group>,
      ),
    },
    {
      label: '',
      span: 18,
      labelSpan: 0,
      wrapperSpan: 23,
      itemNode: getFieldDecorator('offeringPeriod', {
        rules: [{ type: 'array', required: true, message: '기간을 선택해 주세요.' }],
      })(
        <RangePicker
          format="YYYY-MM-DD HH:mm"
          placeholder={['시작일시', '종료일시']}
          showTime={{
            hideDisabledOptions: true,
            defaultValue: [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')],
          }}
        />,
      ),
    },
    {
      label: '발행범위',
      span: 12,
      labelSpan: 6,
      wrapperSpan: 17,
      itemNode: getFieldDecorator('coverage', {
        initialValue: 'ALL',
      })(
        <Radio.Group>
          <Radio key="ALL" value="ALL">
            전상품
          </Radio>
        </Radio.Group>,
      ),
    },
    {
      label: '발급방식',
      span: 12,
      labelSpan: 6,
      wrapperSpan: 17,
      itemNode: getFieldDecorator('issueMethod', {
        initialValue: 'Clip',
      })(
        <Radio.Group>
          <Radio key="Clip" value="Clip">
            다운로드
          </Radio>
          <Radio key="Present" value="Present">
            지급
          </Radio>
        </Radio.Group>,
      ),
    },
  ];
};

const convertUiDataToDomain = (data: ICouponSchemaRegistration): ICouponSchemaAddData => {
  const localPeriod = TimezoneConvertor.localToUtc(data.offeringPeriod[0], data.offeringPeriod[1]);
  const minimumOrderAmount = Number(data.rowerLimit) * 10000;
  const amount = Number(data.discountAmount);
  return {
    name: data.name,
    displayName: data.displayName,
    validityPeriod: {
      inclusiveStartTimeUtc: localPeriod[0].format(),
      exclusiveEndTimeUtc: localPeriod[1].format(),
    },
    issueMethod: data.issueMethod,
    usageRestriction: {
      minimumOrderAmount: minimumOrderAmount,
    },
    discount: {
      kind: data.discountKind,
      amount: amount,
    },
  };
};

export interface CouponSchemaRegistrationContainerProps {
  form: WrappedFormUtils;
  modelVisible: boolean;
  handleCancel: (e?: React.MouseEvent<HTMLElement>) => void;
  fetchList: (request: ICouponListRequestParams) => void;
}

const CouponSchemaRegistrationContainer: FC<CouponSchemaRegistrationContainerProps> = ({
  form,
  modelVisible,
  handleCancel,
  fetchList,
}) => {
  const { getFieldDecorator, validateFields, resetFields } = form;
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const formItems = getFormItems(getFieldDecorator);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const data: ICouponSchemaRegistration = values;
        handleAddCouponSchema(data);
      }
    });
  };

  const handleAddCouponSchema = async (data: ICouponSchemaRegistration) => {
    setSubmitButtonLoading(true);

    try {
      const domain = convertUiDataToDomain(data);
      await addCouponSchema(domain);
      message.success('저장 성공하였습니다.');
    } catch (e) {
      let errorMessage = '';
      if (e.response) {
        const body: ICouponSchemaErrorBody = e.response.data;
        errorMessage = body.message;
      } else {
        console.log(e);
        errorMessage = '오류가 발생하였습니다. 관리자에게 문의하시기 바랍니다.';
      }
      message.error(errorMessage);
    }

    fetchList({ skip: 0, take: 10 });
    resetFields();
    handleCancel();
    setSubmitButtonLoading(false);
  };

  return (
    <Modal
      title="쿠폰 스키마 등록"
      visible={modelVisible}
      width={1100}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          취소
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit} loading={submitButtonLoading}>
          등록
        </Button>,
      ]}
    >
      <CouponSchemaRegistration formItems={formItems} />
    </Modal>
  );
};

export default Form.create<CouponSchemaRegistrationContainerProps>()(CouponSchemaRegistrationContainer);
