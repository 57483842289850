import { v2 } from 'infra/axios';
import IListRequest from 'domain/IListRequest';
import IOptionsFilter from 'domain/preReservationV2/IOptionsFilter';
import IOptionItemUpdateRequest from 'domain/preReservationV2/IOptionItemUpdateRequest';

const fetchOptions = (params: IListRequest<IOptionsFilter>) =>
  v2.post(
    '/admin/api/overseas/pre-reservations-v2/options/process-query/find',
    params,
  );

const updateOptionItems = (id: number, request: IOptionItemUpdateRequest) =>
  v2.post(
    `/admin/api/overseas/pre-reservations-v2/option/${id}/items`,
    request,
  );

export { fetchOptions, updateOptionItems };
