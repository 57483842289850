import axios from 'axios';

const v2 = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
});

const v4 = axios.create({
  baseURL: process.env.REACT_APP_V4_API_HOST,
  withCredentials: true,
});

const admin = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_API_HOST,
});

const partners = axios.create({
  baseURL: process.env.REACT_APP_PARTNERS_API_HOST,
});

export { v2, v4, admin, partners };
