import React, { MouseEvent } from 'react';
import { IColumn } from '../../../types/IColumn';
import { ITripPlannerReservationTableData } from './TripPlannerReservationTable';
import { CopyOutlined, InfoCircleOutlined, WechatOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Row } from 'antd';
import { TripPlannerReservationStatus } from '../../../../domain/tripPlannerReservation/TripPlannerReservationStatus';

interface ITripPlannerReservationTableColumnsProp {
  handleQuestionModalShow: (e: MouseEvent, targetId: string) => void;
  handleClipboardModalShow: (e: MouseEvent, targetId: string) => void;
  handleHistoryModalShow: (e: MouseEvent, targetId: string) => void;
  handleChangeStatus: (e: MouseEvent, targetId: string, status: TripPlannerReservationStatus) => void;
  status: string;
}
const TripPlannerReservationTableColumns: (
  prop: ITripPlannerReservationTableColumnsProp,
) => IColumn<ITripPlannerReservationTableData>[] = ({
  handleQuestionModalShow,
  handleClipboardModalShow,
  handleHistoryModalShow,
  handleChangeStatus,
  status,
}) => {
  const basicColumns: IColumn<ITripPlannerReservationTableData>[] = [
    {
      key: 'id',
      title: '#',
      dataIndex: 'id',
      render: text => <p>{text}</p>,
    },
    {
      key: 'name',
      title: '예약자명',
      dataIndex: 'name',
      render: text => <p>{text}</p>,
    },
    {
      key: 'headCount',
      title: '인원',
      dataIndex: 'headCount',
      render: (text, recode) => {
        const headCount = recode.headCount;
        return (
          <p>
            {headCount.adult + headCount.child + headCount.baby}({headCount.adult}/{headCount.child}/{headCount.baby})
          </p>
        );
      },
    },
    {
      key: 'createdAt',
      title: '예약일시',
      dataIndex: 'createdAt',
      render: text => <p>{text}</p>,
    },
    {
      key: 'agencyName',
      title: '여행사',
      dataIndex: 'agencyName',
      render: text => <p>{text}</p>,
    },
    {
      key: 'pcode',
      title: '행사코드',
      dataIndex: 'pcode',
      render: text => <p>{text}</p>,
    },
    {
      key: 'title',
      title: '행사명',
      dataIndex: 'title',
      render: text => <p>{text}</p>,
    },
    {
      key: 'departureAt',
      title: '출발일',
      dataIndex: 'departureAt',
      render: text => <p>{text}</p>,
    },
    {
      key: 'listingPrice',
      title: '가격',
      dataIndex: 'listingPrice',
      render: text => <p>{text}</p>,
    },
    {
      key: 'sellingPrice',
      title: '특가',
      dataIndex: 'sellingPrice',
      render: text => <p>{text}</p>,
    },
    {
      key: 'status',
      title: '상태',
      dataIndex: 'status',
      render: text => <p>{text}</p>,
    },
  ];

  const actionColumn: IColumn<ITripPlannerReservationTableData>[] = [
    {
      key: 'action-buttons',
      title: '',
      dataIndex: 'action-buttons',
      render: (text, recode) => (
        <div>
          <Button key="wechat" icon={<WechatOutlined />} onClick={e => handleHistoryModalShow(e, recode.id)} />
          <Button
            key="info-circle"
            icon={<InfoCircleOutlined />}
            onClick={e => handleQuestionModalShow(e, recode.id)}
          />
          <Button key="copy" icon={<CopyOutlined />} onClick={e => handleClipboardModalShow(e, recode.id)} />
        </div>
      ),
    },
  ];

  const getColumnsByStatus = function(status: string): IColumn<ITripPlannerReservationTableData>[] {
    if (status === TripPlannerReservationStatus.APPLY) {
      return [
        {
          key: 'change-status-button',
          title: '변경',
          dataIndex: 'change-status-button',
          render: (text, recode) => (
            <Popconfirm
              title="정말 상담진행하시겠습니까?"
              onConfirm={e => handleChangeStatus(e!!, recode.id, TripPlannerReservationStatus.COUNSEL)}
              okText="네"
              cancelText="아니오"
            >
              <Button>상담진행</Button>
            </Popconfirm>
          ),
        },
      ];
    }

    if (status === TripPlannerReservationStatus.COUNSEL) {
      return [
        {
          key: 'change-status-button',
          title: '변경',
          dataIndex: 'change-status-button',
          render: (text, recode) => (
            <Row className="table-button-box">
              <Popconfirm
                title="정말 보류하시겠습니까?"
                onConfirm={e => handleChangeStatus(e!!, recode.id, TripPlannerReservationStatus.HOLD)}
                okText="네"
                cancelText="아니오"
              >
                <Button type="dashed">보류</Button>
              </Popconfirm>
              <Button>상품추천</Button>
              <Button>예약성공</Button>
              <Button danger>예약취소</Button>
            </Row>
          ),
        },
        {
          key: 'plannerName',
          title: '트립플래너',
          dataIndex: 'plannerName',
          render: text => <p>{text}</p>,
        },
      ];
    }

    return [];
  };

  return basicColumns.concat(getColumnsByStatus(status), actionColumn);
};

export default TripPlannerReservationTableColumns;
