import React, { FC, useState } from 'react';
import { Button, message, Modal, notification, Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons/lib';
import * as XLSX from 'xlsx';
import Top10AccommodationInsertData from 'domain/top10/accommodation/Top10AccommodationInsertData';
import { apiUpsertAccommodations } from 'api/top10/top10Accommodations';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { Dimmer } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ArgsProps } from 'antd/lib/notification';

export interface ITop10AccommodationsRegistrationModalProps {
  visible: boolean;
  hideModal: () => void;
}

const Top10AccommodationsRegistrationModal: FC<ITop10AccommodationsRegistrationModalProps> = ({
  visible,
  hideModal,
}) => {
  const [excelData, setExcelData] = useState<any[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [dimmed, setDimmed] = useState(false);

  const resetUploadedData = () => {
    setExcelData([]);
    setFileList([]);
  }

  const openNotification = (success: boolean) => {
    let args: ArgsProps;
    if (success) {
      args = {
        message: '업로드 성공!',
        description:
          fileList[0].name +
          ' 업로드를 성공하였습니다.\n' +
          new Date().toLocaleString(),
        duration: 0,
      };
    } else {
      args = {
        message: '업로드 실패!',
        description:
          fileList[0].name +
          ' 업로드를 실패하였습니다.\n' +
          new Date().toLocaleString(),
        duration: 0,
      };
    }
    notification.open(args);
  };

  const upsertAccommodations = async () => {
    hideModal();
    if (excelData.length === 0) {
      message.error('파일을 업로드하세요!', 2);
      return false;
    }

    setDimmed(true);

    const dataWithoutHeader = excelData.filter(
      data => excelData.indexOf(data) !== 0 && excelData.indexOf(data) !== 1,
    );
    try {
      const accommodationsInsertData = dataWithoutHeader.map(accommodation => {
        return new Top10AccommodationInsertData(accommodation);
      });

      const params = {
        accommodations: accommodationsInsertData,
      };
      await apiUpsertAccommodations(params);
      setDimmed(false);
      openNotification(true);
    } catch (e) {
      setDimmed(false);
      openNotification(false);
      console.log(e);
    }
    resetUploadedData();
  };

  const handleCancel = () => {
    resetUploadedData();
    hideModal();
  };

  const handleChange = (info: UploadChangeParam) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setFileList(fileList);
  };

  const props = {
    accept: '.csv',
    onChange: handleChange,
    beforeUpload: (file: Blob) => {
      const reader = new FileReader();
      reader.onload = e => {
        if (e.target != null) {
          const workBook = XLSX.read(e.target.result, { type: 'binary' });
          const firstSheetName = workBook.SheetNames[0];
          const workSheet = workBook.Sheets[firstSheetName];
          const accommodations = XLSX.utils.sheet_to_json(workSheet, {
            header: 1,
          });
          setExcelData(accommodations);
        }
      };
      reader.readAsText(file);
      return false;
    },
  };

  return (
    <>
      <Modal
        title="숙소정보 업로드"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleCancel}>
            취소
          </Button>,
          <Button key="upload" onClick={upsertAccommodations}>
            업로드
          </Button>,
        ]}
        width={600}
      >
        <Upload {...props} fileList={fileList}>
          <Button icon={<UploadOutlined />}>Upload csv only</Button>
        </Upload>
      </Modal>
      <Dimmer active={dimmed}>
        <Spin size="large" />
      </Dimmer>
    </>
  );
};

export default Top10AccommodationsRegistrationModal;
