import YouTubeExcelTemplate from 'domain/top10/accommodation/template/YouTubeExcelTemplate';

class YouTubeVideoPublisher {
  name: string;

  constructor(name: string) {
    this.name = name;
  }
}

class YouTubeVideo {
  id: string;
  publisher: YouTubeVideoPublisher;

  constructor(id: string, publisher: YouTubeVideoPublisher) {
    this.id = id;
    this.publisher = publisher;
  }
}

export default class YouTube {
  videos: YouTubeVideo[];

  constructor(videos: YouTubeVideo[]) {
    this.videos = videos;
  }

  static of(...args: YouTubeExcelTemplate[]) {
    const youtubeVideos = args
      .filter(youtube => youtube.id !== undefined)
      .map(
        value =>
          new YouTubeVideo(
            value.id,
            new YouTubeVideoPublisher(value.publisherName),
          ),
      );
    return new YouTube(youtubeVideos);
  }
}
