import { v2 } from 'infra/axios';
import IListRequest from 'domain/IListRequest';
import IProductsFilter from 'domain/preReservationV2/IProductsFilter';
import IProductCreateRequest from 'domain/preReservationV2/IProductCreateRequest';

const fetchProducts = (params: IListRequest<IProductsFilter>) =>
  v2.post(
    '/admin/api/overseas/pre-reservations-v2/products/process-query/find',
    params,
  );

const postProduct = (request: IProductCreateRequest) =>
  v2.post('/api/overseas/pre-reservations-v2/products', request);

const fetchProductSummaries = () =>
  v2.post('/admin/api/overseas/pre-reservations-v2/product/summaries');

export { fetchProducts, postProduct, fetchProductSummaries };
