import {
  IPayment,
  IReservation,
} from 'domain/reservation/onda/IOndaReservation';
import { Button } from 'antd';
import React, { MouseEvent } from 'react';
import moment from 'moment';
import { CopyOutlined } from '@ant-design/icons';
import MaskText, { MaskType } from 'views/utils/MaskText';
import env from 'infra/envMap';

const OndaReservationTableColumns = (
  cancelReservation: (reservationCode: string) => void,
  handleCancellationPolicyModalShow: (
    e: MouseEvent,
    reservationCode: string,
  ) => void,
  handleDetailsModalShow: (e: MouseEvent, reservationCode: string) => void,
  showCancellationPenaltyModal: (
    e: MouseEvent,
    reservationCode: string,
    sellingPrice: number,
  ) => void,
) => {
  const columns = [
    {
      key: 'reservationCode',
      title: '예약코드',
      dataIndex: 'reservation',
      render: (reservation: IReservation) => `${reservation.reservationCode}`,
    },
    {
      key: 'bookerName',
      title: '예약자명',
      dataIndex: 'reservation',
      render: (reservation: IReservation) => {
        return (
          <MaskText maskType={MaskType.NAME} text={reservation.booker.name} />
        );
      },
    },
    {
      key: 'bookerPhone',
      title: '전화번호',
      dataIndex: 'reservation',
      render: (reservation: IReservation) => {
        return (
          <MaskText
            maskType={MaskType.MOBILE}
            text={reservation.booker.phone}
          />
        );
      },
    },
    {
      key: 'supplierName',
      title: '공급자명',
      dataIndex: 'reservation',
      render: (reservation: IReservation) => `${reservation.supplier.name}`,
    },
    {
      key: 'accommodationName',
      title: '숙소명',
      dataIndex: 'reservation',
      render: (reservation: IReservation) => {
        return (
          <a
            href={`${env.accommodationWebHost}/domestic/accommodations/details/${reservation.accommodation.id}?adult=${reservation.guest.adultCount}&checkin=${reservation.checkIn}&checkout=${reservation.checkOut}`}
            target={'_blank'}
          >
            {reservation.accommodation.name}
          </a>
        );
      },
    },
    {
      key: 'roomRateName',
      title: '상품명',
      dataIndex: 'reservation',
      render: (reservation: IReservation) => `${reservation.roomRate.name}`,
    },
    {
      key: 'sellingPrice',
      title: '최종금액',
      dataIndex: 'reservation',
      render: (reservation: IReservation) =>
        `${reservation.pricing.sellingPrice.appendThousandComma()}`,
    },
    {
      key: 'reservationStatus',
      title: '예약상태',
      dataIndex: 'reservation',
      render: (reservation: IReservation) => `${reservation.status}`,
    },
    {
      key: 'paymentStatus',
      title: '결제상태',
      dataIndex: 'payment',
      render: (payment: IPayment) => {
        if (payment.status === '결제실패') {
          return `${payment.status}(${payment.stepStatus})`;
        } else {
          return `${payment.status}`;
        }
      },
    },
    {
      key: 'createdAt',
      title: '예약일시',
      dataIndex: 'reservation',
      render: (reservation: IReservation) => `${reservation.createdAt}`,
    },
    {
      key: 'details',
      title: '추가정보',
      dataIndex: 'reservation',
      render: (reservation: IReservation) => {
        return (
          <Button
            key="copy"
            icon={<CopyOutlined />}
            onClick={(e) =>
              handleDetailsModalShow(e, reservation.reservationCode)
            }
          />
        );
      },
    },
    {
      key: 'cancel',
      title: '취소처리',
      dataIndex: 'reservation',
      render: (reservation: IReservation) => {
        if (
          (reservation.status === '예약완료' ||
            reservation.status === '취소실패') &&
          moment(new Date()).startOf('day') <= moment(reservation.checkIn)
        ) {
          return (
            <Button
              danger
              onClick={(e) =>
                showCancellationPenaltyModal(
                  e,
                  reservation.reservationCode,
                  reservation.pricing.sellingPrice,
                )
              }
            >
              예약취소
            </Button>
          );
        }
      },
    },
    {
      key: 'cancellationFee',
      title: '취소수수료',
      dataIndex: 'reservation',
      render: (reservation: IReservation) => {
        if (reservation.refundAmount !== null) {
          return `${(
            reservation.pricing.sellingPrice - reservation.refundAmount
          ).appendThousandComma()}`;
        }
      },
    },
    {
      key: 'cancelledAt',
      title: '취소일시',
      dataIndex: 'reservation',
      render: (reservation: IReservation) => {
        if (
          reservation.cancellation !== null &&
          reservation.cancellation.cancelledAt
        ) {
          return `${reservation.cancellation.cancelledAt}`;
        }
      },
    },
  ];

  return columns;
};

export default OndaReservationTableColumns;
