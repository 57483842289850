import React, { FC, forwardRef, RefObject, useImperativeHandle } from 'react';
import { Card, Form, Input } from 'antd';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import '../../../styles/Overseas/PreReservationV2/ProductCreate.scss';
import {
  IOptionForm,
  IOptionItemForm,
} from 'domain/preReservationV2/IProductCreateRequest';
import DeletePopconfirmButton from './DeletePopconfirmButton';
import OptionItemUpload from './OptionItemUpload';

interface IOptionFormProps {
  index: number;
  ref: React.Ref<unknown>;
  handleDelete: (ref: RefObject<IOptionFormRefObject>) => void;
}

export interface IOptionFormRefObject {
  getForm: () => Promise<IOptionForm>;
}

const OptionForm: FC<IOptionFormProps> = forwardRef(
  ({ index, handleDelete }: IOptionFormProps, ref) => {
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
      async getForm(): Promise<IOptionForm> {
        const data = await form.validateFields();

        ensureValidateForm(data);

        return {
          thumbnail: data.thumbnail,
          displayName: data.displayName,
          supplier: {
            name: data.supplierName,
          },
          additionalInformation: data.additionalInformation,
          items: JSON.parse(data.optionItems),
        };
      },
    }));

    const ensureValidateForm = (data: any) => {
      if (!data.optionItems) {
        throw Error('일별 가격정보를 입력하세요');
      }
    };

    const handleOptionItemUploadSuccess = (
      optionItems: IOptionItemForm[],
      lowestPrice: number,
    ) => {
      form.setFieldsValue({
        optionItems: JSON.stringify(optionItems),
        lowestPrice: lowestPrice,
      });
    };

    return (
      <Card
        title={`상품 - ${index + 1}`}
        extra={
          <DeletePopconfirmButton
            handleConfirm={() =>
              handleDelete(ref as RefObject<IOptionFormRefObject>)
            }
          />
        }
        size="small"
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          initialValues={{
            thumbnail: '',
            displayName: '',
            supplierName: '',
            additionalInformation: '',
            optionItems: '',
            lowestPrice: 0,
          }}
        >
          <Form.Item name="thumbnail" label="썸네일">
            <Input placeholder="썸네일" />
          </Form.Item>
          <Form.Item name="displayName" label="상품이름">
            <Input placeholder="상품이름" />
          </Form.Item>
          <Form.Item name="supplierName" label="여행사 이름">
            <Input placeholder="여행사 이름" />
          </Form.Item>
          <Form.Item name="additionalInformation" label="추가정보">
            <Input placeholder="추가정보" />
          </Form.Item>
          <Form.Item label="일별가격정보">
            <OptionItemUpload
              handleUploadSuccess={handleOptionItemUploadSuccess}
            />
            <Form.Item name="optionItems" hidden>
              <Input hidden placeholder="으아아아" />
            </Form.Item>
          </Form.Item>
          <Form.Item name="lowestPrice" label="최저가">
            <Input placeholder="" disabled />
          </Form.Item>
        </Form>
      </Card>
    );
  },
);

export default OptionForm;
