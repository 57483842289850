import ICouponIssuePlanData from 'domain/coupon/couponIssuePlan/ICouponIssuePlanData';
import { ICouponIssuePlanTableData } from 'views/components/Coupon/CouponIssuePlan/CouponIssuePlanTable';
import TimezoneConvertor from 'infra/TimezoneConvertor';
import moment from 'moment';
import { CouponIssuePlanEnum } from 'domain/coupon/couponIssuePlan/CouponIssuePlanEnum';
import ICouponListRequestParams from 'domain/coupon/ICouponListRequestParams';
import IPagination from 'views/types/IPagination';
import ICouponSchemaData from 'domain/coupon/couponSchema/ICouponSchemaData';
import { ICouponSchemaSelectProps } from 'containers/coupon/couponIssuePlan/CouponIssuePlanRegistrationModalContainer';
import { IssueMethod } from 'domain/coupon/couponSchema/IssueMethod';
import 'infra/NumberExtension';

export const convertToTableData = (domain: ICouponIssuePlanData): ICouponIssuePlanTableData => {
  const discountType = domain.schema.discount.kind === 'Amount' ? '정액할인' : '';
  const discountAmountText = `${domain.schema.discount.amount}원`;
  const dateTimeFormat = 'YYYY-MM-DD HH:mm';

  function getIssuePeriodLocal() {
    if (domain.issuePeriod) {
      let issuePeriodLocal = TimezoneConvertor.utcToLocal(
        moment.utc(domain.issuePeriod.inclusiveStartTimeUtc),
        moment.utc(domain.issuePeriod.exclusiveEndTimeUtc),
      );
      return `${issuePeriodLocal[0].format(dateTimeFormat)} ~ ${issuePeriodLocal[1].format(
        dateTimeFormat,
      )}`;
    } else {
      let issuePeriodLocal = TimezoneConvertor.utcToLocal(
        moment.utc(domain.presentationTimeUtc),
      );
      return issuePeriodLocal[0].format(dateTimeFormat);
    }
  }

  function getNumberOfPeopleForPresentation() {
    return domain.issuePeriod ? '-' : (domain.reportSummary.totalCoupons / domain.unitOfIssue).appendThousandComma();
  }


  const validityPeriodLocal = TimezoneConvertor.utcToLocal(
    moment.utc(domain.schema.validityPeriod.inclusiveStartTimeUtc),
    moment.utc(domain.schema.validityPeriod.exclusiveEndTimeUtc),
  );
  const validityPeriodText = `${validityPeriodLocal[0].format(dateTimeFormat)} ~ ${validityPeriodLocal[1].format(
    dateTimeFormat,
  )}`;

  const unIssuedCount = domain.reportSummary.totalCoupons - domain.reportSummary.issuedCoupons;
  const unUsedCount = domain.reportSummary.issuedCoupons - domain.reportSummary.redeemedCoupons;
  const status = CouponIssuePlanEnum[domain.status as keyof typeof CouponIssuePlanEnum];

  return {
    key: domain.id,
    id: domain.id,
    sequence: domain.sequence,
    schemaName: domain.schema.name,
    schemaDisplayName: domain.schema.displayName,
    discountType: discountType,
    discountAmountText: discountAmountText,
    issuePeriodText: getIssuePeriodLocal(),
    validityPeriodText: validityPeriodText,
    totalCount: domain.reportSummary.totalCoupons.appendThousandComma(),
    issuedCount: domain.reportSummary.issuedCoupons.appendThousandComma(),
    usedCount: domain.reportSummary.redeemedCoupons.appendThousandComma(),
    unIssuedCount: unIssuedCount.appendThousandComma(),
    unUsedCount: unUsedCount.appendThousandComma(),
    status: status,
    numberOfPeopleForPresentation: getNumberOfPeopleForPresentation(),
  };
};

export const convertToPagination = (
  request: ICouponListRequestParams,
  total: number,
  handlePageChange: (page: number, pageSize?: number) => void,
): IPagination => {
  return {
    total: total,
    current: request.skip / request.take + 1,
    pageSize: request.take,
    showSizeChanger: false,
    onShowSizeChange: () => {
    },
    onChange: handlePageChange,
  };
};

export const convertToSelectData = (domain: ICouponSchemaData): ICouponSchemaSelectProps => {
  return {
    key: domain.id,
    id: domain.id,
    title: domain.name,
    issueMethod: domain.issueMethod ? domain.issueMethod : IssueMethod.Clip,
  };
};
