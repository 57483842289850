import React, { FC, useCallback, useReducer, useEffect } from 'react';
import { PageHeader, Divider, Typography } from 'antd';
import { fetchSuppliersRequest } from 'api/agency';
import specialPricingReducer, { initialState, fetchPackages, fetchSuppliers } from 'modules/specialPricingReducer';
import SpecialPricingSearchForm from './SpecialPricingSearchForm';
import SpecialPricingSearchResult from './SpecialPricingSearchResult';
import searchTargetPackages from './searchTargetPackages';
import { IPackageFilters } from 'domain/Package';
import './SpecialPricingPage.scss';

interface ISpecialPricingPage {}

const SpecialPricingPage: FC<ISpecialPricingPage> = () => {
  const [state, dispatch] = useReducer(specialPricingReducer, initialState);

  const getPackages = useCallback(async (keyword: string[]) => {
    dispatch({ type: fetchPackages.request });
    try {
      const packages = await searchTargetPackages(keyword);
      // API 완료되면 제거
      const dummy = packages.map((x, i) => ({
        ...x,
        specialOffer:
          i % 3 === 0
            ? {
                specialPricingPolicy: {
                  id: 'c57a34cf-4fa5-489e-9379-10d95da3f7fc',
                  pricingStrategy: {
                    priority: 0,
                    offeringPeriod: {
                      inclusiveStartUtc: '2020-05-06T02:30:38.7299485Z',
                      exclusiveEndUtc: '9999-12-31T23:59:59.9999999',
                    },
                    badges: [
                      {
                        label: '일반특가',
                      },
                    ],
                    discountFunctions: {
                      supplierFunctions: {
                        adultFunction: {
                          amount: 50000,
                        },
                        childFunction: {
                          amount: null,
                        },
                        infantFunction: {
                          amount: null,
                        },
                      },
                    },
                  },
                  createdTimeUtc: '2020-05-06T02:30:46.7582656Z',
                },
                prices: {
                  listPrices: x.listPrices,
                  sellingPrices: {
                    ...x.listPrices,
                    adultPrice: x.listPrices.adultPrice - 10000 * Math.floor(Math.random() * 10),
                  },
                },
              }
            : null,
      }));
      dispatch({ type: fetchPackages.success, payload: dummy });
    } catch (errRes) {
      dispatch({ type: fetchPackages.error });
      console.log(errRes);
    }
  }, []);

  const getSuppliers = useCallback(async () => {
    dispatch({ type: fetchSuppliers.request });
    try {
      const res = await fetchSuppliersRequest();
      dispatch({ type: fetchSuppliers.success, payload: res.data.items });
    } catch (errRes) {
      dispatch({ type: fetchSuppliers.error });
      console.log(errRes);
    }
  }, []);

  useEffect(() => {
    getSuppliers();
  }, [getSuppliers]);

  return (
    <div className="SpecialPricingRegistration">
      <PageHeader className="page-header" title={<h1>특가정책 등록</h1>} />
      <SpecialPricingSearchForm
        getPackages={getPackages}
        loading={state.packageResponse.loading}
        suppliers={state.supplierResponse.data}
        updateFilter={(filter: IPackageFilters) => dispatch({ type: 'SET_FILTER', payload: filter })}
      />
      <div className="result-count">
        <Typography.Text>조회된 행사코드 </Typography.Text>
        <Typography.Text strong>{state.packageResponse.data.length}</Typography.Text>
        <Divider type="vertical" />
        <Typography.Text>선택된 행사코드 </Typography.Text>
        <Typography.Text strong>{state.checkedItems.length}</Typography.Text>
        <Divider type="vertical" />
        {/* -------------- API 개발 완료되면 제거할 것 ------------------------*/}
        <Typography.Text strong type="danger">
          *조회된 특가 뱃지와 판매가는 실제 정보가 아닌 개발용 더미 데이터입니다.
        </Typography.Text>
        {/* ----------------------------------------------------------------- */}
      </div>
      <SpecialPricingSearchResult
        source={state.filteredResults}
        loading={{ getPackages: state.packageResponse.loading }}
        saveCheckedItems={(checkedItems: string[]) => dispatch({ type: 'SAVE_CHECKED_ITEMS', payload: checkedItems })}
        checkedItems={state.checkedItems}
      />
    </div>
  );
};

export default SpecialPricingPage;
