import ICouponListRequestParams from '../domain/coupon/ICouponListRequestParams';
import { admin } from '../infra/axios';
import ICouponSchemaAddData from '../domain/coupon/couponSchema/ICouponSchemaAddData';

const fetchCouponSchemas = (params: ICouponListRequestParams) => admin.get('/api/coupons/schemas', { params: params });

const fetchCouponSchemaByCouponSchemaId = (couponSchemaId: string) =>
  admin.get(`/api/coupons/schemas/${couponSchemaId}`);

const addCouponSchema = (data: ICouponSchemaAddData) =>
  admin.post('/api/coupons/schemas/execute-command/add-coupon-schema', data);

export { fetchCouponSchemas, addCouponSchema, fetchCouponSchemaByCouponSchemaId };
