import React, { FC, MouseEvent } from 'react';
import { Modal, Button } from 'antd';

export interface ITripPlannerReservationQuestionModalProp {
  visible: boolean;
  content: string;
  handleCancel: (e: MouseEvent) => void;
}
const TripPlannerReservationQuestionModal: FC<ITripPlannerReservationQuestionModalProp> = ({
  visible,
  content,
  handleCancel,
}) => {
  return (
    <Modal
      title="문의사항"
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="close" onClick={handleCancel}>
          닫기
        </Button>,
      ]}
    >
      {content}
    </Modal>
  );
};

export default TripPlannerReservationQuestionModal;
