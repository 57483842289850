import IVocSendFailHistory from '../../domain/vocSendFailHistory/IVocSendFailHistory';
import { IVocSendFailHistoryTableData } from '../../views/components/VocSendFailHistory/VocSendFailHistory';

export const convertToTableData = (domain: IVocSendFailHistory): IVocSendFailHistoryTableData => {
  return {
    key: domain.id.toString(),
    id: domain.id,
    createdAt: domain.createdAt,
    category: domain.category,
    reason: domain.reason,
    data: domain.data,
  };
};
