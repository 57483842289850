import React, { MouseEvent, useEffect, useState } from 'react';
import OndaReservation from 'views/components/Reservations/Onda';
import {
  apiCancelReservation,
  fetchOndaReservation,
} from 'api/reservation/domestic/accommodation/ondaReservation';
import IOndaReservation, {
  ICancellationPolicy,
} from 'domain/reservation/onda/IOndaReservation';
import IListRequest from 'domain/IListRequest';
import IOndaReservationsFilter from 'domain/reservation/onda/IOndaReservationsFilter';
import IPagination, { initialPagination } from 'views/types/IPagination';
import {
  IOndaReservationTableData,
  IOndaReservationTableProps,
} from 'views/components/Reservations/Onda/table/OndaReservationTable';
import { message } from 'antd';
import IOndaCancelReservationRequest from 'domain/reservation/onda/IOndaCancelReservationRequest';
import { IOndaReservationCancellationPolicyModalProp } from 'views/components/Reservations/Onda/Modal/OndaReservationCancellationPolicyModal';
import { IOndaReservationDetailsModalProp } from 'views/components/Reservations/Onda/Modal/OndaReservationDetailsModal';

const OndaReservationContainer = () => {
  const initParam: IListRequest<IOndaReservationsFilter> = {
    page: 0,
    size: 10,
    filter: {},
  };

  const [param, setParam] = useState<IListRequest<IOndaReservationsFilter>>(
    initParam,
  );
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IOndaReservationTableData[]>([]);
  const [pagination, setPagination] = useState<IPagination>(initialPagination);
  const [
    cancellationPolicyModalVisible,
    setCancellationPolicyModalVisible,
  ] = useState<boolean>(false);
  const [
    cancellationPolicyModalContent,
    setCancellationPolicyModalContent,
  ] = useState<ICancellationPolicy | null>(null);
  const [detailsModalVisible, setDetailsModalVisible] = useState<boolean>(
    false,
  );
  const [detailsModalContent, setDetailsModalContent] = useState<
    IOndaReservationTableData | undefined
  >(undefined);

  const fetchList = async (request: IListRequest<IOndaReservationsFilter>) => {
    setTableLoading(true);
    try {
      const response = await fetchOndaReservation(request);
      const reservations: IOndaReservation[] = response.data.items;
      setTableData(reservations.map((x) => toTableData(x)));
      const pagination = response
        ? convertToPagination(request, response.data.total)
        : initialPagination;
      setPagination(pagination);
    } catch (e) {
      console.log(e);
    }
    setTableLoading(false);
  };

  const convertToPagination = (
    request: IListRequest<IOndaReservationsFilter>,
    total: number,
  ): IPagination => {
    return {
      total: total,
      current: request.page ? request.page + 1 : 1,
      pageSize: 10,
      showSizeChanger: false,
      onShowSizeChange: () => {},
      onChange: handlePageChange,
    };
  };

  const handlePageChange = (page: number) => {
    const changedParam: IListRequest<IOndaReservationsFilter> = {
      ...param,
      page: page - 1,
    };
    setParam(changedParam);
    fetchList(changedParam);
  };

  const tableProps: IOndaReservationTableProps = {
    source: tableData,
    pagination: pagination,
    tableLoading: tableLoading,
    cancelReservation: cancelReservation,
    handleCancellationPolicyModalShow: handleCancellationPolicyModalShow,
    handleDetailsModalShow: handleDetailsModalShow,
    fetchList: fetchList,
  };

  function toTableData(data: IOndaReservation) {
    const result: IOndaReservationTableData = {
      key: data.reservation.sequence,
      ...data,
    };
    return result;
  }

  async function cancelReservation(reservationCode: string) {
    const ondaCancelReservationRequest: IOndaCancelReservationRequest = {
      reservationCode: reservationCode,
      requester: {
        type: 'ADMIN',
      },
    };
    await apiCancelReservation(ondaCancelReservationRequest);
  }

  function handleCancellationPolicyModalShow(
    e: MouseEvent,
    reservationCode: string,
  ) {
    const ondaReservationTableData = tableData.find(
      (it) => it.reservation.reservationCode === reservationCode,
    );
    const cancellationPolicy = ondaReservationTableData
      ? ondaReservationTableData.reservation.roomRate.cancellationPolicy
      : null;
    setCancellationPolicyModalContent(cancellationPolicy);
    setCancellationPolicyModalVisible(true);
  }

  function handleDetailsModalShow(e: MouseEvent, reservationCode: string) {
    const ondaReservationTableData = tableData.find(
      (it) => it.reservation.reservationCode === reservationCode,
    );
    setDetailsModalContent(ondaReservationTableData);
    setDetailsModalVisible(true);
  }

  const handleCancellationPolicyModalCancel = () => {
    setCancellationPolicyModalVisible(false);
  };

  const handleDetailsModalCancel = () => {
    setDetailsModalVisible(false);
  };

  const cancellationPolicyModalProps: IOndaReservationCancellationPolicyModalProp = {
    visible: cancellationPolicyModalVisible,
    content: cancellationPolicyModalContent,
    handleCancel: handleCancellationPolicyModalCancel,
  };

  const detailsModalProps: IOndaReservationDetailsModalProp = {
    visible: detailsModalVisible,
    content: detailsModalContent,
    handleCancel: handleDetailsModalCancel,
  };

  useEffect(() => {
    fetchList(initParam);
  }, []);

  return (
    <OndaReservation
      tableProps={tableProps}
      fetchList={fetchList}
      param={param}
      cancellationPolicyModalProps={cancellationPolicyModalProps}
      detailsModalProps={detailsModalProps}
    />
  );
};

export default OndaReservationContainer;
