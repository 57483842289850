import React, { FC } from 'react';
import { PageHeader } from 'antd';
import OptionTable, { IOptionTableProps } from './table/OptionTable';

interface IOptionProps {
  tableProps: IOptionTableProps;
}

const Option: FC<IOptionProps> = ({ tableProps }) => {
  return (
    <div className="Option">
      <PageHeader className="page-header" title={<h1>옵션 관리</h1>} />
      <OptionTable {...tableProps} />
    </div>
  );
};

export default Option;
