import React, { FC } from 'react';
import { PageHeader } from 'antd';
import SuppliersTable, { ISuppliersTableProps } from './SuppliersTable';
import SuppliersSearchForm from './SuppliersSearchForm';
import ISuppliersRequestParams from 'domain/suppliers/ISuppliersRequestParams';

interface ISupplier {
  tableProps: ISuppliersTableProps;
  fetchList: (request: ISuppliersRequestParams) => void;
}

const Suppliers: FC<ISupplier> = ({ tableProps, fetchList }) => {
  return (
    <div className="Suppliers">
      <PageHeader className="page-header" title={<h1>여행사 목록</h1>} />
      <SuppliersSearchForm />
      <SuppliersTable {...tableProps} />
    </div>
  );
};

export default Suppliers;
