import React, { FC } from 'react';
import { Table } from 'antd';
import IPagination from 'views/types/IPagination';
import columns from "views/components/Coupon/CouponSchema/CouponSchemaTable/CouponSchemaTableColumns";

export interface ICouponSchemeTableData {
  key: string;
  id: string;
  sequence: number;
  name: string;
  displayName: string;
  applicableItem: string;
  discountKind: string;
  discountFunction: string;
  offeringPeriod: string;
  coverage: string;
  issueTarget: string;
  issueMethod: string;
}

export interface ICouponSchemeTableProps {
  source: ICouponSchemeTableData[];
  pagination: IPagination;
  loading: boolean;
}

const CouponSchemeTable: FC<ICouponSchemeTableProps> = ({ source, pagination, loading }) => {
  return (
    <Table<ICouponSchemeTableData> columns={columns} dataSource={source} pagination={pagination} loading={loading} />
  );
};

export default CouponSchemeTable;
