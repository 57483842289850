import {Button, Modal} from "antd";
import React from "react";
import OndaReservationDetailsModalTable
  from "views/components/Reservations/Onda/Modal/OndaReservationDetailsModalTable";
import {IOndaReservationTableData} from "views/components/Reservations/Onda/table/OndaReservationTable";

export interface IOndaReservationDetailsModalProp {
  visible: boolean;
  content: IOndaReservationTableData | undefined;
  handleCancel: () => void;
}

function OndaReservationDetailsModal(
  {
    visible,
    content,
    handleCancel
  }: IOndaReservationDetailsModalProp
) {
  if (content !== undefined) {
    return (
      <Modal
        title="추가정보"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleCancel}>
            닫기
          </Button>
        ]}
        width={1000}
      >
        <OndaReservationDetailsModalTable source={content}/>
      </Modal>
    )
  } else {
    return <div></div>
  }
}

export default OndaReservationDetailsModal
