import React from 'react';
import { Button } from 'antd';
import { IContentType } from 'domain/preReservationV2/IProduct';

interface IContentAddButtonProps {
  handleAddContent: (type: IContentType) => void;
}

const ContentAddButton = ({ handleAddContent }: IContentAddButtonProps) => {
  return (
    <div className="center">
      <Button onClick={() => handleAddContent(IContentType.TEXT)}>
        텍스트 추가
      </Button>
      <Button onClick={() => handleAddContent(IContentType.IMAGE)}>
        사진 추가
      </Button>
      <Button onClick={() => handleAddContent(IContentType.VIDEO)}>
        영상 추가
      </Button>
    </div>
  );
};

export default ContentAddButton;
