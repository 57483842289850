import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import { Col, Layout, Row } from 'antd';
import useAuth from 'infra/hooks/useAuth';
import useWindowDimensions from 'infra/hooks/useWindowDimensions';
import TripPlannerReservation from 'containers/tripPlannerReservation';
import CouponScheme from 'containers/coupon/couponSchema';
import VocSendFailHistory from 'containers/vocSendFailHistory';
import CouponIssuePlan from 'containers/coupon/couponIssuePlan';
import Logo from 'views/components/Logo';
import Navigation from 'views/components/Navigation';
import SpecialPricing from 'views/components/SpecialPricing';
import Suppliers from 'containers/partners/suppliers';
import SupplierDetailContainer from 'containers/partners/suppliers/supplierDetailContainer';
import 'views/styles/App.scss';
import 'views/styles/base/PageHeader.scss';
import OndaReservation from 'containers/reservations/onda';
import Top10Accommodations from 'containers/top10/accommodations/Top10AccommodationsContainer';
import Top10WeeklyRankedSheet from 'containers/top10/weeklyRankedSheet/Top10WeeklyRankedSheetContainer';
import {
  ProductContainer,
  ProductCreateContainer,
  ThemeContainer,
  ThemeCreateContainer,
} from 'containers/overseas/preReservatoinV2';
import OptionContainer from 'containers/overseas/preReservatoinV2/OptionContainer';

const { Header, Content } = Layout;

const App: FC = () => {
  const { height } = useWindowDimensions();
  return useAuth(
    <Layout className="App">
      <Header
        className="App-header"
        style={{ borderBottom: '1px solid #e8e8e8' }}
      >
        <Row>
          <Col xs={24} sm={24} md={2}>
            <Logo />
          </Col>
          <Col xs={0} sm={0} md={22}>
            <Navigation />
          </Col>
        </Row>
      </Header>
      <Content style={{ background: '#efefef', minHeight: height - 64 }}>
        <div
          style={{
            margin: 16,
            background: '#fff',
            minHeight: height - 64 - 32,
            padding: 16,
          }}
        >
          <Route path="/coupon-issue-plan" component={CouponIssuePlan} />
          <Route path="/coupon-scheme" component={CouponScheme} />
          <Route path="/special-pricing" component={SpecialPricing} />
          <Route
            path="/trip-planner-reservation"
            component={TripPlannerReservation}
          />
          <Route path="/voc-send-fail-history" component={VocSendFailHistory} />
          <Route
            exact={true}
            path="/pre-reservation-v2/products"
            component={ProductContainer}
          />
          <Route
            exact={true}
            path="/pre-reservation-v2/products/create"
            component={ProductCreateContainer}
          />
          <Route
            exact
            path="/pre-reservation-v2/themes"
            component={ThemeContainer}
          />
          <Route
            exact={true}
            path="/pre-reservation-v2/options"
            component={OptionContainer}
          />
          <Route
            exact
            path="/pre-reservation-v2/themes/create"
            component={ThemeCreateContainer}
          />
          <Route exact={true} path="/suppliers" component={Suppliers} />
          <Route
            exact={true}
            path="/suppliers/:supplierId"
            component={SupplierDetailContainer}
          />
          <Route
            exact={true}
            path="/reservations/onda"
            component={OndaReservation}
          />
          <Route
            exact={true}
            path="/top10/accommodations"
            component={Top10Accommodations}
          />
          <Route
            exact={true}
            path="/top10/weekly-ranked-sheet"
            component={Top10WeeklyRankedSheet}
          />
        </div>
      </Content>
    </Layout>,
  );
};

export default App;
