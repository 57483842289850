import React, { FC, MouseEvent, useEffect, useState } from 'react';
import CouponIssuePlan from 'views/components/Coupon/CouponIssuePlan';
import ICouponIssuePlanData from 'domain/coupon/couponIssuePlan/ICouponIssuePlanData';
import IPagination, { initialPagination } from 'views/types/IPagination';
import ICouponListRequestParams from 'domain/coupon/ICouponListRequestParams';
import { message } from 'antd';
import { fetchCouponIssuePlan } from 'api/couponIssuePlan';
import ICouponListResponse from 'domain/coupon/ICouponListResponse';
import {
  ICouponIssuePlanTableData,
  ICouponIssuePlanTableProps,
} from 'views/components/Coupon/CouponIssuePlan/CouponIssuePlanTable';
import { CouponIssuePlanEnum } from 'domain/coupon/couponIssuePlan/CouponIssuePlanEnum';
import { ICouponIssuePlanFormModalContainerProps } from 'containers/coupon/couponIssuePlan/CouponIssuePlanRegistrationModalContainer';
import CouponIssuePlanFormField, { ICouponIssuePlanFormField } from 'services/coupon/couponIssuePlan/CouponIssuePlanFormField';
import { convertToPagination, convertToTableData } from 'services/coupon/couponIssuePlan/CouponIssuePlanConvertor';
import { IssueMethod } from 'domain/coupon/couponSchema/IssueMethod';
import { fetchCouponSchemaByCouponSchemaId } from 'api/couponSchema';

export enum ModalFormStatus {
  REGISTRATION,
  REVISION,
  STOP,
  END,
}

const CouponIssuePlanContainer: FC = () => {
  const [tableData, setTableData] = useState<ICouponIssuePlanTableData[]>([]);
  const [tablePagination, setTablePagination] = useState<IPagination>(initialPagination);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [registrationModalVisible, setRegistrationModalVisible] = useState(false);
  const [issueMethod, setIssueMethod] = useState<IssueMethod>(IssueMethod.Clip);
  const [formModalMode, setFormModalMode] = useState<ModalFormStatus>(ModalFormStatus.REGISTRATION);
  const [couponIssuePlanDataList, setCouponIssuePlanDataList] = useState<ICouponIssuePlanData[]>([]);
  const [couponIssuePlanData, setCouponIssuePlanData] = useState<ICouponIssuePlanFormField>(
    new CouponIssuePlanFormField(),
  );

  const fetchList = async (request: ICouponListRequestParams) => {
    setTableLoading(true);
    try {
      const result = await fetchCouponIssuePlan(request);
      const response: ICouponListResponse<ICouponIssuePlanData> = result.data;
      const responseItems: ICouponIssuePlanData[] = response ? response.items : [];
      const formatted: ICouponIssuePlanTableData[] = responseItems.map(x => {
        return convertToTableData(x);
      });
      const pagination: IPagination = response
        ? convertToPagination(request, response.total, handlePageChange)
        : initialPagination;

      setCouponIssuePlanDataList(responseItems);
      setTableData(formatted);
      setTablePagination(pagination);
    } catch (e) {
      console.log(e);
      message.error('목록 조회 실패하였습니다.');
    }
    setTableLoading(false);
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    const take = pageSize ? pageSize : 10;
    const skip = (page - 1) * take;
    fetchList({ skip: skip, take: take });
  };

  const handleRegistrationModalHide = () => {
    setRegistrationModalVisible(false);
  };

  const handleRegistrationModalShow = () => {
    setRegistrationModalVisible(true);
    setFormModalMode(ModalFormStatus.REGISTRATION);
    setCouponIssuePlanData(new CouponIssuePlanFormField());
  };

  const handleViewModalShow = async (e: MouseEvent, id: String) => {
    const findData = couponIssuePlanDataList.find(x => x.id === id);
    if (!findData) {
      message.error(`[${id}]값을 가진 쿠폰발급 계획을 찾을 수 없습니다.`);
      return;
    }
    const issueMethod = (await fetchCouponSchemaByCouponSchemaId(findData.schema.id)).data.issueMethod;
    setIssueMethod(issueMethod);
    setCouponIssuePlanData(new CouponIssuePlanFormField(findData));

    const status = CouponIssuePlanEnum[findData.status as keyof typeof CouponIssuePlanEnum];

    switch (status) {
      case CouponIssuePlanEnum.Standby:
        setFormModalMode(ModalFormStatus.REVISION);
        break;
      case CouponIssuePlanEnum.Available:
        setFormModalMode(ModalFormStatus.STOP);
        break;
      default:
        setFormModalMode(ModalFormStatus.END);
    }

    setRegistrationModalVisible(true);
  };

  const handleViewChange = (changedFields: any) => {
    setCouponIssuePlanData({
      ...couponIssuePlanData,
      ...changedFields,
    });
  };

  useEffect(() => {
    fetchList({ skip: 0, take: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableProps: ICouponIssuePlanTableProps = {
    tableData: tableData,
    tablePagination: tablePagination,
    tableLoading: tableLoading,
    handleViewModalShow: handleViewModalShow,
  };

  const modalProps: ICouponIssuePlanFormModalContainerProps = {
    modalVisible: registrationModalVisible,
    handleModalHide: handleRegistrationModalHide,
    fetchList: fetchList,
    modalMode: formModalMode,
    couponIssuePlanData: couponIssuePlanData,
    handleViewChange: handleViewChange,
    issueMethod: issueMethod,
    setIssueMethod:setIssueMethod,
  };

  return (
    <CouponIssuePlan
      handleRegistrationModalShow={handleRegistrationModalShow}
      tableProps={tableProps}
      modalProps={modalProps}
    />
  );
};

export default CouponIssuePlanContainer;
