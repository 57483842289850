import React, { useEffect, useState } from 'react';
import Top10Accommodations from 'views/components/Top10/Accommodations/Top10Accommodations';
import {
  constructTop10AccommodationsTableData,
  ITop10AccommodationsTableData,
  ITop10AccommodationsTableProps,
} from 'views/components/Top10/Accommodations/Top10AccommodationsTable';
import {getDummyOfTop10Accommodations, getEmptyTop10Accommodations} from 'api/top10/top10Accommodations';
import IListRequest from 'domain/IListRequest';
import ITop10AccommodationsFilter from 'domain/top10/accommodation/ITop10AccommodationsFilter';
import IPagination, {
  initialPagination,
  TSPagination,
} from 'views/types/IPagination';
import { ITop10AccommodationsRegistrationModalProps } from 'views/components/Top10/Accommodations/Top10AccommodationsRegistrationModal';

const Top10AccommodationsContainer = () => {
  const defaultPageRequest = {
    page: 0,
    size: 10,
    filter: {},
  };

  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableSource, setTableSource] = useState<
    ITop10AccommodationsTableData[]
  >([]);
  const [pageRequest, setPageRequest] = useState<
    IListRequest<ITop10AccommodationsFilter>
  >(defaultPageRequest);
  const [pagination, setPagination] = useState<IPagination>(initialPagination);
  const [registrationModalVisible, setRegistrationModalVisible] = useState(
    false,
  );

  const handlePageChange = (page: number) => {
    const changedPagedRequest: IListRequest<ITop10AccommodationsFilter> = {
      ...pageRequest,
      page: page - 1,
    };
    setPageRequest(changedPagedRequest);
    fetchList(changedPagedRequest);
  };

  const fetchList = (request: IListRequest<ITop10AccommodationsFilter>) => {
    setTableLoading(true);
    const response = getEmptyTop10Accommodations(request);
    const accommodations = response.data.items;
    const tableSource = accommodations.map(x =>
      constructTop10AccommodationsTableData(x),
    );
    setTableSource(tableSource);
    const pagination = response
      ? new TSPagination(request, response.data.total, handlePageChange)
      : initialPagination;
    setPagination(pagination);
    setTableLoading(false);
  };

  const showRegistrationModal = () => {
    setRegistrationModalVisible(true);
  };

  const hideRegistrationModal = () => {
    setRegistrationModalVisible(false);
  };

  const tableProps: ITop10AccommodationsTableProps = {
    source: tableSource,
    tableLoading: tableLoading,
    pagination: pagination,
    fetchList: fetchList,
    showReservationModal: showRegistrationModal,
  };

  const registrationModalProps: ITop10AccommodationsRegistrationModalProps = {
    visible: registrationModalVisible,
    hideModal: hideRegistrationModal,
  };

  useEffect(() => {
    fetchList(defaultPageRequest);
  }, []);

  return (
    <Top10Accommodations
      tableProps={tableProps}
      registrationModalProps={registrationModalProps}
    />
  );
};

export default Top10AccommodationsContainer;
