import React, { useEffect, useState } from 'react';
import { message, Select } from 'antd';
import { fetchProductSummaries } from 'api/overseas/preReservationV2/product';
import IProductSummary from 'domain/preReservationV2/IProductSummary';

const { Option } = Select;

interface IProductSelectorProps {
  handleChange: (productSummary: IProductSummary) => void;
}

const ProductSelector = ({ handleChange }: IProductSelectorProps) => {
  const [items, setItems] = useState<IProductSummary[]>([]);

  useEffect(() => {
    const promise = async () => {
      try {
        const response = await fetchProductSummaries();
        setItems(response.data.items as IProductSummary[]);
      } catch (e) {
        message.error(e);
      }
    };

    promise();
  }, []);

  const handleChangeSelect = (value: any) => {
    handleChange(items.find(it => it.id == value)!!);
  };

  if (items.length < 1) {
    return <span>상품이 없거나 로딩중입니다</span>;
  }

  return (
    <Select
      showSearch
      defaultValue="상품을 선택하세요"
      onChange={handleChangeSelect}
    >
      {items.map(it => (
        <Option value={it.id}>
          [{it.id}]{it.displayName}
        </Option>
      ))}
    </Select>
  );
};

export default ProductSelector;
