import React, { FC, useRef } from 'react';
import { PageHeader, Button, Form, Input, Divider, message, Radio } from 'antd';
import '../../../styles/Overseas/PreReservationV2/ProductCreate.scss';
import '../../../styles/Overseas/PreReservationV2/PreReservationV2.scss';
import ProductGroupSection, {
  IProductGroupSectionRefObject,
} from './ProductGroupSection';
import IThemeCreateRequest, {
  IProductGroupSectionForm,
} from 'domain/preReservationV2/IThemeCreateRequest';
import { postTheme } from 'api/overseas/preReservationV2/theme';

const ThemeCreate: FC = ({}) => {
  const [form] = Form.useForm();
  const productGroupSectionRef = useRef<IProductGroupSectionRefObject>();

  const handleCreateTheme = async () => {
    try {
      const reqeust = toRequest(
        await form.validateFields(),
        await productGroupSectionRef.current!!.getForm(),
      );

      await postTheme(reqeust);

      alert('상품을 등록했습니다');
      window.location.reload();
    } catch (e) {
      message.error(e.message);
    }
  };

  const toRequest = (
    data: any,
    productGroupSections: IProductGroupSectionForm[],
  ): IThemeCreateRequest => ({
    id: data.id,
    name: data.name,
    isActive: data.isActive,
    elements: {
      head: {
        title: data.headTitle,
        meta: {
          description: data.ogDescription,
          og: {
            image: data.ogImage,
            title: data.ogTitle,
            description: data.ogDescription,
            url: data.ogUrl,
          },
        },
      },
      toast: {
        title: data.toast,
      },
    },
    displayName: data.displayName,
    subtitle: data.subtitle,
    image: data.image,
    description: data.description,
    footerText: data.footerText,
    productGroupSections: productGroupSections,
    log: {
      campaign: data.log,
    },
  });

  return (
    <div className="pre-reservation-v2 theme-create">
      <PageHeader className="page-header" title={<h1>목록등록</h1>} />

      <Form
        form={form}
        initialValues={{
          id: '',
          name: '',
          isActive: true,
          headTitle: '',
          ogImage: '',
          ogTitle: '',
          ogDescription: '',
          toast: '',
          log: '',
          displayName: '',
          subtitle: '',
          image: '',
          description: '',
          footerText: '',
        }}
        labelCol={{ span: 4 }}
      >
        <Form.Item name="id" label="목록ID">
          <Input placeholder="목록ID" />
        </Form.Item>
        <Form.Item name="isActive" label="isActive">
          <Radio.Group defaultValue={true}>
            <Radio value={true}>활성화</Radio>
            <Radio value={false}>비활성화</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="name" label="목록이름">
          <Input placeholder="목록이름" />
        </Form.Item>

        <Divider />

        <Form.Item name="headTitle" label="headTitle">
          <Input placeholder="headTitle" />
        </Form.Item>
        <Form.Item name="ogImage" label="og_image">
          <Input placeholder="og_image" />
        </Form.Item>
        <Form.Item name="ogTitle" label="og_title">
          <Input placeholder="og_title" />
        </Form.Item>
        <Form.Item name="ogDescription" label="og_description">
          <Input placeholder="og_description" />
        </Form.Item>
        <Form.Item name="ogUrl" label="og_url">
          <Input placeholder="og_url" />
        </Form.Item>
        <Form.Item name="toast" label="toast">
          <Input placeholder="toast" />
        </Form.Item>
        <Form.Item name="log" label="로그명">
          <Input placeholder="log" />
        </Form.Item>

        <Divider />

        <Form.Item name="displayName" label="타이틀">
          <Input placeholder="타이틀" />
        </Form.Item>
        <Form.Item name="subtitle" label="서브타이틀">
          <Input placeholder="서브타이틀" />
        </Form.Item>
        <Form.Item name="image" label="상단 이미지">
          <Input placeholder="상단 이미지 주소 입력" />
        </Form.Item>
        <Form.Item name="description" label="추가 설명">
          <Input placeholder="추가 설명" />
        </Form.Item>
        <Form.Item name="footerText" label="하단 텍스트">
          <Input placeholder="하단 텍스트" />
        </Form.Item>
      </Form>

      <ProductGroupSection ref={productGroupSectionRef} />

      <Divider />
      <div className="center">
        <Button type="primary" size="large" onClick={handleCreateTheme}>
          저장하기
        </Button>
      </div>
    </div>
  );
};

export default ThemeCreate;
