import React, {FC} from "react";
import {Table} from "antd";
import {IPenalty} from "domain/reservation/onda/IOndaReservation";

export interface IOndaReservationCancellationPolicyModalTableProps {
  source: IPenalty[];
}

interface IOndaReservationCancellationPolicyModalTableData {
  key: number;
  penalty: IPenalty,
}

const OndaReservationCancellationPolicyModalTable: FC<IOndaReservationCancellationPolicyModalTableProps> = ({
  source,
}) => {
  function OndaReservationCancellationPolicyModalTableColumns() {
    const columns = [
      {
        key: 'daysPriorTo',
        title: '남은기간',
        dataIndex: 'penalty',
        render: (penalty: IPenalty) => `${penalty.daysPriorTo}`
      },
      {
        key: 'fee',
        title: '취소 수수료',
        dataIndex: 'penalty',
        render: (penalty: IPenalty) => `${penalty.fee}`
      }
    ]
    return columns
  }

  const columns = OndaReservationCancellationPolicyModalTableColumns();

  function toTableData(penalty: IPenalty, index: number) {
    const result: IOndaReservationCancellationPolicyModalTableData = {
      key: index,
      penalty: penalty,
    };
    return result
  }

  const tableData = source.map((penalty: IPenalty, index: number) => {
    return toTableData(penalty, index);
  })

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
    />
  );
}

export default OndaReservationCancellationPolicyModalTable
