import React, { FC } from 'react';
import Top10AccommodationsTable, {
  ITop10AccommodationsTableProps,
} from 'views/components/Top10/Accommodations/Top10AccommodationsTable';
import { PageHeader } from 'antd';
import Top10AccommodationsRegistrationModal, {
  ITop10AccommodationsRegistrationModalProps,
} from 'views/components/Top10/Accommodations/Top10AccommodationsRegistrationModal';

export interface ITop10AccommodationsProps {
  tableProps: ITop10AccommodationsTableProps;
  registrationModalProps: ITop10AccommodationsRegistrationModalProps;
}

const Top10Accommodations: FC<ITop10AccommodationsProps> = ({
  tableProps,
  registrationModalProps,
}) => {
  return (
    <div className="Top10Accommodations">
      <PageHeader className="page-header" title={<h1>TOP10 숙소관리</h1>} />
      <Top10AccommodationsTable {...tableProps} />
      <Top10AccommodationsRegistrationModal {...registrationModalProps} />
    </div>
  );
};

export default Top10Accommodations;
