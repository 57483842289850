export default class Address {
  country: string;
  state: string;
  city: string;
  area: string;
  street: string;

  constructor(
    country: string,
    state: string,
    city: string,
    area: string,
    street: string,
  ) {
    this.country = country;
    this.state = state;
    this.city = city;
    this.area = area;
    this.street = street;
  }
}
