import { Button, Modal } from 'antd';
import React from 'react';
import { IRefundAmountInfo } from 'domain/reservation/onda/IOndaRefundAmount';

export interface IOndaReservationCancellationModalProps {
  visible: boolean;
  hideModal: () => void;
  refundAmountInfo: IRefundAmountInfo;
  cancelReservation: () => void;
}

const OndaReservationCancellationModal = ({
  visible,
  hideModal,
  refundAmountInfo,
  cancelReservation,
}: IOndaReservationCancellationModalProps) => {
  const handleCancel = () => {
    hideModal();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="close" onClick={handleCancel}>
          아니오
        </Button>,
        <Button key="upload" type="primary" onClick={cancelReservation}>
          네
        </Button>,
      ]}
      width={500}
    >
      <strong style={{ fontSize: 18 }}>지금 취소하면</strong>
      <br />
      취소수수료: {refundAmountInfo.fee.appendThousandComma()}원
      <br />
      환불금액: {refundAmountInfo.refundAmount.appendThousandComma()}원
      <br />
      (취소수수료 산정 기준 : {refundAmountInfo.until}까지)
      <br />
      <strong style={{ fontSize: 18 }}>예약을 취소하시겠습니까?</strong>
    </Modal>
  );
};

export default OndaReservationCancellationModal;
