import React, {
  createRef,
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Button } from 'antd';
import '../../../styles/Overseas/PreReservationV2/ProductCreate.scss';
import ContentGroupForm from './ContentGroupForm';
import { IContentGroupForm } from 'domain/preReservationV2/IProductCreateRequest';
import { IContentType } from 'domain/preReservationV2/IProduct';

export interface IContentGroupSectionRefObject {
  getForm: () => Promise<IContentGroupForm[]>;
}

const ContentGroupSection = forwardRef(({}, ref) => {
  const [contentGroups, setContentGroups] = useState<IContentGroupForm[]>(
    new Array(6).fill(null).map(() => ({
      title: '',
      isShowMoreButton: false,
      contents: [
        {
          type: IContentType.IMAGE,
          source: '',
          link: '',
          text: '',
        },
      ],
    })),
  );

  useImperativeHandle(ref, () => ({
    async getForm(): Promise<IContentGroupForm[]> {
      return contentGroups;
    },
  }));

  const handleAddContentGroup = useCallback(() => {
    setContentGroups([
      ...contentGroups,
      {
        title: '',
        isShowMoreButton: false,
        contents: [],
      },
    ]);
  }, [contentGroups]);

  const handleChangeContentGroup = useCallback(
    (index: number, contentGroupForm: IContentGroupForm) => {
      const newContentsGroup = [...contentGroups];
      newContentsGroup[index] = contentGroupForm;
      setContentGroups(newContentsGroup);
    },
    [contentGroups],
  );

  const handleDeleteContentGroup = useCallback(
    (index: number) => {
      const newContentsGroup = [...contentGroups];
      newContentsGroup.splice(index, 1);
      setContentGroups(newContentsGroup);
    },
    [contentGroups],
  );

  return (
    <>
      {contentGroups.map((value, index) => (
        <ContentGroupForm
          index={index}
          defaultValue={value}
          handleChange={handleChangeContentGroup}
          handleDelete={handleDeleteContentGroup}
        />
      ))}

      <div className="center">
        <Button size="large" onClick={handleAddContentGroup}>
          컨텐츠 그룹 추가
        </Button>
      </div>
    </>
  );
});

export default ContentGroupSection;
