import { v2 } from '../infra/axios';
import IListRequest from '../domain/IListRequest';
import ITripPlannerReservationListFilter from '../domain/tripPlannerReservation/ITripPlannerReservationListFilter';
import ITripPlannerReservationHistoryUpdateRequest from '../domain/tripPlannerReservation/ITripPlannerReservationHistoryUpdateRequest';
import ITripPlannerReservationStatusUpdateRequest from '../domain/tripPlannerReservation/ITripPlannerReservationStatusUpdateRequest';

const fetchTripPlannerReservations = (params: IListRequest<ITripPlannerReservationListFilter>) =>
  v2.get('/admin/reservation/trip-planner-reservations', {
    params: params,
  });

const updateTripPlannerHistory = (params: ITripPlannerReservationHistoryUpdateRequest) =>
  v2.put('/admin/reservation/trip-planner-reservations', params);

const updateTripPlannerStatus = (params: ITripPlannerReservationStatusUpdateRequest) =>
  v2.put('/admin/reservation/trip-planner-reservations', params);

export { fetchTripPlannerReservations, updateTripPlannerHistory, updateTripPlannerStatus };
