import React, { FC } from 'react';
import { Table } from 'antd';
import IPagination from 'views/types/IPagination';
import columns from "views/components/Partners/Suppliers/SuppliersTable/SuppliersTableColumns";

export interface ISuppliersTableData {
  key: string;
  supplierName: string;
  administratorName: string;
  administratorEmail: string;
  appliedAt: string;
  judgedAt: string;
  contractCompletedAt: string;
  activatedAt: string;
  status: string;
}

export interface ISuppliersTableProps {
  source: ISuppliersTableData[];
  pagination: IPagination;
  loading: boolean;
}

const SuppliersTable: FC<ISuppliersTableProps> = ({ source, pagination, loading }) => {
  return (
    <Table<ISuppliersTableData> columns={columns} dataSource={source} pagination={pagination} loading={loading} />
  );
};

export default SuppliersTable;
