import React, { ReactElement } from 'react';
import IUserProfile from '../../domain/IUserProfile';
import { fetchUserProfile } from '../../api/user';
import usePromise from './usePromise';
import env from '../envMap';

export default function useAuth(elements: ReactElement) {
  const { loading, resolved, error } = usePromise<IUserProfile>(fetchUserProfile, []);

  if (!env.skipLogin) {
    if (error) {
      console.log(error);
      return <div>Login Error</div>;
    }

    if (loading || !resolved) {
      return <div>Loading...</div>;
    }

    if (!(resolved.roles && resolved.roles.includes('ADMIN'))) {
      window.location.href = env.v2AdminHost || '';
    }
  }

  return elements;
}
