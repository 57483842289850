import React, { FC, MouseEvent, ChangeEvent } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Row, Col } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

interface ITripPlannerReservationHistoryModalProp {
  visible: boolean;
  handleCancel: (e: MouseEvent) => void;
  handleSave: (e: MouseEvent) => void;
  content: string;
  handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  saveButtonLoadingVisible: boolean;
}
const TripPlannerReservationHistoryModal: FC<ITripPlannerReservationHistoryModalProp> = ({
  visible,
  handleCancel,
  content,
  handleSave,
  handleChange,
  saveButtonLoadingVisible,
}) => {
  return (
    <Modal
      title="상담이력"
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="close" onClick={handleCancel}>
          닫기
        </Button>,
        <Button key="save" type="primary" onClick={handleSave} loading={saveButtonLoadingVisible}>
          저장
        </Button>,
      ]}
    >
      <Form>
        <Row>
          <Col span={24}>
            <Form.Item wrapperCol={{ span: 24 }}>
              <TextArea rows={10} placeholder="상담내용을 입력하세요" value={content} onChange={handleChange} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TripPlannerReservationHistoryModal;
