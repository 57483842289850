import IPagination from '../../../../types/IPagination';
import React, { FC } from 'react';
import { Button, Col, Form, Input, Row, Table } from 'antd';
import IListRequest from 'domain/IListRequest';
import ThemeTableColumns from './ThemeTableColumns';
import ITheme from 'domain/preReservationV2/ITheme';
import { PlusOutlined } from '@ant-design/icons';
import IThemesFilter from 'domain/preReservationV2/IThemesFilter';
import { Link } from 'react-router-dom';

export interface IThemeTableData {
  key: string;
  theme: ITheme;
}

export interface IThemeTableProps {
  source: IThemeTableData[];
  pagination: IPagination;
  tableLoading: boolean;
  fetchList: (param: IListRequest<IThemesFilter>) => void;
}

const ThemeTable: FC<IThemeTableProps> = ({
  source,
  pagination,
  tableLoading,
  fetchList,
}) => {
  const columns = ThemeTableColumns();
  const [form] = Form.useForm();
  async function handleSearch() {
    try {
      const values = await form.validateFields();
      const pageRequest: IListRequest<IThemesFilter> = {
        filter: {
          id: values.id,
          name: values.name,
        },
      };
      fetchList(pageRequest);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Form
        form={form}
        className="PreReservationV2ThemeSearchForm"
        onFinish={handleSearch}
        initialValues={{
          id: '',
          displayName: '',
          place: '',
        }}
        style={{ marginBottom: 30 }}
      >
        <Row gutter={4} justify="end" align="top">
          <Form.Item name="id" style={{ margin: 3.5 }}>
            <Input placeholder="상품코드" />
          </Form.Item>
          <Form.Item name="name" style={{ margin: 3.5 }}>
            <Input placeholder="상품명" />
          </Form.Item>
          <Col>
            <Button type="primary" htmlType="submit" style={{ margin: 3.5 }}>
              검색
            </Button>
          </Col>
          <Col className="btn-add" style={{ marginLeft: 'auto', order: 2 }}>
            <Link to="/pre-reservation-v2/themes/create">
              <Button icon={<PlusOutlined />}>상품목록 등록</Button>
            </Link>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={source}
        pagination={pagination}
        loading={tableLoading}
      />
    </>
  );
};

export default ThemeTable;
