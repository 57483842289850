import React, { FunctionComponent } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import IFormItem from 'views/types/IFormItem';

interface ICouponSchemaRegistrationProps {
  formItems: IFormItem[];
}

const Registration: FunctionComponent<ICouponSchemaRegistrationProps> = props => {
  const { formItems } = props;

  return (
    <Form>
      <Row gutter={24}>
        {formItems.map(it => (
          <Col span={it.span} key={it.label}>
            <Form.Item
              labelCol={{ span: it.labelSpan }}
              wrapperCol={{ span: it.wrapperSpan, offset: it.wrapperOffset ? it.wrapperOffset : 1 }}
              label={it.label}
            >
              {it.itemNode}
            </Form.Item>
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export default Registration;
