class Photo {
  url: string;

  constructor(url: string) {
    this.url = url
  }
}

export default class Photos {
  listPhotos: Photo[];
  headerPhotos: Photo[];

  constructor(listPhotos: Photo[], headerPhotos: Photo[]) {
    this.listPhotos = listPhotos;
    this.headerPhotos = headerPhotos;
  }

  static constructListPhotos(...listPhotoUrls: string[]) {
    return listPhotoUrls.filter(value => value !== undefined).map(listPhotoUrl => new Photo(listPhotoUrl));
  }

  static constructHeaderPhotos(...headerPhotoUrls: string[]) {
    return headerPhotoUrls.filter(value => value !== undefined).map(headerPhotoUrl => new Photo(headerPhotoUrl));
  }
}
