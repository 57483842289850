import React, { FC, MouseEvent } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import Form from '@ant-design/compatible/lib/form/Form';
import ICouponListRequestParams from 'domain/coupon/ICouponListRequestParams';
import IFormItem from 'views/types/IFormItem';
import { ModalFormStatus } from 'containers/coupon/couponIssuePlan/CouponIssuePlanContainer';
import { IssueMethod } from 'domain/coupon/couponSchema/IssueMethod';

interface ICouponIssuePlanRegistrationModalProps {
  formItems: IFormItem[];
  modalVisible: boolean;
  isRegisteringProgress: boolean;
  issueMethod: IssueMethod;
  handleModalHide: (e?: MouseEvent) => void;
  fetchList: (request: ICouponListRequestParams) => void;
  handleRegistrationEvent: (e: MouseEvent) => void;
  modalMode: ModalFormStatus;
  handleModificationEvent: (e: MouseEvent) => void;
  handleAbortCouponIssuePlan: (e: MouseEvent) => void;
  handleDeleteCouponIssuePlan: (e: MouseEvent) => void;
}

const CouponIssuePlanRegistrationModal: FC<ICouponIssuePlanRegistrationModalProps> = ({
  formItems,
  modalVisible,
  isRegisteringProgress = false,
  handleModalHide,
  issueMethod,
  handleRegistrationEvent,
  modalMode,
  handleModificationEvent,
  handleAbortCouponIssuePlan,
  handleDeleteCouponIssuePlan,
}) => {
  return (
    <Modal
      title="쿠폰발급 계획 등록"
      visible={modalVisible}
      onCancel={handleModalHide}
      width={1000}
      footer={[
        <Button key="back" onClick={handleModalHide}>
          취소
        </Button>,
        <Button
          danger
          key="delete"
          style={modalMode === ModalFormStatus.REVISION ? {} : { display: 'none' }}
          onClick={handleDeleteCouponIssuePlan}
        >
          삭제
        </Button>,
        <Button
          danger
          key="abort"
          style={modalMode === ModalFormStatus.STOP ? {} : { display: 'none' }}
          onClick={handleAbortCouponIssuePlan}
        >
          중단
        </Button>,
        <Button
          key="update"
          type="primary"
          style={issueMethod === IssueMethod.Clip && modalMode === ModalFormStatus.REVISION ? {} : { display: 'none' }}
          onClick={handleModificationEvent}
        >
          수정
        </Button>,
        <Button
          key="add"
          type="primary"
          onClick={handleRegistrationEvent}
          loading={isRegisteringProgress}
          style={modalMode === ModalFormStatus.REGISTRATION ? {} : { display: 'none' }}
        >
          등록
        </Button>,
      ]}
    >
      <Form id="couponRegistrationForm">
        <Row gutter={24}>
          {formItems.map(it => (
            <Col span={it.span} key={it.label}>
              <Form.Item
                labelCol={{ span: it.labelSpan }}
                wrapperCol={{ span: it.wrapperSpan, offset: it.wrapperOffset ? it.wrapperOffset : 1 }}
                label={it.label}
              >
                {it.itemNode}
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    </Modal>
  );
};

export default CouponIssuePlanRegistrationModal;
