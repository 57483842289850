import React, { useEffect, useState } from 'react';
import IPagination, { initialPagination } from 'views/types/IPagination';
import {
  IProductTableData,
  IProductTableProps,
} from 'views/components/Overseas/PreReservationV2/table/ProductTable';
import Product from 'views/components/Overseas/PreReservationV2/Product';
import IListRequest from 'domain/IListRequest';
import IProductsFilter from 'domain/preReservationV2/IProductsFilter';
import IProduct from 'domain/preReservationV2/IProduct';
import { fetchProducts } from 'api/overseas/preReservationV2/product';

const ProductContainer = () => {
  const initParam: IListRequest<IProductsFilter> = {
    page: 0,
    size: 10,
    filter: {},
  };

  const [param, setParam] = useState<IListRequest<IProductsFilter>>(initParam);
  const [tableData, setTableData] = useState<IProductTableData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>(initialPagination);

  const fetchList = async (request: IListRequest<IProductsFilter>) => {
    setTableLoading(true);
    try {
      const response = await fetchProducts(request);
      const products: IProduct[] = response.data.items;
      const pagination = response
        ? convertToPagination(request, response.data.total)
        : initialPagination;
      setTableData(products.map(x => toTableData(x)));
      setPagination(pagination);
    } catch (e) {
      console.log(e);
    }
    setTableLoading(false);
  };

  const convertToPagination = (
    request: IListRequest<IProductsFilter>,
    total: number,
  ): IPagination => {
    return {
      total: total,
      current: request.page ? request.page + 1 : 1,
      pageSize: 10,
      showSizeChanger: false,
      onShowSizeChange: () => {},
      onChange: handlePageChange,
    };
  };

  const handlePageChange = (page: number) => {
    const changedParam: IListRequest<IProductsFilter> = {
      ...param,
      page: page - 1,
    };
    setParam(changedParam);
    fetchList(changedParam);
  };

  const tableProps: IProductTableProps = {
    source: tableData,
    pagination: pagination,
    tableLoading: tableLoading,
    fetchList,
  };

  function toTableData(data: IProduct) {
    const result: IProductTableData = {
      key: data.id,
      product: data,
    };
    return result;
  }

  useEffect(() => {
    fetchList(initParam);
  }, []);

  return <Product tableProps={tableProps} />;
};

export default ProductContainer;
