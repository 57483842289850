import React, { FC, MouseEvent } from 'react';
import { Table, Button } from 'antd';
import IPagination from 'views/types/IPagination';
import { IColumn } from 'views/types/IColumn';

export interface ICouponIssuePlanTableData {
  key: string;
  id: string;
  sequence: number;
  schemaName: string;
  schemaDisplayName: string;
  discountType: string;
  discountAmountText: string;
  issuePeriodText: string;
  validityPeriodText: string;
  totalCount: string;
  issuedCount: string;
  usedCount: string;
  unIssuedCount: string;
  unUsedCount: string;
  status: string;
  numberOfPeopleForPresentation: string;
}

const getColumns = (handleViewModalShow: (e: MouseEvent, id: string) => void): IColumn<ICouponIssuePlanTableData>[] => {
  return [
    {
      key: 'sequence',
      title: 'ID',
      dataIndex: 'sequence',
      render: (text, recode) => (
        <Button type="link" onClick={e => handleViewModalShow(e, recode.id)}>
          {text}
        </Button>
      ),
    },
    {
      key: 'schemaName',
      title: '쿠폰스키마명',
      dataIndex: 'schemaName',
      render: text => <p>{text}</p>,
    },
    {
      key: 'schemaDisplayName',
      title: '쿠폰표시명',
      dataIndex: 'schemaDisplayName',
      render: text => <p>{text}</p>,
    },
    {
      key: 'issuePeriodText',
      title: '발행기간/지급일시',
      dataIndex: 'issuePeriodText',
      render: text => <p>{text}</p>,
    },
    {
      key: 'totalCount',
      title: '발행건',
      dataIndex: 'totalCount',
      render: text => <p>{text}</p>,
    },
    {
      key: 'issuedCount',
      title: '발급건',
      dataIndex: 'issuedCount',
      render: text => <p>{text}</p>,
    },
    {
      key: 'numberOfPeopleForPresentation',
      title: '발급 인원 수',
      dataIndex: 'numberOfPeopleForPresentation',
      render: text => <p>{text}</p>,
    },
    {
      key: 'usedCount',
      title: '사용건',
      dataIndex: 'usedCount',
      render: text => <p>{text}</p>,
    },
    {
      key: 'unIssuedCount',
      title: '미발급건',
      dataIndex: 'unIssuedCount',
      render: text => <p>{text}</p>,
    },
    {
      key: 'unUsedCount',
      title: '미사용건',
      dataIndex: 'unUsedCount',
      render: text => <p>{text}</p>,
    },
    {
      key: 'status',
      title: '상태',
      dataIndex: 'status',
      render: text => <p>{text}</p>,
    },
  ];
};

export interface ICouponIssuePlanTableProps {
  tableData: ICouponIssuePlanTableData[];
  tablePagination: IPagination;
  tableLoading: boolean;
  handleViewModalShow: (e: MouseEvent, id: string) => void;
}

const CouponIssuePlanTable: FC<ICouponIssuePlanTableProps> = ({
  tableData,
  tablePagination,
  tableLoading,
  handleViewModalShow,
}) => {
  const columns = getColumns(handleViewModalShow);
  return (
    <Table<ICouponIssuePlanTableData>
      columns={columns}
      dataSource={tableData}
      pagination={tablePagination}
      loading={tableLoading}
    />
  );
};

export default CouponIssuePlanTable;
