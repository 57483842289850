import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { IOptionItemForm } from 'domain/preReservationV2/IProductCreateRequest';
import moment from 'moment';
import React from 'react';
import * as XLSX from 'xlsx';

interface OptionItemUploadProps {
  handleUploadSuccess: (
    optionItems: IOptionItemForm[],
    lowestPrice: number,
  ) => void;
}

export default function OptionItemUpload(props: OptionItemUploadProps) {
  const optionItemUploadProps = {
    accept: '.csv',
    beforeUpload: (file: Blob) => {
      const reader = new FileReader();
      reader.onload = e => {
        if (e.target != null) {
          const workBook = XLSX.read(e.target.result, { type: 'binary' });
          const firstSheetName = workBook.SheetNames[0];
          const workSheet = workBook.Sheets[firstSheetName];

          try {
            const optionItems = toOptionItemList(
              XLSX.utils.sheet_to_json(workSheet, {
                raw: false,
                dateNF: 'yyyy-mm-dd',
              }),
            ).filter(it => it.priceAdult > 0);

            const minAdultOptionItem = optionItems
              .filter(x => x.priceAdult > 0)
              .reduce((a, b) => (a.priceAdult < b.priceAdult ? a : b));

            props.handleUploadSuccess(
              optionItems,
              minAdultOptionItem.priceAdult,
            );
            message.success('불러오기 완료');
          } catch (e) {
            message.error(e.message);
          }
        }
      };
      reader.readAsText(file);
      return false;
    },
  };

  const toOptionItemList = (rows: any[]): IOptionItemForm[] => {
    if (rows.length === 0) {
      throw Error('1개 이상의 데이터가 있어야 합니다');
    }

    ensureExcelFormat(rows[0]);

    return rows.map(toOptionItem);
  };

  const ensureExcelFormat = (row: any) => {
    const keys = Object.keys(row);
    if (!['출발일'].every((key, index) => key == keys[index])) {
      throw Error('엑셀 포멧이 유효하지 않습니다');
    }
  };

  const toOptionItem = (row: any): IOptionItemForm => {
    return {
      departureDate: moment(row['출발일']).format('YYYY-MM-DD'),
      priceAdult:
        '성인가' in row
          ? parseInt(row['성인가'].replace(',', '').replace(/,/g, ''))
          : 0,
      priceChild:
        '아동가' in row
          ? parseInt(row['아동가'].replace(',', '').replace(/,/g, ''))
          : 0,
      priceBaby:
        '유아가' in row
          ? parseInt(row['유아가'].replace(',', '').replace(/,/g, ''))
          : 0,
    };
  };

  return (
    <Upload {...optionItemUploadProps}>
      <Button icon={<UploadOutlined />}>CSV만 업로드 가능!</Button>
    </Upload>
  );
}
