import React, { FC } from 'react';
import { Form, Button, Card, Input, Row, Col, Select, DatePicker, Typography, Space } from 'antd';
import { SearchOutlined, FilterOutlined, SwapRightOutlined } from '@ant-design/icons';
import { ISupplier, IPackageFilters } from 'domain/Package';
import { Store } from 'antd/lib/form/interface';
import './SpecialPricingSearchForm.scss';

interface ISpecialPricingSearchFormProps {
  getPackages: (keyword: string[]) => void;
  loading: boolean;
  suppliers: ISupplier[];
  updateFilter: (filter: IPackageFilters) => void;
}

const SpecialPricingSearchForm: FC<ISpecialPricingSearchFormProps> = ({
  getPackages,
  loading,
  suppliers,
  updateFilter,
}) => {
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const { keyword } = await form.validateFields();
      form.setFieldsValue({
        keyword: keyword.replace(/\s/g, ''),
      });

      getPackages(refine(keyword));
    } catch (errInfo) {
      console.log(errInfo);
    }
  };

  const refine = (input: string): string[] => {
    return input
      .replace(/\s/g, '')
      .split(',')
      .filter((x: string) => !!x);
  };

  const handleFilter = (changedValues: Store, values: Store) => {
    const changedFieldsNames = Object.keys(changedValues);
    if (!(changedFieldsNames.length === 1 && changedFieldsNames[0] === 'keyword')) {
      return updateFilter(values);
    }
  };

  return (
    <Form
      className="SpecialPricingSearchForm"
      name="SpecialPricingSearch"
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      onFinishFailed={errInfo => console.log(errInfo)}
      onValuesChange={handleFilter}
      hideRequiredMark
    >
      <Card size="small" bordered={false} className="search-area">
        <Row>
          <Col span={22}>
            <Form.Item
              label="단일 상품코드 또는 행사코드 목록"
              name="keyword"
              rules={[
                { required: true, message: '상품 코드 또는 행사 코드를 입력해주세요.' },
                {
                  validator: (_, value: string) =>
                    refine(value).length <= 300
                      ? Promise.resolve()
                      : Promise.reject('The number of codes cannot be greater than 300.'),
                  message: '코드는 최대 300개 까지 입력할 수 있습니다.',
                },
              ]}
            >
              <Input autoFocus placeholder="AA5341200207ZE3,AA5341200207ZEE,AA5341200208ZE3" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item shouldUpdate={(prev, cur) => prev.keyword !== cur.keyword}>
              {() => (
                <Button
                  style={{ marginTop: '37.71px' }}
                  type="primary"
                  htmlType="submit"
                  disabled={!form.getFieldValue('keyword') || form.getFieldError('keyword').length > 0}
                  loading={loading}
                  block
                >
                  <SearchOutlined />
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Space className="filter-area" size="middle" align="center">
        <Typography.Text strong>
          <FilterOutlined style={{ marginRight: '0.2rem' }} />
          Filter by
        </Typography.Text>

        <Form.Item name="supplier">
          <Select
            allowClear
            showSearch
            placeholder={<span>여행사</span>}
            loading={loading}
            disabled={loading}
            optionFilterProp="children"
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {suppliers.map((x: ISupplier) => (
              <Select.Option key={x.id} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row gutter={2}>
          <Col>
            <Form.Item name="departureDateStart">
              <DatePicker placeholder="출발일 시작" disabled={loading} />
            </Form.Item>
          </Col>
          <Col>
            <SwapRightOutlined style={{ lineHeight: '2.4' }} />
          </Col>
          <Col>
            <Form.Item name="departureDateEnd">
              <DatePicker placeholder="출발일 종료" disabled={loading} />
            </Form.Item>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

export default SpecialPricingSearchForm;
