import IPagination from '../../../../types/IPagination';
import {
  IPayment,
  IReservation,
} from 'domain/reservation/onda/IOndaReservation';
import React, { FC, MouseEvent, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Select, Table } from 'antd';
import OndaReservationTableColumns from './OndaReservationTableColumns';
import IListRequest from 'domain/IListRequest';
import IOndaReservationsFilter from 'domain/reservation/onda/IOndaReservationsFilter';
import OndaReservationCancellationModal, {
  IOndaReservationCancellationModalProps,
} from 'views/components/Reservations/Onda/Modal/OndaReservationCancellationModal';
import { apiGetCancellationFee } from 'api/reservation/domestic/accommodation/ondaReservation';
import { IRefundAmountInfo } from 'domain/reservation/onda/IOndaRefundAmount';
import ICancellationFee from 'domain/reservation/domestic/accommodation/ICancellationFee';

export interface IOndaReservationTableData {
  key: number;
  reservation: IReservation;
  payment: IPayment;
}

export interface IOndaReservationTableProps {
  source: IOndaReservationTableData[];
  pagination: IPagination;
  tableLoading: boolean;
  cancelReservation: (reservationCode: string) => void;
  handleCancellationPolicyModalShow: (
    e: MouseEvent,
    reservationCode: string,
  ) => void;
  handleDetailsModalShow: (e: MouseEvent, reservationCode: string) => void;
  fetchList: (param: IListRequest<IOndaReservationsFilter>) => void;
}

const OndaReservationTable: FC<IOndaReservationTableProps> = ({
  source,
  pagination,
  tableLoading,
  cancelReservation,
  handleCancellationPolicyModalShow,
  handleDetailsModalShow,
  fetchList,
}) => {
  const [reservationCode, setReservationCode] = useState<string | undefined>(
    undefined,
  );
  const [refundAmountInfo, setRefundAmountInfo] = useState<IRefundAmountInfo>({
    until: '',
    refundAmount: -1,
    fee: -1,
  });
  const [cancellationModalIsVisible, setCancellationModalIsVisible] = useState(
    false,
  );

  const showCancellationModal = async (
    e: MouseEvent,
    reservationCode: string,
    sellingPrice: number,
  ) => {
    try {
      setReservationCode(reservationCode);
      const response = await apiGetCancellationFee(reservationCode);
      const cancellationFee: ICancellationFee = response.data;
      const refundAmountInfo: IRefundAmountInfo = {
        until: cancellationFee.until,
        refundAmount: sellingPrice - cancellationFee.fee,
        fee: cancellationFee.fee,
      };
      setRefundAmountInfo(refundAmountInfo);

      setCancellationModalIsVisible(true);
    } catch (e) {
      console.log(e);
    }
  };

  const columns = OndaReservationTableColumns(
    handleCancelReservation,
    handleCancellationPolicyModalShow,
    handleDetailsModalShow,
    showCancellationModal,
  );
  const [form] = Form.useForm();

  async function handleSearch() {
    try {
      const values = await form.validateFields();
      const pageRequest: IListRequest<IOndaReservationsFilter> = {
        filter: {
          supplierId: values.supplierId,
          reservationCode: values.reservationCode,
          bookerName: values.bookerName,
          bookerPhone: values.bookerPhone,
        },
      };
      fetchList(pageRequest);
    } catch (e) {
      console.log(e);
    }
  }

  async function handleCancelReservation() {
    try {
      await cancelReservation(reservationCode!);
      await handleSearch();
      setCancellationModalIsVisible(false);
      message.success('예약 취소되었습니다.');
    } catch (e) {
      console.log(e);
      message.error('예약 취소에 실패하였습니다. 관리자에게 문의 바랍니다.');
    }
  }

  const hideCancellationPenaltyModal = () => {
    setCancellationModalIsVisible(false);
  };

  const ondaReservationCancellationModalProps: IOndaReservationCancellationModalProps = {
    visible: cancellationModalIsVisible,
    hideModal: hideCancellationPenaltyModal,
    refundAmountInfo: refundAmountInfo,
    cancelReservation: handleCancelReservation,
  };

  return (
    <>
      <Form
        form={form}
        className="OndaReservationSearchForm"
        onFinish={handleSearch}
        initialValues={{
          supplierId: '',
        }}
        style={{ marginBottom: 30 }}
      >
        <Row gutter={4} justify="end" align="top">
          <Form.Item name="supplierId" style={{ margin: 3.5 }}>
            <Select style={{ width: 140 }}>
              <Select.Option value="">전체</Select.Option>
              <Select.Option value="20e9e7bf-9a6a-4faf-b270-86db6bb5923a">
                온다
              </Select.Option>
              <Select.Option value="b9047f69-59a9-412e-ac4c-f38ed64fb0cd">
                인터파크
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="reservationCode" style={{ margin: 3.5 }}>
            <Input placeholder="예약코드" />
          </Form.Item>
          <Form.Item name="bookerName" style={{ margin: 3.5 }}>
            <Input placeholder="예약자명" />
          </Form.Item>
          <Form.Item name="bookerPhone" style={{ margin: 3.5 }}>
            <Input placeholder="휴대폰" />
          </Form.Item>
          <Col>
            <Button type="primary" htmlType="submit" style={{ margin: 3.5 }}>
              검색
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={source}
        pagination={pagination}
        loading={tableLoading}
      />
      <OndaReservationCancellationModal
        {...ondaReservationCancellationModalProps}
      />
    </>
  );
};

export default OndaReservationTable;
