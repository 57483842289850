import React, { useEffect, useState } from 'react';
import IPagination, { initialPagination } from 'views/types/IPagination';
import {
  IThemeTableData,
  IThemeTableProps,
} from 'views/components/Overseas/PreReservationV2/table/ThemeTable';
import Theme from 'views/components/Overseas/PreReservationV2/Theme';
import IListRequest from 'domain/IListRequest';
import IThemesFilter from 'domain/preReservationV2/IThemesFilter';
import ITheme from 'domain/preReservationV2/ITheme';
import { fetchThemes } from 'api/overseas/preReservationV2/theme';

const ThemeContainer = () => {
  const initParam: IListRequest<IThemesFilter> = {
    page: 0,
    size: 10,
    filter: {},
  };

  const [param, setParam] = useState<IListRequest<IThemesFilter>>(initParam);
  const [tableData, setTableData] = useState<IThemeTableData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>(initialPagination);

  const fetchList = async (request: IListRequest<IThemesFilter>) => {
    setTableLoading(true);
    try {
      const response = await fetchThemes(request);
      const themes: ITheme[] = response.data.items;
      const pagination = response
        ? convertToPagination(request, response.data.total)
        : initialPagination;
      setTableData(themes.map(x => toTableData(x)));
      setPagination(pagination);
    } catch (e) {
      console.log(e);
    }
    setTableLoading(false);
  };

  const convertToPagination = (
    request: IListRequest<IThemesFilter>,
    total: number,
  ): IPagination => {
    return {
      total: total,
      current: request.page ? request.page + 1 : 1,
      pageSize: 10,
      showSizeChanger: false,
      onShowSizeChange: () => {},
      onChange: handlePageChange,
    };
  };

  const handlePageChange = (page: number) => {
    const changedParam: IListRequest<IThemesFilter> = {
      ...param,
      page: page - 1,
    };
    setParam(changedParam);
    fetchList(changedParam);
  };

  const tableProps: IThemeTableProps = {
    source: tableData,
    pagination: pagination,
    tableLoading: tableLoading,
    fetchList,
  };

  function toTableData(data: ITheme) {
    const result: IThemeTableData = {
      key: data.id,
      theme: data,
    };
    return result;
  }

  useEffect(() => {
    fetchList(initParam);
  }, []);

  return <Theme tableProps={tableProps} />;
};

export default ThemeContainer;
