import React, { FC, useState } from 'react';
import ITop10Accommodation from 'domain/top10/accommodation/ITop10Accommodation';
import { Button, Col, Form, Input, Pagination, Row, Table } from 'antd';
import Top10AccommodationsTableColumns from 'views/components/Top10/Accommodations/Top10AccommodationsTableColumns';
import IPagination from 'views/types/IPagination';
import IListRequest from 'domain/IListRequest';
import ITop10AccommodationsFilter, {
  Top10AccommodationsFilter,
} from 'domain/top10/accommodation/ITop10AccommodationsFilter';
import { UploadOutlined } from '@ant-design/icons/lib';

export interface ITop10AccommodationsTableData {
  key: string;
  guid: string;
  name: string;
}

export const constructTop10AccommodationsTableData = (
  accommodation: ITop10Accommodation,
): ITop10AccommodationsTableData => {
  return {
    key: accommodation.guid,
    ...accommodation,
  };
};

export interface ITop10AccommodationsTableProps {
  source: ITop10AccommodationsTableData[];
  tableLoading: boolean;
  pagination: IPagination;
  fetchList: (pageRequest: IListRequest<ITop10AccommodationsFilter>) => void;
  showReservationModal: () => void;
}

const Top10AccommodationsTable: FC<ITop10AccommodationsTableProps> = ({
  source,
  tableLoading,
  pagination,
  fetchList,
  showReservationModal,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [form] = Form.useForm();
  const columns = Top10AccommodationsTableColumns();

  const handlePage = async (page: number) => {
    await handleSearch(page - 1);
  };

  const handleSearch = async (page = 0, pageSize = 10) => {
    try {
      setCurrentPage(page + 1);
      const values = await form.validateFields();
      const pageRequest: IListRequest<ITop10AccommodationsFilter> = {
        page: page,
        size: pageSize,
        filter: new Top10AccommodationsFilter(values.guid),
      };
      fetchList(pageRequest);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="Top10AccommodationsSearch">
      <Row>
        <Col span={12}>
          <Button
            icon={<UploadOutlined />}
            style={{ margin: 3.5 }}
            onClick={showReservationModal}
          >
            숙소정보 업로드
          </Button>
        </Col>
        <Col span={12}>
          <Form
            form={form}
            className="Top10AccommodationsSearchForm"
            onFinish={() => handleSearch()}
            style={{ marginBottom: 30 }}
          >
            <Row gutter={4} justify="end" align="top">
              <Form.Item name="guid" style={{ margin: 3.5 }}>
                <Input placeholder="숙소ID" />
              </Form.Item>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ margin: 3.5 }}
                >
                  검색
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={source}
        loading={tableLoading}
        pagination={false}
      />
      <Row justify="end" align="top" style={{ marginTop: 30 }}>
        <Pagination
          current={currentPage}
          total={pagination.total}
          onChange={handlePage}
        />
      </Row>
    </div>
  );
};

export default Top10AccommodationsTable;
