import React, { Dispatch, FC, MouseEvent, SetStateAction, useEffect, useState } from 'react';
import CouponIssuePlanRegistrationModal from 'views/components/Coupon/CouponIssuePlan/CouponIssuePlanRegistrationModal';
import IFormItem from 'views/types/IFormItem';
import ICouponListRequestParams from 'domain/coupon/ICouponListRequestParams';
import Form, { WrappedFormUtils, GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form';
import { UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, InputNumber, message, Select, Upload } from 'antd';
import moment from 'moment';
import { fetchCouponSchemas } from 'api/couponSchema';
import ICouponSchemaData from 'domain/coupon/couponSchema/ICouponSchemaData';
import ICouponListResponse from 'domain/coupon/ICouponListResponse';
import {
  abortCouponIssuePlan,
  addCouponIssuePlan,
  changeCouponIssuePlan,
  deleteCouponIssuePlan,
} from 'api/couponIssuePlan';
import ICouponSchemaErrorBody from 'domain/coupon/couponSchema/ICouponSchemaErrorBody';
import { ModalFormStatus } from 'containers/coupon/couponIssuePlan/CouponIssuePlanContainer';
import { ICouponIssuePlanFormField } from 'services/coupon/couponIssuePlan/CouponIssuePlanFormField';
import CouponIssuePlanViewModel, {
  CouponIssuePlanModificationViewModel,
} from 'services/coupon/couponIssuePlan/CouponIssuePlanViewModel';
import { convertToSelectData } from 'services/coupon/couponIssuePlan/CouponIssuePlanConvertor';
import TimezoneConvertor from 'infra/TimezoneConvertor';
import { IssueMethod } from 'domain/coupon/couponSchema/IssueMethod';
import Text from 'antd/es/typography/Text';
import { RcFile } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';

const { Option } = Select;
const { RangePicker } = DatePicker;

export interface ICouponSchemaSelectProps {
  key: string;
  id: string;
  title: string;
  issueMethod: IssueMethod;
}

interface FormItemMetadata {
  currentUploadedFile?: RcFile;
  issueMethod?: IssueMethod;
  modalStatus: ModalFormStatus;
}

const getCommonFormItems = (
  getFieldDecorator: (id: string, options?: GetFieldDecoratorOptions) => (node: React.ReactNode) => React.ReactNode,
  couponSchemaList: ICouponSchemaSelectProps[],
  onSelectOption: (id: string) => void,
  onUpdateUploadedFile: (file: RcFile) => void,
  metadata: FormItemMetadata,
): IFormItem[] => {
  const canChangeWhenRegistrationOrRevision =
    metadata.modalStatus !== ModalFormStatus.REGISTRATION && metadata.modalStatus !== ModalFormStatus.REVISION;
  return metadata.issueMethod === IssueMethod.Present
    ? [
        {
          label: '쿠폰 스키마',
          span: 10,
          labelSpan: 6,
          wrapperSpan: 17,
          itemNode: getFieldDecorator('schemaId', {
            rules: [{ required: true, message: '쿠폰 스키마를 선택해주세요.' }],
          })(
            <Select
              placeholder="쿠폰 스키마를 선택하세요."
              disabled={metadata.modalStatus !== ModalFormStatus.REGISTRATION}
              onSelect={value => onSelectOption(value.toString())}
            >
              {couponSchemaList.map(x => (
                <Option key={x.key} value={x.id}>
                  {x.title}
                </Option>
              ))}
            </Select>,
          ),
        },
        {
          label: '쿠폰지급일시',
          span: 14,
          labelSpan: 6,
          wrapperSpan: 17,
          itemNode: getFieldDecorator('presentScheduleUtc', {
            rules: [{ required: true, message: '발급기간을 입력하세요.' }],
          })(
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              showTime
              placeholder="발급기간&시간"
              disabled={metadata.modalStatus === ModalFormStatus.REVISION}
            />,
          ),
        },
        {
          label: '발급 단위',
          span: 8,
          labelSpan: 8,
          wrapperSpan: 15,
          itemNode: getFieldDecorator('unitOfIssue', {
            rules: [{ required: true, message: '발급 단위를 입력해주세요.' }],
          })(<InputNumber min={0} disabled={canChangeWhenRegistrationOrRevision} />),
        },
        ...getPresentFormItems(
          getFieldDecorator,
          couponSchemaList,
          canChangeWhenRegistrationOrRevision,
          onUpdateUploadedFile,
          metadata,
        ),
      ]
    : [
        {
          label: '쿠폰 스키마',
          span: 10,
          labelSpan: 6,
          wrapperSpan: 17,
          itemNode: getFieldDecorator('schemaId', {
            rules: [{ required: true, message: '쿠폰 스키마를 선택해주세요.' }],
          })(
            <Select
              placeholder="쿠폰 스키마를 선택하세요."
              disabled={metadata.modalStatus !== ModalFormStatus.REGISTRATION}
              onSelect={value => onSelectOption(value.toString())}
            >
              {couponSchemaList.map(x => (
                <Option key={x.key} value={x.id}>
                  {x.title}
                </Option>
              ))}
            </Select>,
          ),
        },
        {
          label: '발급기간',
          span: 14,
          labelSpan: 6,
          wrapperSpan: 17,
          itemNode: getFieldDecorator('issuePeriod', {
            rules: [{ required: false, message: '발급기간을 입력하세요.' }],
          })(
            <RangePicker
              format="YYYY-MM-DD HH:mm"
              placeholder={['시작일시', '종료일시']}
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')],
              }}
              disabled={canChangeWhenRegistrationOrRevision}
            />,
          ),
        },
        {
          label: '발급 인원수',
          span: 8,
          labelSpan: 8,
          wrapperSpan: 15,
          itemNode: getFieldDecorator('maximumIssueCount', {
            rules: [{ required: false, message: '발급 인원수를 입력해주세요.' }],
          })(<InputNumber min={0} disabled={canChangeWhenRegistrationOrRevision} />),
        },
        {
          label: '발급 단위',
          span: 8,
          labelSpan: 8,
          wrapperSpan: 15,
          itemNode: getFieldDecorator('unitOfIssue', {
            rules: [{ required: true, message: '발급 단위를 입력해주세요.' }],
          })(<InputNumber min={0} disabled={canChangeWhenRegistrationOrRevision} />),
        },
        {
          label: '총 쿠폰수',
          span: 8,
          labelSpan: 8,
          wrapperSpan: 15,
          itemNode: getFieldDecorator('totalCouponCount')(
            <InputNumber readOnly disabled={canChangeWhenRegistrationOrRevision} />,
          ),
        },
      ];
};

const getPresentFormItems = (
  getFieldDecorator: (id: string, options?: GetFieldDecoratorOptions) => (node: React.ReactNode) => React.ReactNode,
  couponSchemaList: ICouponSchemaSelectProps[],
  canChangeWhenRegistrationOrRevision: boolean,
  onChangeUploadFile: (file: RcFile) => void,
  metaData: FormItemMetadata,
): IFormItem[] => {
  const currentUploadedFileName = metaData.currentUploadedFile;
  const formItems: IFormItem[] = [];
  const getCurrentFileName = function() {
    if (currentUploadedFileName) return currentUploadedFileName.name;
    else return '파일 없음';
  };

  const excelForm = {
    label: '엑셀',
    span: 14,
    labelSpan: 3,
    wrapperSpan: 15,
    itemNode: getFieldDecorator('usersAsExcelFile')(
      <Upload
        disabled={canChangeWhenRegistrationOrRevision}
        beforeUpload={file => {
          onChangeUploadFile(file);
          return false;
        }}
        fileList={new Array<UploadFile>()}
        accept=".xlsx"
      >
        <Button disabled={canChangeWhenRegistrationOrRevision}>
          <UploadOutlined /> Click to Upload
        </Button>
        {metaData.modalStatus === ModalFormStatus.REGISTRATION && (
          <Text>
            <br />
            {getCurrentFileName()}
          </Text>
        )}
      </Upload>,
    ),
  };

  if (metaData.modalStatus !== ModalFormStatus.REVISION) {
    formItems.push(excelForm);
  }
  return formItems;
};

export interface ICouponIssuePlanFormModalContainerProps {
  modalVisible: boolean;
  handleModalHide: (e?: MouseEvent) => void;
  fetchList: (request: ICouponListRequestParams) => void;
  modalMode: ModalFormStatus;
  couponIssuePlanData: ICouponIssuePlanFormField;
  handleViewChange: (changedFields: any) => void;
  issueMethod: IssueMethod;
  setIssueMethod: Dispatch<SetStateAction<IssueMethod>>;
}

interface ICouponIssuePlanFormModalContainerWithFormProps {
  form: WrappedFormUtils;
  modalProps: ICouponIssuePlanFormModalContainerProps;
}

const CouponIssuePlanRegistrationModalContainer: FC<ICouponIssuePlanFormModalContainerWithFormProps> = ({
  form,
  modalProps,
}) => {
  const [couponSchemaSelectList, setCouponSchemaSelectList] = useState<ICouponSchemaSelectProps[]>([]);
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const [currentUploadedFile, setCurrentUploadedFile] = useState<RcFile | undefined>(undefined);

  const { getFieldDecorator, validateFields, resetFields } = form;
  const { modalVisible, handleModalHide, fetchList, modalMode, couponIssuePlanData } = modalProps;

  const fetchCouponSchemaSelectList = async () => {
    try {
      const result = await fetchCouponSchemas({ skip: 0, take: 50 });
      const response: ICouponListResponse<ICouponSchemaData> = result.data;
      const formatted: ICouponSchemaSelectProps[] = response
        ? response.items
            .filter(x => {
              const exclusiveEndTimeLocal = TimezoneConvertor.utcToLocal(
                moment.utc(x.validityPeriod.exclusiveEndTimeUtc),
              );
              const now = moment();
              return exclusiveEndTimeLocal[0].isAfter(now);
            })
            .map(x => convertToSelectData(x))
        : [];
      setCouponSchemaSelectList(formatted);
    } catch (e) {
      console.log(e);
    }
  };

  const onSelectSchema = (id: String) => {
    let selectedCouponSchema = couponSchemaSelectList.find(value => value.id === id);
    setCurrentUploadedFile(undefined);
    if (selectedCouponSchema) {
      modalProps.setIssueMethod(selectedCouponSchema.issueMethod);
    }
  };

  const onChangeUploadedFile = (file: RcFile) => {
    setCurrentUploadedFile(file);
  };

  const onCancelModal = (event?: MouseEvent) => {
    handleModalHide();
    setCurrentUploadedFile(undefined);
  };

  const formItems = getCommonFormItems(
    getFieldDecorator,
    couponSchemaSelectList,
    onSelectSchema,
    onChangeUploadedFile,
    {
      currentUploadedFile: currentUploadedFile,
      issueMethod: modalProps.issueMethod,
      modalStatus: modalMode,
    },
  );

  const handleRegistrationEvent = () => {
    validateFields(async (err, values) => {
      if (!err) {
        const data = new CouponIssuePlanViewModel(values, currentUploadedFile);
        await handleAddCouponIssuePlan(data, data.schemaId);
      }
    });
  };

  const handleAddCouponIssuePlan = async (data: CouponIssuePlanViewModel, schemaId: string) => {
    try {
      setIsRegistering(true);
      const couponIssuePlanRequest = data.toAddDomain(modalProps.issueMethod);
      await addCouponIssuePlan(couponIssuePlanRequest, schemaId);
      message.success('쿠폰 발급 계획 등록 성공하였습니다.');
      await fetchList({ skip: 0, take: 10 });
      onCancelModal();
      resetFields();
    } catch (e) {
      let errorMessage = '';
      if (e.response) {
        const body: ICouponSchemaErrorBody = e.response.data;
        errorMessage = body.message;
      } else if (typeof e === 'string') {
        console.log(e);
        errorMessage = e;
      } else {
        console.log(e);
        errorMessage = '쿠폰 발급 계획 등록 실패하였습니다. 관리자에게 문의바랍니다.';
      }
      message.error(errorMessage);
    } finally {
      setIsRegistering(false);
    }
  };

  const handleModificationEvent = () => {
    validateFields(async (err, values) => {
      if (!err) {
        const data = new CouponIssuePlanModificationViewModel({
          issuePlanId: couponIssuePlanData.issuePlanId,
          ...values,
        });
        await handleChangeCouponIssuePlan(data, data.schemaId);
      }
    });
  };

  const handleChangeCouponIssuePlan = async (data: CouponIssuePlanModificationViewModel, schemaId: string) => {
    try {
      await changeCouponIssuePlan(data.toChangeDomain(), schemaId);

      message.success('쿠폰 발급 계획 수정 성공하였습니다.');
      await fetchList({ skip: 0, take: 10 });
      onCancelModal();
      resetFields();
    } catch (e) {
      let errorMessage = '';
      if (e.response) {
        const body: ICouponSchemaErrorBody = e.response.data;
        errorMessage = body.message;
      } else {
        console.log(e);
        errorMessage = '오류가 발생하였습니다. 관리자에게 문의바랍니다.';
      }
      message.error(errorMessage);
    }
  };

  const handleAbortCouponIssuePlan = async () => {
    try {
      if (!couponIssuePlanData.issuePlanId) {
        message.error('필수값이 존재하지 않습니다.');
        return;
      }

      await abortCouponIssuePlan(couponIssuePlanData.schemaId.value, couponIssuePlanData.issuePlanId!!);

      message.success('쿠폰 발급 계획 중단하였습니다.');
      fetchList({ skip: 0, take: 10 });
      onCancelModal();
    } catch (e) {
      console.log(e);
      message.error('오류가 발생하였습니다. 관리자에게 문의바랍니다.');
    }
  };

  const handleDeleteCouponIssuePlan = async () => {
    try {
      if (!couponIssuePlanData.issuePlanId) {
        message.error('필수값이 존재하지 않습니다.');
        return;
      }

      await deleteCouponIssuePlan(couponIssuePlanData.schemaId.value, couponIssuePlanData.issuePlanId!!);

      message.success('쿠폰 발급 계획 삭제하였습니다.');
      fetchList({ skip: 0, take: 10 });
      onCancelModal();
    } catch (e) {
      console.log(e);
      message.error('오류가 발생하였습니다. 관리자에게 문의바랍니다.');
    }
  };

  useEffect(() => {
    fetchCouponSchemaSelectList();
  }, []);

  return (
    <CouponIssuePlanRegistrationModal
      formItems={formItems}
      modalVisible={modalVisible}
      isRegisteringProgress={isRegistering}
      issueMethod={modalProps.issueMethod}
      fetchList={fetchList}
      handleModalHide={() => {
        onCancelModal();
      }}
      handleRegistrationEvent={handleRegistrationEvent}
      modalMode={modalMode}
      handleModificationEvent={handleModificationEvent}
      handleAbortCouponIssuePlan={handleAbortCouponIssuePlan}
      handleDeleteCouponIssuePlan={handleDeleteCouponIssuePlan}
    />
  );
};

export default Form.create<ICouponIssuePlanFormModalContainerWithFormProps>({
  onFieldsChange(props, changedFields) {
    const { modalProps } = props;

    let totalCouponCount = null;

    if (changedFields.maximumIssueCount) {
      totalCouponCount = modalProps.couponIssuePlanData.unitOfIssue.value * changedFields.maximumIssueCount.value;
    }

    if (changedFields.unitOfIssue) {
      totalCouponCount = modalProps.couponIssuePlanData.maximumIssueCount.value * changedFields.unitOfIssue.value;
    }

    const changingField = totalCouponCount
      ? {
          ...changedFields,
          totalCouponCount: {
            name: 'totalCouponCount',
            value: totalCouponCount,
          },
        }
      : changedFields;

    modalProps.handleViewChange(changingField);
  },
  mapPropsToFields(props) {
    const data = props.modalProps.couponIssuePlanData;
    return {
      schemaId: Form.createFormField(data.schemaId),
      issuePeriod: Form.createFormField(data.issuePeriod),
      maximumIssueCount: Form.createFormField(data.maximumIssueCount),
      unitOfIssue: Form.createFormField(data.unitOfIssue),
      totalCouponCount: Form.createFormField(data.totalCouponCount),
      presentScheduleUtc: Form.createFormField(data.presentScheduleUtc),
    };
  },
})(CouponIssuePlanRegistrationModalContainer);
