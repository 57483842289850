import moment from 'moment';
import React, {FC, useEffect, useState} from 'react';
import Suppliers from 'views/components/Partners/Suppliers';
import {
  ISuppliersTableData,
  ISuppliersTableProps
} from "views/components/Partners/Suppliers/SuppliersTable";
import IPagination, { initialPagination } from 'views/types/IPagination';
import ISuppliersRequestParams from 'domain/suppliers/ISuppliersRequestParams';
import { fetchSuppliers } from 'api/suppliers';
import ISuppliersResponse from 'domain/suppliers/ISuppliersResponse';
import ISupplierData from 'domain/suppliers/ISupplierData';

export interface IHistory {
  tenantStatus: string;
  createdAt: string;
}

const convertToTableData = (domain: ISupplierData): ISuppliersTableData => {
  const key = domain.id;
  const supplierName = domain.supplier.companyName;
  const administratorName = domain.administrator.name;
  const administratorEmail = domain.administrator.email;

  const histories: IHistory[] = (domain.histories);
  const applied = histories.filter(l => { return l.tenantStatus === "Applied" });
  const judged = histories.filter(l => { return l.tenantStatus === "Approved" || l.tenantStatus === "Rejected" });
  const contractCompleted = histories.filter(l => { return l.tenantStatus === "ContractCompleted" });
  const activated = histories.filter(l => { return l.tenantStatus === "Activated" });
  let status = "";
  if (histories.length < 1) {
    status = "-";
  } else if (histories[0].tenantStatus === "Applied") {
    status = "심사대기";
  } else if (histories[0].tenantStatus === "Approved") {
    status = "계약대기"
  } else if (histories[0].tenantStatus === "ContractCompleted") {
    status = "가입대기"
  } else if (histories[0].tenantStatus === "Rejected") {
    status = "입점반려"
  } else if (histories[0].tenantStatus === "Activated") {
    status = "사용중";
  }

  const appliedAt = applied.length > 0 ? moment(applied[0]["createdAt"]).local().format('YYYY-MM-DD HH:mm') : '-';
  const judgedAt = judged.length > 0 ? moment(judged[0]["createdAt"]).local().format('YYYY-MM-DD HH:mm') : '-';
  const contractCompletedAt = contractCompleted.length > 0 ? moment(contractCompleted[0]["createdAt"]).local().format('YYYY-MM-DD HH:mm') : '-';
  const activatedAt = activated.length > 0 ? moment(activated[0]["createdAt"]).local().format('YYYY-MM-DD HH:mm') : '-';
  return {
    key,
    supplierName,
    administratorName,
    administratorEmail,
    appliedAt,
    judgedAt,
    contractCompletedAt,
    activatedAt,
    status,
  };
};

const convertToPagination = (
  request: ISuppliersRequestParams,
  total: number,
  handlePageChange: (page: number, pageSize?: number) => void,
): IPagination => {
  return {
    total: total,
    current: request.skip / request.take + 1,
    pageSize: request.take,
    showSizeChanger: false,
    onShowSizeChange: () => {
    },
    onChange: handlePageChange,
  };
};

const SuppliersContainer: FC = () => {
  const [tableData, setTableData] = useState<ISuppliersTableData[]>([]);
  const [tablePagination, setTablePagination] = useState<IPagination>(initialPagination);
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const fetchList = async (request: ISuppliersRequestParams) => {
    setTableLoading(true);
    try {
      const result = await fetchSuppliers(request);
      const response: ISuppliersResponse<ISupplierData> = result;
      const formatted: ISuppliersTableData[] = response ? response.data.map(x => convertToTableData(x)) : [];
      const pagination: IPagination = response
        ? convertToPagination(request, 100, handlePageChange)
        : initialPagination;
      setTableData(formatted);
      setTablePagination(pagination);
    } catch (e) {
      console.log(e);
    } finally {
      setTableLoading(false);
    }
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    const take = pageSize ? pageSize : 10;
    const skip = (page - 1) * take;
    fetchList({ skip: skip, take: take });
  };

  useEffect(() => {
    fetchList({ skip: 0, take: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableProps : ISuppliersTableProps = {
    source: tableData,
    pagination: tablePagination,
    loading: tableLoading,
  };

  return (
    <Suppliers
      tableProps={tableProps}
      fetchList={fetchList}
    />
  );
};

export default SuppliersContainer;
