import React, { FC, MouseEvent } from 'react';
import { Table } from 'antd';
import IPagination from '../../../types/IPagination';
import TripPlannerReservationTableColumns from './TripPlannerReservationTableColumns';
import { TripPlannerReservationStatus } from '../../../../domain/tripPlannerReservation/TripPlannerReservationStatus';

interface IReservation {}

export interface ITripPlannerReservationTableData {
  key: string;
  id: string;
  name: string;
  mobile: string;
  headCount: ITripPlannerReservationHeadCount;
  createdAt: string;
  agencyName: string;
  pcode: string;
  title: string;
  packageId: string;
  departureAt: string;
  listingPrice: number;
  sellingPrice: number;
  status: string;
  plannerName: string;
  history: string;
  questions: string;
  reservationInfo: string;
  reservation?: IReservation;
}

interface ITripPlannerReservationHeadCount {
  adult: number;
  child: number;
  baby: number;
}

export interface ITripPlannerReservationTableProps {
  source: ITripPlannerReservationTableData[];
  pagination: IPagination;
  tableLoading: boolean;
  handleQuestionModalShow: (e: MouseEvent, targeId: string) => void;
  handleClipboardModalShow: (e: MouseEvent, targeId: string) => void;
  handleHistoryModalShow: (e: MouseEvent, targeId: string) => void;
  handleChangeStatus: (e: MouseEvent, targetId: string, status: TripPlannerReservationStatus) => void;
  status: string;
}

const TripPlannerReservationTable: FC<ITripPlannerReservationTableProps> = ({
  source,
  pagination,
  tableLoading,
  handleQuestionModalShow,
  handleClipboardModalShow,
  handleHistoryModalShow,
  handleChangeStatus,
  status,
}) => {
  const columns = TripPlannerReservationTableColumns({
    handleQuestionModalShow,
    handleClipboardModalShow,
    handleHistoryModalShow,
    handleChangeStatus,
    status,
  });

  return (
    <Table<ITripPlannerReservationTableData>
      columns={columns}
      dataSource={source}
      pagination={pagination}
      loading={tableLoading}
    />
  );
};

export default TripPlannerReservationTable;
