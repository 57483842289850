import moment, { Moment } from 'moment';

export default class TimezoneConvertor {
  static utcToLocal(...utcMoment: Moment[]): Moment[] {
    return utcMoment.filter(x => x.isUTC()).map(x => moment.utc(x).utcOffset('+0900'));
  }

  static localToUtc(...localMoment: Moment[]): Moment[] {
    return localMoment.filter(x => !x.isUTC()).map(x => moment.utc(x));
  }
}
