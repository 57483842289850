import React, { FC, MouseEvent } from 'react';
import { PageHeader } from 'antd';
import 'views/styles/Coupon/CouponIssuePlan.scss';
import CouponIssuePlanRegistrationModalContainer, {
  ICouponIssuePlanFormModalContainerProps,
} from 'containers/coupon/couponIssuePlan/CouponIssuePlanRegistrationModalContainer';
import CouponIssuePlanSearchForm from 'views/components/Coupon/CouponIssuePlan/CouponIssuePlanSearchForm';
import CouponIssuePlanTable, { ICouponIssuePlanTableProps } from 'views/components/Coupon/CouponIssuePlan/CouponIssuePlanTable';

interface ICouponIssuePlanProps {
  handleRegistrationModalShow: (e?: MouseEvent) => void;
  tableProps: ICouponIssuePlanTableProps;
  modalProps: ICouponIssuePlanFormModalContainerProps;
}

const CouponIssuePlan: FC<ICouponIssuePlanProps> = ({ handleRegistrationModalShow, tableProps, modalProps }) => {
  return (
    <div className="CouponIssuePlan">
      <PageHeader className="page-header" title={<h1>쿠폰발급 계획</h1>} />
      <CouponIssuePlanSearchForm handleRegistrationModalShow={handleRegistrationModalShow} />
      <CouponIssuePlanTable {...tableProps} />
      <CouponIssuePlanRegistrationModalContainer modalProps={modalProps} />
    </div>
  );
};

export default CouponIssuePlan;
