import React from 'react';
import { IColumn } from 'views/types/IColumn';
import { ISuppliersTableData } from "views/components/Partners/Suppliers/SuppliersTable/index";

const columns: IColumn<ISuppliersTableData>[] = [
  {
    key: 'supplierName',
    title: '여행사',
    dataIndex: 'supplierName',
    render: text => <p>{text}</p>,
  },
  {
    key: 'administratorName',
    title: '대표관리자',
    dataIndex: 'administratorName',
    render: text => <p>{text}</p>,
  },
  {
    key: 'administratorEmail',
    title: '이메일',
    dataIndex: 'administratorEmail',
    render: text => <p>{text}</p>,
  },
  {
    key: 'appliedAt',
    title: '입점신청 일시',
    dataIndex: 'appliedAt',
    render: text => <p>{text}</p>,
  },
  {
    key: 'judgedAt',
    title: '입점심사 일시',
    dataIndex: 'judgedAt',
    render: text => <p>{text}</p>,
  },
  {
    key: 'contractCompletedAt',
    title: '계약서 확인 일시',
    dataIndex: 'contractCompletedAt',
    render: text => <p>{text}</p>,
  },
  {
    key: 'activatedAt',
    title: '대표관리자 가입일시',
    dataIndex: 'activatedAt',
    render: text => <p>{text}</p>,
  },
  {
    key: 'status',
    title: '상태',
    dataIndex: 'status',
    render: text => <p>{text}</p>,
  },
];

export default columns;
