import React, { FC, useRef, useState } from 'react';
import {
  PageHeader,
  Button,
  Form,
  Input,
  Divider,
  Row,
  Col,
  message,
  Radio,
} from 'antd';
import '../../../styles/Overseas/PreReservationV2/ProductCreate.scss';
import '../../../styles/Overseas/PreReservationV2/PreReservationV2.scss';
import ContentGroupSection, {
  IContentGroupSectionRefObject,
} from './ContentGroupSection';
import OptionSection, { IOptionSectionRefObject } from './OptionSection';
import IProductCreateRequest, {
  IContentGroupForm,
  IOptionForm,
} from 'domain/preReservationV2/IProductCreateRequest';
import { postProduct } from 'api/overseas/preReservationV2/product';

const ProductCreate: FC = ({}) => {
  const LAST_PRODUCT_CREATE_REQUEST_LOCAL_STORAGE = 'lastProductCreateRequest';

  const [form] = Form.useForm();
  const optionSectionRef = useRef<IOptionSectionRefObject>();
  const contentGroupSectionRef = useRef<IContentGroupSectionRefObject>();
  const [lastProductCreateRequest, setLastProductCreateRequest] = useState<
    IProductCreateRequest | undefined
  >(() => {
    let json = window.localStorage.getItem(
      LAST_PRODUCT_CREATE_REQUEST_LOCAL_STORAGE,
    );
    return json ? JSON.parse(json) : undefined;
  });

  const handleCreateProduct = async () => {
    try {
      const request = toRequest(
        await form.validateFields(),
        await optionSectionRef.current!!.getForm(),
        await contentGroupSectionRef.current!!.getForm(),
      );

      await postProduct(request);
      window.localStorage.setItem(
        LAST_PRODUCT_CREATE_REQUEST_LOCAL_STORAGE,
        JSON.stringify(request),
      );

      alert('상품을 등록했습니다');
      window.location.reload();
    } catch (e) {
      message.error(e.message);
    }
  };

  const toRequest = (
    data: any,
    options: IOptionForm[],
    contentGroups: IContentGroupForm[],
  ): IProductCreateRequest => ({
    id: data.id,
    isActive: data.isActive,
    elements: {
      head: {
        title: data.headTitle,
        meta: {
          description: data.displayName,
          og: {
            image: data.ogImage,
            title: data.ogTitle,
            description: data.displayName,
            url: data.ogUrl,
          },
        },
      },
      toast: {
        title: data.toast,
      },
    },
    displayName: data.displayName,
    category: data.category,
    image: data.image,
    tags: data.tags?.split(','),
    contentGroups: contentGroups,
    optionDescription: data.optionDescription,
    options: options,
    place: data.place,
    log: {
      campaign: data.log,
    },
  });

  return (
    <div className="pre-reservation-v2 product-create">
      <PageHeader className="page-header" title={<h1>상품등록</h1>} />

      <Form
        form={form}
        initialValues={{
          id: '',
          isActive: true,
          headTitle: lastProductCreateRequest
            ? lastProductCreateRequest.elements.head.title
            : '',
          place: lastProductCreateRequest ? lastProductCreateRequest.place : '',
          ogImage: '',
          ogUrl: '',
          toast: '',
          log: '',
          displayName: '',
          category: '',
          image: '',
          tags: '',
          optionDescription: '',
        }}
        labelCol={{ span: 4 }}
      >
        <Form.Item name="id" label="상품ID">
          <Input placeholder="상품ID" />
        </Form.Item>
        <Form.Item name="isActive" label="isActive">
          <Radio.Group defaultValue={true}>
            <Radio value={true}>활성화</Radio>
            <Radio value={false}>비활성화</Radio>
          </Radio.Group>
        </Form.Item>

        <Divider />

        <Form.Item name="headTitle" label="headTitle">
          <Input placeholder="headTitle" />
        </Form.Item>
        <Form.Item name="place" label="place">
          <Input placeholder="place" />
        </Form.Item>
        <Form.Item name="ogImage" label="og_image">
          <Input placeholder="og_image" />
        </Form.Item>
        <Form.Item name="ogTitle" label="og_title">
          <Input placeholder="og_title" />
        </Form.Item>
        <Form.Item name="ogUrl" label="og_url">
          <Input placeholder="og_url" />
        </Form.Item>
        <Form.Item name="toast" label="toast">
          <Input placeholder="toast" />
        </Form.Item>
        <Form.Item name="log" label="로그명">
          <Input placeholder="log" />
        </Form.Item>

        <Divider />

        <Form.Item name="displayName" label="상품이름">
          <Input placeholder="상품이름" />
        </Form.Item>
        <Form.Item name="category" label="카테고리">
          <Input placeholder="카테고리" />
        </Form.Item>
        <Form.Item name="image" label="상단 이미지">
          <Input placeholder="상단 이미지 주소 입력" />
        </Form.Item>
        <Form.Item name="tags" label="태그">
          <Input placeholder="태그(,로 구분 : 태그1,태그2,태그3)" />
        </Form.Item>

        <Divider orientation="left">예약옵션</Divider>

        <Form.Item name="optionDescription" label="옵션상세">
          <Input placeholder="옵션상세" />
        </Form.Item>
      </Form>

      <OptionSection ref={optionSectionRef} />
      <ContentGroupSection ref={contentGroupSectionRef} />

      <Divider />
      <div className="center">
        <Button type="primary" size="large" onClick={handleCreateProduct}>
          저장하기
        </Button>
      </div>
    </div>
  );
};

export default ProductCreate;
