import React, { FC, forwardRef, RefObject, useImperativeHandle } from 'react';
import { Card, Form, Input } from 'antd';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import '../../../styles/Overseas/PreReservationV2/ProductCreate.scss';
import { IProductGroupForm } from 'domain/preReservationV2/IThemeCreateRequest';
import ProductSelector from './ProductSelector';
import IProductSummary from 'domain/preReservationV2/IProductSummary';
import DeletePopconfirmButton from './DeletePopconfirmButton';

interface IProductGroupFormProps {
  index: number;
  ref: React.Ref<unknown>;
  handleDelete: (ref: RefObject<IProductGroupFormRefObject>) => void;
}

export interface IProductGroupFormRefObject {
  getForm: () => Promise<IProductGroupForm>;
}

const ProductGroupForm: FC<IProductGroupFormProps> = forwardRef(
  ({ index, handleDelete }: IProductGroupFormProps, ref) => {
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
      async getForm(): Promise<IProductGroupForm> {
        const data = await form.validateFields();

        return {
          thumbnail: data.thumbnail,
          displayName: data.displayName,
          category: data.category,
          tags: data.tags?.split(','),
          sortPriority: data.sortPriority,
          productId: data.productId,
        };
      },
    }));

    const handleChangeProduct = (productSummary: IProductSummary) => {
      form.setFieldsValue({
        productId: productSummary.id,
        thumbnail: productSummary.image,
        displayName: productSummary.displayName,
        category: productSummary.category,
        tags: productSummary.tags.join(', '),
        lowestPrice: productSummary.lowestPrice,
      });
    };

    return (
      <Card
        title={`상품 - ${index + 1}`}
        extra={
          <DeletePopconfirmButton
            handleConfirm={() =>
              handleDelete(ref as RefObject<IProductGroupFormRefObject>)
            }
          />
        }
        size="small"
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          initialValues={{
            thumbnail: '',
            displayName: '',
            category: '',
            tags: '',
            sortPriority: 0,
            productId: 0,
          }}
        >
          <Form.Item label="상품상세 선택">
            <ProductSelector handleChange={handleChangeProduct} />
          </Form.Item>
          <Form.Item hidden name="productId" label="상품아이디">
            <Input />
          </Form.Item>
          <Form.Item name="thumbnail" label="썸네일">
            <Input placeholder="썸네일" />
          </Form.Item>
          <Form.Item name="displayName" label="상품이름">
            <Input placeholder="상품이름" />
          </Form.Item>
          <Form.Item name="category" label="카테고리">
            <Input placeholder="카테고리" />
          </Form.Item>
          <Form.Item name="tags" label="태그">
            <Input placeholder="태그" />
          </Form.Item>
          <Form.Item name="lowestPrice" label="최저가">
            <Input placeholder="" disabled />
          </Form.Item>
        </Form>
      </Card>
    );
  },
);

export default ProductGroupForm;
