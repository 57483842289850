import React, {
  createRef,
  FC,
  forwardRef,
  RefObject,
  useImperativeHandle,
  useState,
} from 'react';
import { Button, Card, Form, Input } from 'antd';

import '../../../styles/Overseas/PreReservationV2/ProductCreate.scss';
import { IProductGroupSectionForm } from 'domain/preReservationV2/IThemeCreateRequest';
import ProductGroupForm, {
  IProductGroupFormRefObject,
} from './ProductGroupForm';
import DeletePopconfirmButton from './DeletePopconfirmButton';

interface IProductGroupSectionFormProps {
  index: number;
  ref: React.Ref<unknown>;
  handleDelete: (ref: RefObject<IProductGroupSectionFormRefObject>) => void;
}

export interface IProductGroupSectionFormRefObject {
  getForm: () => Promise<IProductGroupSectionForm>;
}

const ProductGroupSectionForm: FC<IProductGroupSectionFormProps> = forwardRef(
  ({ index, handleDelete }: IProductGroupSectionFormProps, ref) => {
    const [form] = Form.useForm();

    const [productGroups, setProductGroups] = useState<
      RefObject<IProductGroupFormRefObject>[]
    >([]);

    useImperativeHandle(ref, () => ({
      async getForm(): Promise<IProductGroupSectionForm> {
        const data = await form.validateFields();

        return {
          displayName: data.displayName,
          sortPriority: data.sortPriority,
          productGroups: await Promise.all(
            productGroups
              .filter(it => it.current !== undefined)
              .map(async it => await it.current!!.getForm()),
          ),
        };
      },
    }));

    const handleAddProduct = () => {
      setProductGroups([...productGroups, createRef()]);
    };

    const handleDeleteProductGroup = (
      ref: RefObject<IProductGroupFormRefObject>,
    ) => {
      setProductGroups(productGroups.filter(it => it !== ref));
    };

    return (
      <Card
        title={`상품그룹 섹션 - ${index + 1}`}
        extra={
          <DeletePopconfirmButton
            handleConfirm={() =>
              handleDelete(ref as RefObject<IProductGroupSectionFormRefObject>)
            }
          />
        }
        size="small"
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          initialValues={{
            displayName: '',
            sortPriority: 0,
          }}
        >
          <Form.Item name="displayName" label="타이틀명">
            <Input placeholder="타이틀명" />
          </Form.Item>
          <Form.Item hidden name="sortPrioirty" label="정렬순서">
            <Input placeholder="" disabled />
          </Form.Item>
        </Form>

        {productGroups.map((it, index) => (
          <ProductGroupForm
            ref={it}
            key={index}
            index={index}
            handleDelete={handleDeleteProductGroup}
          />
        ))}

        <div className="center">
          <Button size="large" onClick={handleAddProduct}>
            상품 추가
          </Button>
        </div>
      </Card>
    );
  },
);

export default ProductGroupSectionForm;
