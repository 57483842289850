import { AxiosResponse } from 'axios';
import { IPackages, getPackagesByPackagePlanCode, getPackagesByPackageCodes } from 'api/specialPricing';
import mergeObjectsInUnique from 'infra/mergeObjectsInUnique';
import { IPackage } from 'domain/Package';

export default async function searchTargetPackages(codes: string[]): Promise<IPackage[]> {
  if (codes.length === 1) {
    const responses: AxiosResponse<IPackages>[] = await Promise.all(
      codes
        .map((item: string): Promise<AxiosResponse<IPackages>> => getPackagesByPackagePlanCode(item.toUpperCase()))
        .concat(getPackagesByPackageCodes(codes)),
    );

    return mergeObjectsInUnique<IPackage, 'id'>(responses.map(res => res.data.items).flat(), 'id');
  }

  const response: AxiosResponse<IPackages> = await getPackagesByPackageCodes(codes);
  return response.data.items;
}
