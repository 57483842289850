import React from 'react';
import OptionItemUpload from '../OptionItemUpload';
import { IOptionItemForm } from 'domain/preReservationV2/IProductCreateRequest';
import { IOption } from 'domain/preReservationV2/IOption';

interface IOptionTableColumnsProp {
  handleOptionItemChange: (id: number, optionItems: IOptionItemForm[]) => void;
}
const OptionTableColumns = (props: IOptionTableColumnsProp) => {
  const columns = [
    {
      key: 'id',
      title: '옵션아이디',
      dataIndex: 'option',
      render: (option: IOption) => `${option.id}`,
    },
    {
      key: 'displayName',
      title: '옵션명',
      dataIndex: 'option',
      render: (option: IOption) => `${option.displayName}`,
    },
    {
      key: '',
      title: '옵션아이템 수정',
      dataIndex: 'option',
      render: (option: IOption) => (
        <div>
          <OptionItemUpload
            handleUploadSuccess={(optionItems, lowestPrice) =>
              props.handleOptionItemChange(option.id, optionItems)
            }
          />
        </div>
      ),
    },
  ];

  return columns;
};

export default OptionTableColumns;
