import { Moment } from 'moment';
import {
  AddCouponIssuePlanToClip,
  AddCouponIssuePlanToPresent,
  IAddCouponIssuePlan,
} from 'domain/coupon/couponIssuePlan/AddCouponIssuePlan';
import ICouponIssuePlanChangeData from 'domain/coupon/couponIssuePlan/ICouponIssuePlanChangeData';
import TimezoneConvertor from 'infra/TimezoneConvertor';
import { IssueMethod } from 'domain/coupon/couponSchema/IssueMethod';

interface ICouponIssuePlanFormViewModel {
  issuePlanId?: string;
  schemaId: string;
  issuePeriod: Moment[];
  maximumIssueCount: number;
  unitOfIssue: number;
  presentScheduleUtc?: Moment;
}

export class CouponIssuePlanModificationViewModel {
  constructor(data: ICouponIssuePlanFormViewModel) {
    this.issuePlanId = data.issuePlanId;
    this.schemaId = data.schemaId;
    this.issuePeriod = data.issuePeriod;
    this.maximumIssueCount = data.maximumIssueCount;
    this.unitOfIssue = data.unitOfIssue;
    this.presentScheduleUtc = data.presentScheduleUtc;
  }

  issuePlanId?: string;
  schemaId: string;
  issuePeriod: Moment[];
  maximumIssueCount: number;
  unitOfIssue: number;
  presentScheduleUtc?: Moment;

  toChangeDomain(): ICouponIssuePlanChangeData {
    const utcTimes = TimezoneConvertor.localToUtc(this.issuePeriod[0], this.issuePeriod[1]);
    return {
      issuePlanId: this.issuePlanId!!,
      unitOfIssue: this.unitOfIssue,
      maximumIssueCount: this.maximumIssueCount,
      issuePeriod: {
        inclusiveStartTimeUtc: utcTimes[0].format(),
        exclusiveEndTimeUtc: utcTimes[1].format(),
      },
    };
  }
}

export default class CouponIssuePlanViewModel {
  issuePlanId?: string;
  schemaId: string;
  issuePeriod: Moment[];
  maximumIssueCount: number;
  unitOfIssue: number;
  presentScheduleUtc?: Moment;
  userExcelFile?: File;

  constructor(data: ICouponIssuePlanFormViewModel, userExcelFile?: File) {
    this.issuePlanId = data.issuePlanId;
    this.schemaId = data.schemaId;
    this.issuePeriod = data.issuePeriod;
    this.maximumIssueCount = data.maximumIssueCount;
    this.unitOfIssue = data.unitOfIssue;
    this.presentScheduleUtc = data.presentScheduleUtc;
    this.userExcelFile = userExcelFile;
  }

  toAddDownloadCouponIssuePlanData(): AddCouponIssuePlanToClip {
    return AddCouponIssuePlanToClip.fromUiData(this);
  }

  toAddPresentCouponIssuePlanData(): AddCouponIssuePlanToPresent {
    return AddCouponIssuePlanToPresent.fromUiData(this);
  }

  toAddDomain(issueMethod: IssueMethod): IAddCouponIssuePlan {
    switch (issueMethod) {
      case IssueMethod.Clip:
        return this.toAddDownloadCouponIssuePlanData();
      case IssueMethod.Present:
        return this.toAddPresentCouponIssuePlanData();
      default:
        throw '쿠폰 스키마 타입이 정확않아 계획이 생성되지 않았습니다. 관리자에게 문의해주세요. ';
    }
  }
}