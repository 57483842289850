import React, { FC } from 'react';
import { PageHeader, Space, Card, Row, Col, Button, Select, Input } from 'antd';
import 'views/styles/Partners/Suppliers.scss';
import { MatchParams } from 'containers/partners/suppliers/supplierDetailContainer';

const SupplierDetail: FC<MatchParams> = ({ supplierId }) => {

  // TODO: 추후 supplierId 로 여행사 상세정보를 가져오는 로직을 추가한다.
  console.log(supplierId);

  return (
    <div className="SupplierDetail">
      <PageHeader className="page-header" title={<h1>여행사 정보</h1>} />
      <div className="detail-section">
        <h3>사업자 정보</h3>
        <Row>
          <Col span={12}>
            <label>사업자등록번호</label>
            <div>760-81-00630</div>
          </Col>
          <Col span={12}>
            <label>사업자구분</label>
            <div>법인</div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label>회사명</label>
            <div>모두투어(주)</div>
          </Col>
          <Col span={12}>
            <label>여행사명</label>
            <div>
              <Input value={"모두투어"} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label>대표자명</label>
            <div>홍길동</div>
          </Col>
          <Col span={12}>
            <label>대표번호</label>
            <div>0212341234</div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label>사업장주소</label>
            <div>서울시 중구 을지로12 모두빌딩 9층</div>
          </Col>
          <Col span={12}>
            <label>통신판매업 신고번호</label>
            <div>76서울을지00630</div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label>사업자등록증</label>
            <div>
              <a>gotour.jpg</a>
              <a className="close-button">&times;</a>
              <Button>파일첨부</Button>
            </div>
          </Col>
          <Col span={12}>
            <label>관광사업등록증</label>
            <div>
              <a>gotour.jpg</a>
              <a className="close-button">&times;</a>
              <Button>파일첨부</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label>보증보험</label>
            <div>
              <a>gotour.jpg</a>
              <a className="close-button">&times;</a>
              <Button>파일첨부</Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="detail-section">
        <h3>대표관리자 정보</h3>
        <Row>
          <Col span={12}>
            <label>이름</label>
            <div>
              <Input value={"나관리"} />
            </div>
          </Col>
          <Col span={12}>
            <label>휴대폰번호</label>
            <div>
              <Input value={"01012345555"} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label>직통전화</label>
            <div>
              <Input value={"021231234"} />
            </div>
          </Col>
          <Col span={12}>
            <label>이메일</label>
            <div>
              <Input value={"super@modu.com"} />
            </div>
          </Col>
        </Row>
      </div>
      <div className="detail-section">
        <h3>정산 정보</h3>
        <Row>
          <Col span={12}>
            <label>계좌정보</label>
            <div>
              <Select style={{ width: '30%', marginRight: 10 }}>
                <Select.Option value="1">우리은행</Select.Option>
                <Select.Option value="2">국민은행</Select.Option>
                <Select.Option value="3">하나은행</Select.Option>
              </Select>
              <Input value={"11111999"} style={{ width: '30%', marginRight: 10 }}/>
              <Input value={"모두투어(주)"} style={{ width: '30%' }}/>
            </div>
          </Col>
          <Col span={12}>
            <label>통장사본</label>
            <div>
              <a>gotour.jpg</a>
              <a className="close-button">&times;</a>
              <Button>파일첨부</Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="detail-section">
        <h3>여행사 상태</h3>
        <Row>
          <Col span={12}>
            <label>입점심사</label>
            <div>
              <Button className="judge judge-confirm">승인</Button>
              <Button className="judge judge-reject">반려</Button>
            </div>
          </Col>
          <Col span={12}>
            <label>상태</label>
            <div>입점신청</div>
          </Col>
        </Row>
      </div>
      <div className="footer">
        <Button className="float-left cancel">취소</Button>
        <Button className="float-right edit" type="primary">수정하기</Button>
      </div>
    </div>
  );
};

export default SupplierDetail;
