import React, { FC, MouseEvent } from 'react';
import { PageHeader } from 'antd';
import CouponSchemeTable, { ICouponSchemeTableProps } from 'views/components/Coupon/CouponSchema/CouponSchemaTable/CouponSchemaTable';
import CouponSchemaSearchForm from 'views/components/Coupon/CouponSchema/CouponSchemaSearchForm';
import 'views/styles/Coupon/CouponScheme.scss';
import CouponSchemaRegistrationContainer from 'containers/coupon/couponSchema/CouponSchemaRegistrationModalContainer';
import ICouponListRequestParams from 'domain/coupon/ICouponListRequestParams';

interface ICouponSchema {
  tableProps: ICouponSchemeTableProps;
  showModal: (e: MouseEvent) => void;
  modalVisible: boolean;
  handleCancel: (e?: React.MouseEvent<HTMLElement>) => void;
  fetchList: (request: ICouponListRequestParams) => void;
}

const CouponSchema: FC<ICouponSchema> = ({ tableProps, showModal, modalVisible, handleCancel, fetchList }) => {
  return (
    <div className="CouponScheme">
      <PageHeader className="page-header" title={<h1>쿠폰 스키마</h1>} />
      <CouponSchemaSearchForm showModal={showModal} />
      <CouponSchemeTable {...tableProps} />
      <CouponSchemaRegistrationContainer
        modelVisible={modalVisible}
        handleCancel={handleCancel}
        fetchList={fetchList}
      />
    </div>
  );
};

export default CouponSchema;
