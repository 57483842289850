export default interface ITop10AccommodationsFilter {
  guid?: string;
}

export class Top10AccommodationsFilter {
  guid?: string;

  constructor(guid?: string) {
    this.guid = guid;
  }
}
