import React, { FC, useState } from 'react';
import {
  Button,
  Form,
  Col,
  InputNumber,
  message,
  Modal,
  notification,
  Row,
  Spin,
  Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons/lib';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import * as XLSX from 'xlsx';
import { Dimmer } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import Top10WeeklyRankedSheet from 'domain/top10/weeklyRankedSheet/Top10WeeklyRankedSheet';
import { ArgsProps } from 'antd/lib/notification';
import { apiUpsertWeeklyRankedSheet } from 'api/top10/top10Accommodations';

export interface ITop10WeeklyRankedSheetRegistrationModalProps {
  visible: boolean;
  hideModal: () => void;
}

const Top10WeeklyRankedSheetRegistrationModal: FC<ITop10WeeklyRankedSheetRegistrationModalProps> = ({
  visible,
  hideModal,
}) => {
  const [excelData, setExcelData] = useState<any[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [dimmed, setDimmed] = useState(false);
  const [form] = Form.useForm();

  const resetUploadedData = () => {
    setExcelData([]);
    setFileList([]);
    form.resetFields();
  };

  const openNotification = (success: boolean) => {
    let args: ArgsProps;
    if (success) {
      args = {
        message: '업로드 성공!',
        description: (
          <code>
            {fileList[0].name} 업로드를 성공하였습니다.
            <br />
            {new Date().toLocaleString()}
          </code>
        ),
        duration: 0,
      };
    } else {
      args = {
        message: '업로드 실패!',
        description: (
          <code>
            {fileList[0].name} 업로드를 실패하였습니다.
            <br />
            {new Date().toLocaleString()}
          </code>
        ),
        duration: 0,
      };
    }
    notification.open(args);
  };

  const upsertAccommodations = async () => {
    hideModal();
    if (excelData.length === 0) {
      message.error('파일을 업로드하세요!', 2);
      return false;
    }

    setDimmed(true);

    const values = await form.validateFields();
    const excelDataWithoutHeader = excelData.filter(
      (data) => excelData.indexOf(data) !== 0,
    );
    const params = new Top10WeeklyRankedSheet(
      values.year,
      values.weekNumber,
      excelDataWithoutHeader,
    );

    try {
      await apiUpsertWeeklyRankedSheet(params);
      setDimmed(false);
      openNotification(true);
    } catch (e) {
      setDimmed(false);
      openNotification(false);
      console.log(e);
    }
    resetUploadedData();
  };

  const handleCancel = () => {
    resetUploadedData();
    hideModal();
  };

  const handleChange = (info: UploadChangeParam) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setFileList(fileList);
  };

  const props = {
    accept: '.csv',
    onChange: handleChange,
    beforeUpload: (file: Blob) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null) {
          const workBook = XLSX.read(e.target.result, { type: 'binary' });
          const firstSheetName = workBook.SheetNames[0];
          const workSheet = workBook.Sheets[firstSheetName];
          const accommodations = XLSX.utils.sheet_to_json(workSheet, {
            header: 1,
          });
          setExcelData(accommodations);
        }
      };
      reader.readAsText(file);
      return false;
    },
  };

  return (
    <>
      <Modal
        title="순위정보 업로드"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleCancel}>
            취소
          </Button>,
          <Button key="upload" onClick={upsertAccommodations}>
            업로드
          </Button>,
        ]}
        width={600}
      >
        <Row>
          <Upload {...props} fileList={fileList}>
            <Button icon={<UploadOutlined />}>Upload csv only</Button>
          </Upload>
        </Row>
        <Form
          form={form}
          className="Top10WeeklyRankedSheetRegistrationModalForm"
          style={{ marginTop: 10 }}
        >
          <Row gutter={4}>
            <Col span={6}>
              <Form.Item
                name="year"
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: '필수입력값입니다.' }]}
              >
                <InputNumber placeholder="연도" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="weekNumber"
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: '필수입력값입니다.' }]}
              >
                <InputNumber placeholder="주차" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Dimmer active={dimmed}>
        <Spin size="large" />
      </Dimmer>
    </>
  );
};

export default Top10WeeklyRankedSheetRegistrationModal;
