import { IPackage, ISupplier, IPackageFilters } from 'domain/Package';
import packageFilter from 'services/specialPricing/packageFilter';

// actions
export enum fetchPackages {
  request = 'FETCH_PACKAGES',
  success = 'FETCH_PACKAGES_SUCCESS',
  error = 'FETCH_PACKAGES_ERROR',
}

export enum fetchSuppliers {
  request = 'FETCH_SUPPLIERS',
  success = 'FETCH_SUPPLIERS_SUCCESS',
  error = 'FETCH_SUPPLIERS_ERROR',
}

type SpecialPricingActions =
  | { type: fetchPackages.request }
  | { type: fetchPackages.success; payload: IPackage[] }
  | { type: fetchPackages.error }
  | { type: fetchSuppliers.request }
  | { type: fetchSuppliers.success; payload: ISupplier[] }
  | { type: fetchSuppliers.error }
  | { type: 'SET_FILTER'; payload: IPackageFilters }
  | { type: 'SAVE_CHECKED_ITEMS'; payload: string[] };

// state
interface IAsyncState<T> {
  loading: boolean;
  data: T;
  error: boolean;
}

type SpecialPricingState = {
  filter: IPackageFilters;
  filteredResults: IPackage[];
  checkedItems: string[];
  packageResponse: IAsyncState<IPackage[]>;
  supplierResponse: IAsyncState<ISupplier[]>;
};

export const initialState: SpecialPricingState = {
  filter: { supplier: undefined },
  filteredResults: [],
  checkedItems: [],
  packageResponse: {
    loading: false,
    data: [],
    error: false,
  },
  supplierResponse: {
    loading: false,
    data: [],
    error: false,
  },
};

// reducer
export default function reducer(state: SpecialPricingState, action: SpecialPricingActions): SpecialPricingState {
  switch (action.type) {
    case fetchPackages.request:
      return {
        ...state,
        filteredResults: [],
        packageResponse: {
          loading: true,
          data: [],
          error: false,
        },
      };

    case fetchPackages.success:
      return {
        ...state,
        filteredResults: packageFilter(action.payload, state.filter),
        packageResponse: {
          loading: false,
          data: action.payload,
          error: false,
        },
      };

    case fetchPackages.error:
      return {
        ...state,
        filteredResults: [],
        packageResponse: {
          loading: false,
          data: [],
          error: true,
        },
      };

    case fetchSuppliers.request:
      return {
        ...state,
        supplierResponse: {
          loading: true,
          data: [],
          error: false,
        },
      };

    case fetchSuppliers.success:
      return {
        ...state,
        supplierResponse: {
          loading: false,
          data: action.payload,
          error: false,
        },
      };

    case fetchSuppliers.error:
      return {
        ...state,
        supplierResponse: {
          loading: false,
          data: [],
          error: true,
        },
      };

    case 'SET_FILTER': {
      const excepts = ['keyword'];
      const selected = Object.fromEntries(Object.entries(action.payload).filter(([key]) => !excepts.includes(key)));
      const source = state.packageResponse.data;
      return {
        ...state,
        filter: { ...state.filter, ...action.payload },
        filteredResults: packageFilter(source, selected),
      };
    }

    case 'SAVE_CHECKED_ITEMS': {
      console.log(action.payload);
      return {
        ...state,
        checkedItems: action.payload,
      };
    }

    default: {
      throw new Error('Action is not defined');
    }
  }
}
