import React, { useState, useEffect, MouseEvent } from 'react';
import TripPlannerReservations from '../../views/components/TripPlannerReservation';
import { fetchTripPlannerReservations, updateTripPlannerStatus } from '../../api/tripPlannerReservation';
import ITripPlannerReservations from '../../domain/tripPlannerReservation/ITripPlannerReservation';
import {
  ITripPlannerReservationTableData,
  ITripPlannerReservationTableProps,
} from '../../views/components/TripPlannerReservation/TripPlannerReservationTable/TripPlannerReservationTable';
import IListResponse from '../../domain/IListResponse';
import IPagination, { initialPagination } from '../../views/types/IPagination';
import IListRequest from '../../domain/IListRequest';
import ITripPlannerReservationListFilter from '../../domain/tripPlannerReservation/ITripPlannerReservationListFilter';
import copy from 'clipboard-copy';
import { message } from 'antd';
import ITripPlannerReservationHistoryUpdateRequest from '../../domain/tripPlannerReservation/ITripPlannerReservationHistoryUpdateRequest';
import { ITripPlannerReservationQuestionModalProp } from '../../views/components/TripPlannerReservation/TripPlannerReservationModal/TripPlannerReservationQuestionModal';
import { ITripPlannerReservationClipboardModalProp } from '../../views/components/TripPlannerReservation/TripPlannerReservationModal/TripPlannerReservationClipBoardModal';
import { ITripPlannerHistoryModalContainerProp } from './TripPlannerReservationHistoryModalContainer';
import { TripPlannerReservationStatus } from '../../domain/tripPlannerReservation/TripPlannerReservationStatus';
import ITripPlannerReservationStatusUpdateRequest from '../../domain/tripPlannerReservation/ITripPlannerReservationStatusUpdateRequest';

const initParam: IListRequest<ITripPlannerReservationListFilter> = {
  page: 0,
  size: 10,
  sort: 'id,desc',
  filter: {
    status: 'APPLY',
    agencyId: '',
    createdAtEnd: '',
    createdAtStart: '',
    searchWord: '',
  },
};

const TripPlannerContainer = () => {
  const [param, setParam] = useState<IListRequest<ITripPlannerReservationListFilter>>(initParam);
  const [tableSource, setTableSource] = useState<ITripPlannerReservationTableData[]>([]);
  const [pagination, setPagination] = useState<IPagination>(initialPagination);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [questionModalVisible, setQuestionModalVisible] = useState<boolean>(false);
  const [questionModalContent, setQuestionModalContent] = useState<string>('');
  const [clipboardModalVisible, setClipboardModalVisible] = useState<boolean>(false);
  const [clipboardModalContent, setClipboardModalContent] = useState<string>('');
  const [historyModalVisible, setHistoryModalVisible] = useState<boolean>(false);
  const [historyModalData, setHistoryModalData] = useState<ITripPlannerReservationHistoryUpdateRequest>({
    id: 0,
    counselorMemo: '',
  });

  const fetchList = async (request?: IListRequest<ITripPlannerReservationListFilter>) => {
    setTableLoading(true);
    try {
      const requestParam = request ? request : param;
      const result = await fetchTripPlannerReservations(requestParam);
      const response: IListResponse<ITripPlannerReservations> = result.data;

      const formatted: ITripPlannerReservationTableData[] = response
        ? response.content.map(x => convertToTableData(x))
        : [];
      const pagination = response ? convertToPagination(response) : initialPagination;

      setTableSource(formatted);
      setPagination(pagination);
    } catch (e) {
      const formatted: ITripPlannerReservationTableData[] = [];

      setTableSource(formatted);
      setPagination(initialPagination);
    }
    setTableLoading(false);
  };

  const convertToPagination = (response: IListResponse<any>): IPagination => {
    return {
      total: response.totalElements,
      current: response.number + 1,
      pageSize: response.size,
      showSizeChanger: false,
      onChange: handlePageChange,
      onShowSizeChange: () => {},
    };
  };

  const handleTabChange = (key: string) => {
    const changedParam: IListRequest<ITripPlannerReservationListFilter> = {
      ...param,
      filter: {
        ...param.filter,
        status: key,
      },
    };
    setParam(changedParam);

    fetchList(changedParam);
  };

  const handlePageChange = (page: number) => {
    const changedParam: IListRequest<ITripPlannerReservationListFilter> = {
      ...param,
      page: page - 1,
    };
    setParam(changedParam);

    fetchList(changedParam);
  };

  const handleQuestionModalShow = (e: MouseEvent, targetId: string) => {
    const findSource = tableSource.find(it => it.id === targetId);
    const content = findSource ? findSource.questions : '';
    setQuestionModalContent(content);
    setQuestionModalVisible(true);
  };

  const handleQuestionModalCancel = () => {
    setQuestionModalVisible(false);
  };

  const handleClipboardModalCancel = () => {
    setClipboardModalVisible(false);
  };

  const handleClipboardModalShow = (e: MouseEvent, targetId: string) => {
    const findSource = tableSource.find(it => it.id === targetId);
    const content = findSource ? findSource.reservationInfo : '';
    copy(content);
    message.success('예약정보가 클립보드에 저장되었습니다.');
    setClipboardModalContent(content);
    setClipboardModalVisible(true);
  };

  const handleHistoryModalCancel = () => {
    setHistoryModalVisible(false);
  };

  const handleHistoryModalShow = (e: MouseEvent, targetId: string) => {
    const findSource = tableSource.find(it => it.id === targetId);
    const content = findSource ? findSource.history : '';
    const id = findSource ? findSource.id : '';
    const data: ITripPlannerReservationHistoryUpdateRequest = {
      id: Number(id),
      counselorMemo: content,
    };
    setHistoryModalData(data);
    setHistoryModalVisible(true);
  };

  const handleChangeStatus = async (e: MouseEvent, targetId: string, status: TripPlannerReservationStatus) => {
    try {
      const param: ITripPlannerReservationStatusUpdateRequest = {
        id: Number(targetId),
        status: status,
      };
      await updateTripPlannerStatus(param);
      await fetchList();
      message.success('상태 변경 성공하였습니다.');
    } catch (e) {
      console.log(e);
      message.error('상태 변경 실패하였습니다. 관리자에게 문의바랍니다.');
    }
  };

  useEffect(() => {
    handleTabChange('APPLY');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableProps: ITripPlannerReservationTableProps = {
    source: tableSource,
    pagination: pagination,
    tableLoading: tableLoading,
    handleQuestionModalShow: handleQuestionModalShow,
    handleClipboardModalShow: handleClipboardModalShow,
    handleHistoryModalShow: handleHistoryModalShow,
    handleChangeStatus: handleChangeStatus,
    status: param.filter.status,
  };

  const questionModalProps: ITripPlannerReservationQuestionModalProp = {
    visible: questionModalVisible,
    content: questionModalContent,
    handleCancel: handleQuestionModalCancel,
  };

  const clipBoardModalProps: ITripPlannerReservationClipboardModalProp = {
    visible: clipboardModalVisible,
    content: clipboardModalContent,
    handleCancel: handleClipboardModalCancel,
  };

  const historyModalProps: ITripPlannerHistoryModalContainerProp = {
    visible: historyModalVisible,
    data: historyModalData,
    handleCancel: handleHistoryModalCancel,
    fetchList: fetchList,
  };

  return (
    <TripPlannerReservations
      tableProps={tableProps}
      handleTabChange={handleTabChange}
      fetchList={fetchList}
      param={param}
      questionModalProps={questionModalProps}
      clipBoardModalProps={clipBoardModalProps}
      historyModalProps={historyModalProps}
    />
  );
};

function convertToTableData(data: ITripPlannerReservations) {
  const result: ITripPlannerReservationTableData = {
    key: data.id,
    ...data,
  };
  return result;
}

export default TripPlannerContainer;
