import { PaginationProps } from 'antd/lib/pagination';
import IListRequest from 'domain/IListRequest';

export default interface IPagination extends PaginationProps {
  total: number;
  current: number;
  pageSize: number;
  showSizeChanger: boolean;
  onShowSizeChange: (current: number, size: number) => void;
  onChange: (page: number, pageSize?: number) => void;
}

export const initialPagination: IPagination = {
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
  onChange: () => {},
  onShowSizeChange: () => {},
};

export class TSPagination implements IPagination {
  current: number;
  pageSize = 10;
  showSizeChanger = false;
  total: number;

  constructor(request: IListRequest<any>, total: number, handlePageChange: (page: number) => void) {
    this.total = total;
    this.current = request.page ? request.page + 1 : 1;
    this.onChange = handlePageChange;
  }

  onChange(page: number, pageSize: number | undefined): void {}

  onShowSizeChange(current: number, size: number): void {}
}
