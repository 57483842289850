class BenefitsFacility {
  displayName: string;

  constructor(displayName: string) {
    this.displayName = displayName;
  }
}

class BenefitsService {
  displayName: string;

  constructor(displayName: string) {
    this.displayName = displayName;
  }
}

class BenefitsNearbyAttraction {
  displayName: string;

  constructor(displayName: string) {
    this.displayName = displayName;
  }
}

export default class Benefits {
  facilities: BenefitsFacility[];
  services: BenefitsService[];
  nearbyAttractions: BenefitsNearbyAttraction[];

  constructor(
    facilities: BenefitsFacility[],
    services: BenefitsService[],
    nearbyAttraction: BenefitsNearbyAttraction[],
  ) {
    this.facilities = facilities;
    this.services = services;
    this.nearbyAttractions = nearbyAttraction;
  }

  static constructBenefitsFacilities(
    ...displayNames: string[]
  ): BenefitsFacility[] {
    return displayNames
      .filter(value => value !== undefined)
      .map(value => new BenefitsFacility(value));
  }

  static constructBenefitsServices(
    ...displayNames: string[]
  ): BenefitsFacility[] {
    return displayNames
      .filter(value => value !== undefined)
      .map(value => new BenefitsService(value));
  }

  static constructBenefitsNearbyAttractions(
    ...displayNames: string[]
  ): BenefitsNearbyAttraction[] {
    return displayNames
      .filter(value => value !== undefined)
      .map(value => new BenefitsNearbyAttraction(value));
  }
}
