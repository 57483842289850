import React, { FC, MouseEvent } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button } from 'antd';

interface ITripPlannerReservationSearchFormProps {
  formItems: { itemNode: React.ReactNode }[];
  handleSearch: (e: MouseEvent) => void;
}

const TripPlannerReservationSearchForm: FC<ITripPlannerReservationSearchFormProps> = ({ formItems, handleSearch }) => {
  return (
    <Form className="TripPlannerReservationSearchForm">
      <Row gutter={4} justify="end" align="top">
        {formItems.map((it, idx) => (
          <Col key={idx}>
            <Form.Item>{it.itemNode}</Form.Item>
          </Col>
        ))}
        <Col>
          <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch} style={{ margin: 3.5 }}>
            검색
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default TripPlannerReservationSearchForm;
