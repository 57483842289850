import React, { useState } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import menu from './menuProperties';
import '../styles/Navigation.scss';

const { SubMenu } = Menu;
const MenuItem = Menu.Item;

interface INavigationProps {
  title: string;
  key?: string;
  href?: string;
  link?: string;
  children?: INavigationProps[];
}

const Navigation = () => {
  const [current, setCurrent] = useState<string>('');

  return (
    <Menu
      mode="horizontal"
      onClick={(e) => {
        setCurrent(`${e.key}`);
      }}
      selectedKeys={[current]}
      style={{ border: 0 }}
    >
      {menu.map(defineMenu)}
    </Menu>
  );
};

function defineMenu(x: INavigationProps) {
  return x.children ? (
    <SubMenu title={x.title} className="Navigation-item" key={x.title}>
      {x.children.map((y) =>
        y.children ? (
          defineMenu(y)
        ) : (
          <MenuItem key={y.key} className="Navigation-item">
            {defineMenuItem(y)}
          </MenuItem>
        ),
      )}
    </SubMenu>
  ) : (
    <MenuItem key={x.key} className="Navigation-item">
      {defineMenuItem(x)}
    </MenuItem>
  );
}

function defineMenuItem(item: INavigationProps) {
  return item.href ? (
    <a href={item.href}>{item.title}</a>
  ) : (
    <Link to={item.link || ''}>{item.title}</Link>
  );
}

export default Navigation;
