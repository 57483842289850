import { v2 } from 'infra/axios';
import IListRequest from 'domain/IListRequest';
import IThemesFilter from 'domain/preReservationV2/IThemesFilter';
import IThemeCreateRequest from 'domain/preReservationV2/IThemeCreateRequest';

const fetchThemes = (params: IListRequest<IThemesFilter>) =>
  v2.post(
    '/admin/api/overseas/pre-reservations-v2/themes/process-query/find',
    params,
  );

const postTheme = (request: IThemeCreateRequest) =>
  v2.post('/api/overseas/pre-reservations-v2/themes', request);

export { fetchThemes, postTheme };
