import React, { FunctionComponent } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Row, Col, Input, Select, DatePicker, Button } from 'antd';
import '@ant-design/compatible/assets/index.css';
import 'views/styles/Coupon/CouponScheme.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface ISearchColumn {
  key: string;
  name: string;
}

const searchColumns: ISearchColumn[] = [
  {
    key: 'name',
    name: '스키마명',
  },
  {
    key: 'displayName',
    name: '쿠폰표시명',
  },
];

interface CouponSchemaSearchFormProp {
  showModal: (e: React.MouseEvent<HTMLElement>) => void;
}

const CouponSchemaSearchForm: FunctionComponent<CouponSchemaSearchFormProp> = prop => {
  return (
    <Form className="search-box">
      <Row>
        <Col span={4}>
          <Select defaultValue="검색조건" disabled>
            {searchColumns.map(it => (
              <Option key={it.key} value={it.name}>
                {it.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <Input placeholder="검색어를 입력하세요" disabled />
        </Col>
        <Col span={5}>
          <RangePicker placeholder={['유효기간 시작일', '유효기간 종료일']} disabled />
        </Col>
        <Col span={2}>
          <Button type="primary" icon={<SearchOutlined />} disabled>
            검색
          </Button>
        </Col>
        <Col span={2} className="float-right">
          <Button type="default" icon={<PlusOutlined />} onClick={prop.showModal}>
            등록
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CouponSchemaSearchForm;
