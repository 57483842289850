import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import { UploadOutlined } from '@ant-design/icons/lib';

export interface ITop10WeeklyRankedSheetTableProps {
  showReservationModal: () => void;
}

const Top10WeeklyRankedSheetTable: FC<ITop10WeeklyRankedSheetTableProps> = ({
  showReservationModal,
}) => {
  return (
    <div className="Top10AccommodationsSearch">
      <Row>
        <Col span={12}>
          <Button icon={<UploadOutlined />} onClick={showReservationModal}>
            순위정보 업로드
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Top10WeeklyRankedSheetTable;
