import React from 'react';
import IProduct from 'domain/preReservationV2/IProduct';
import env from '../../../../../infra/envMap';

const ProductTableColumns = () => {
  const columns = [
    {
      key: 'id',
      title: '상품코드',
      dataIndex: 'product',
      render: (product: IProduct) => (
        <a href={`${env.tourWebHost}/overseas/product/${product.id}`}>
          {product.id}
        </a>
      ),
    },
    {
      key: 'displayName',
      title: '상품명',
      dataIndex: 'product',
      render: (product: IProduct) => `${product.displayName}`,
    },
    {
      key: 'category',
      title: '카테고리',
      dataIndex: 'product',
      render: (product: IProduct) => `${product.category}`,
    },
    {
      key: 'place',
      title: '여행지',
      dataIndex: 'product',
      render: (product: IProduct) => `${product.place}`,
    },
    {
      key: 'tags',
      title: '태그',
      dataIndex: 'product',
      render: (product: IProduct) => `${product.tags.join(', ')}`,
    },
    {
      key: 'optionDescription',
      title: '옵션설명',
      dataIndex: 'product',
      render: (product: IProduct) => `${product.optionDescription}`,
    },
    {
      key: 'isActive',
      title: 'isActive',
      dataIndex: 'product',
      render: (product: IProduct) => `${product.isActive}`,
    },
  ];

  return columns;
};

export default ProductTableColumns;
