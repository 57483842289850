import React, { FC } from 'react';
import { PageHeader } from 'antd';
import Top10WeeklyRankedSheetRegistrationModal, {
  ITop10WeeklyRankedSheetRegistrationModalProps,
} from 'views/components/Top10/WeeklyRankedSheet/Top10WeeklyRankedSheetRegistrationModal';
import Top10WeeklyRankedSheetTable, {
  ITop10WeeklyRankedSheetTableProps,
} from 'views/components/Top10/WeeklyRankedSheet/Top10WeeklyRankedSheetTable';

export interface ITop10WeeklyRankedSheetProps {
  tableProps: ITop10WeeklyRankedSheetTableProps;
  registrationModalProps: ITop10WeeklyRankedSheetRegistrationModalProps;
}

const Top10WeeklyRankedSheet: FC<ITop10WeeklyRankedSheetProps> = ({
  tableProps,
  registrationModalProps,
}) => {
  return (
    <div className="Top10WeeklyRankedSheet">
      <PageHeader className="page-header" title={<h1>TOP10 순위관리</h1>} />
      <Top10WeeklyRankedSheetTable {...tableProps} />
      <Top10WeeklyRankedSheetRegistrationModal {...registrationModalProps} />
    </div>
  );
};

export default Top10WeeklyRankedSheet;
