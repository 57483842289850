import IListRequest from 'domain/IListRequest';
import ITop10AccommodationsFilter from 'domain/top10/accommodation/ITop10AccommodationsFilter';
import Top10AccommodationInsertData, {
  ITop10AccommodationInsertData,
} from 'domain/top10/accommodation/Top10AccommodationInsertData';
import { admin } from 'infra/axios';
import IWeeklyRankedSheet from "domain/top10/weeklyRankedSheet/Top10WeeklyRankedSheet";

export const getDummyOfTop10Accommodations = (
  request: IListRequest<ITop10AccommodationsFilter>,
) => {
  let accommodations = dummyOfAccommodations;
  if (
    request.filter.guid !== undefined &&
    request.filter.guid.trim().length > 1
  ) {
    accommodations = accommodations.filter(
      value => value.guid === request.filter.guid,
    );
  }
  const page = request.page || 0;
  const size = request.size || 10;
  const start = page * size;
  const end = start + size;
  const pagedAccommodations = accommodations.slice(start, end);
  return {
    data: {
      items: pagedAccommodations,
      total: accommodations.length,
    },
  };
};

export const getEmptyTop10Accommodations = (
  request: IListRequest<ITop10AccommodationsFilter>,
) => {
  return {
    data: {
      items: [],
      total: 0,
    },
  };
};

const dummyOfAccommodations = [
  {
    guid: 'ad3fcc5a-3f6f-11eb-b378-0242ac130002',
    name: '제주 신라 호텔',
    agodaId: '1111',
    ondaId: '2222',
    type: '호텔/리조트',
  },
  {
    guid: 'ad3fcc5a-3f6f-11eb-b378-0242ac130002',
    name: '제주 신라 호텔',
    agodaId: '1111',
    ondaId: '2222',
    type: '호텔/리조트',
  },
  {
    guid: 'ad3fcc5a-3f6f-11eb-b378-0242ac130002',
    name: '제주 신라 호텔',
    agodaId: '1111',
    ondaId: '2222',
    type: '호텔/리조트',
  },
  {
    guid: 'ad3fcc5a-3f6f-11eb-b378-0242ac130002',
    name: '제주 신라 호텔',
    agodaId: '1111',
    ondaId: '2222',
    type: '호텔/리조트',
  },
  {
    guid: 'ad3fcc5a-3f6f-11eb-b378-0242ac130002',
    name: '제주 신라 호텔',
    agodaId: '1111',
    ondaId: '2222',
    type: '호텔/리조트',
  },
  {
    guid: 'ad3fcc5a-3f6f-11eb-b378-0242ac130002',
    name: '제주 신라 호텔',
    agodaId: '1111',
    ondaId: '2222',
    type: '호텔/리조트',
  },
  {
    guid: 'ad3fcc5a-3f6f-11eb-b378-0242ac130002',
    name: '제주 신라 호텔',
    agodaId: '1111',
    ondaId: '2222',
    type: '호텔/리조트',
  },
  {
    guid: 'ad3fcc5a-3f6f-11eb-b378-0242ac130002',
    name: '제주 신라 호텔',
    agodaId: '1111',
    ondaId: '2222',
    type: '호텔/리조트',
  },
  {
    guid: 'ad3fcc5a-3f6f-11eb-b378-0242ac130002',
    name: '제주 신라 호텔',
    agodaId: '1111',
    ondaId: '2222',
    type: '호텔/리조트',
  },
  {
    guid: 'ad3fcc5a-3f6f-11eb-b378-0242ac130002',
    name: '제주 신라 호텔',
    agodaId: '1111',
    ondaId: '2222',
    type: '호텔/리조트',
  },
  {
    guid: 'ad3fcc5a-3f6f-11eb-b378-0242ac130002',
    name: '제주 신라 호텔',
    agodaId: '1111',
    ondaId: '2222',
    type: '호텔/리조트',
  },
  {
    guid: '43af20b4-3f70-11eb-b378-0242ac130002',
    name: '시그니엘 부산',
    agodaId: '131414',
    ondaId: '1241234',
    type: '호텔/리조트',
  },
];

export const apiUpsertAccommodations = (params: {
  accommodations: Top10AccommodationInsertData[];
}) =>
  admin.post(
    '/api/accommodations/execute-command/insert-or-replace-accommodations',
    params,
  );

export const apiUpsertWeeklyRankedSheet = (params: IWeeklyRankedSheet) =>
  admin.post(
    '/api/accommodations/execute-command/insert-or-replace-weekly-ranked-sheet',
    params,
  );
