import React, { RefObject } from 'react';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface IDeletePopconfirmButtonProps {
  handleConfirm: () => void;
}

const DeletePopconfirmButton = ({
  handleConfirm,
}: IDeletePopconfirmButtonProps) => {
  return (
    <Popconfirm
      title={'지우겠습니까?'}
      onConfirm={() => handleConfirm()}
      okText="예"
      cancelText="아니오"
    >
      <Button icon={<DeleteOutlined />} />
    </Popconfirm>
  );
};

export default DeletePopconfirmButton;
