import IFormField from 'views/types/IFormField';
import moment, { Moment } from 'moment';
import ICouponIssuePlanData from 'domain/coupon/couponIssuePlan/ICouponIssuePlanData';

export interface ICouponIssuePlanFormField {
  issuePlanId?: string;
  schemaId: IFormField<string>;
  issuePeriod: IFormField<Moment[]>;
  maximumIssueCount: IFormField<number>;
  unitOfIssue: IFormField<number>;
  totalCouponCount: IFormField<number>;
  presentScheduleUtc?: IFormField<Moment>;
}

export default class CouponIssuePlanFormField implements ICouponIssuePlanFormField {
  issuePlanId?: string;
  schemaId: IFormField<string>;
  issuePeriod: IFormField<Moment[]>;
  maximumIssueCount: IFormField<number>;
  unitOfIssue: IFormField<number>;
  totalCouponCount: IFormField<number>;
  presentScheduleUtc?: IFormField<Moment>;

  constructor(data?: ICouponIssuePlanData) {
    this.issuePlanId = data ? data.id : undefined;
    this.schemaId = { name: 'schemaId', value: data ? data.schema.id : '' };

    this.issuePeriod = {
      name: 'issuePeriod',
      value: (data) ? (data.issuePeriod ? [moment(data.issuePeriod.inclusiveStartTimeUtc), moment(data.issuePeriod.exclusiveEndTimeUtc)] : []) : [],
    };

    this.presentScheduleUtc = data ? {
      name: 'presentScheduleUtc',
      value: moment(data.presentationTimeUtc),
    } : undefined;
    this.maximumIssueCount = { name: 'maximumIssueCount', value: data ? data.maximumIssueCount : 0 };
    this.unitOfIssue = { name: 'unitOfIssue', value: data ? data.unitOfIssue : 0 };
    this.totalCouponCount = { name: 'totalCouponCount', value: data ? data.maximumIssueCount * data.unitOfIssue : 0 };
  }
}
