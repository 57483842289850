export interface IWeeklyRankedSheet {
  id: {
    year: number;
    weekNumber: number;
  };
  items: any;
}

export default class Top10WeeklyRankedSheet implements IWeeklyRankedSheet {
  id: { year: number; weekNumber: number };
  items: any;

  constructor(year: number, weekNumber: number, excel: any[]) {
    this.id = {
      year: year,
      weekNumber: weekNumber,
    };

    this.items = {};
    excel.forEach(low => {
      const excelTemplateData = new Top10WeeklyRankedSheetExcelTemplate(low);
      this.items[excelTemplateData.guid] = {
        rankInCountry: excelTemplateData.rankInCountry,
        rankInCustomaryLocalityGroup:
          excelTemplateData.rankInCustomaryLocalityGroup,
        rankInCustomaryLocality: excelTemplateData.rankInCustomaryLocality,
      };
    });
  }
}

class Top10WeeklyRankedSheetExcelTemplate {
  guid: string;
  accommodationDisplayName: string;
  rankInCountry: string;
  rankInCustomaryLocalityGroup: string;
  rankInCustomaryLocality: string;

  constructor(excelData: string[]) {
    [
      this.guid,
      this.accommodationDisplayName,
      this.rankInCountry,
      this.rankInCustomaryLocalityGroup,
      this.rankInCustomaryLocality,
    ] = excelData;
  }
}
