import { IPackageFilters, IPackage } from 'domain/Package';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';

export default function packageFilter(source: IPackage[], filterConfig: IPackageFilters) {
  const filterBySupplier = (origin: IPackage[]) =>
    filterConfig.supplier ? origin.filter(x => x.supplier.id === filterConfig.supplier) : source;

  const filterByDepartureDateStart = (origin: IPackage[]) => {
    return filterConfig.departureDateStart
      ? origin.filter(
          x =>
            !isBefore(
              startOfDay(new Date(x.departureTimeLocal)),
              startOfDay(new Date(filterConfig.departureDateStart!)),
            ),
        )
      : origin;
  };

  const filterByDepartureDateEnd = (origin: IPackage[]) => {
    return filterConfig.departureDateEnd
      ? origin.filter(
          x =>
            !isAfter(startOfDay(new Date(x.departureTimeLocal)), startOfDay(new Date(filterConfig.departureDateEnd!))),
        )
      : origin;
  };

  return pipe(filterBySupplier, filterByDepartureDateStart, filterByDepartureDateEnd)(source);
}

const pipe = (...fns: Function[]) => (initialValue: any) => fns.reduce((v, f) => f(v), initialValue);
