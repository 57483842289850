import { v2, admin } from '../infra/axios';
import { AxiosResponse } from 'axios';
import { IPackage } from 'domain/Package';

export const getPackageSequenceId = (uuid: string): Promise<AxiosResponse<{ id: number }>> =>
  v2.get(`/convert/getPackageID/uuid/${uuid}`);

export const getPackagesByPackagePlanCode = (packagePlanCode: string): Promise<AxiosResponse<IPackages>> =>
  admin.get(`/api/special-pricing/packages?package-plan-code=${packagePlanCode}`);

export const getPackagesByPackageCodes = (packageCodes: string[]): Promise<AxiosResponse<IPackages>> =>
  admin.post('/api/special-pricing/process-query/get-packages-by-package-codes', { packageCodes });

export const addSpecialPricingPolicy = (uuids: string[], amount: number): Promise<AxiosResponse> =>
  admin.post('/api/special-pricing/execute-command/add-special-pricing-policy-with-template', {
    discountFunctions: {
      supplierFunctions: {
        adultFunction: { amount },
        childFunction: { amount: null },
        infantFunction: { amount: null },
      },
    },
    targetPackageIds: uuids,
  });

export interface IPackages {
  items: IPackage[];
}
