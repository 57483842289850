import React, { FC } from 'react';
import { PageHeader } from 'antd';
import ProductTable, { IProductTableProps } from './table/ProductTable';

interface IProductProps {
  tableProps: IProductTableProps;
}

const Product: FC<IProductProps> = ({ tableProps }) => {
  return (
    <div className="OndaReservation">
      <PageHeader className="page-header" title={<h1>상품목록</h1>} />
      <ProductTable {...tableProps} />
    </div>
  );
};

export default Product;
