import React, { FC } from 'react';
import { PageHeader } from 'antd';
import ThemeTable, { IThemeTableProps } from './table/ThemeTable';

interface IThemeProps {
  tableProps: IThemeTableProps;
}

const Theme: FC<IThemeProps> = ({ tableProps }) => {
  return (
    <div className="OndaReservation">
      <PageHeader className="page-header" title={<h1>상품목록관리</h1>} />
      <ThemeTable {...tableProps} />
    </div>
  );
};

export default Theme;
