import React from 'react';
import { ITop10AccommodationsTableData } from 'views/components/Top10/Accommodations/Top10AccommodationsTable';

const Top10AccommodationsTableColumns = () => {
  const columns = [
    {
      key: 'guid',
      title: 'GUID',
      dataIndex: 'guid',
    },
    {
      key: 'name',
      title: '숙소명',
      render: (accommodation: ITop10AccommodationsTableData) => (
        <a href={'./accommodations/' + accommodation.guid}>
          {accommodation.name}
        </a>
      ),
    },
    {
      key: 'agodaId',
      title: '아고다ID',
      dataIndex: 'agodaId',
    },
    {
      key: 'ondaId',
      title: '온다ID',
      dataIndex: 'ondaId',
    },
    {
      key: 'type',
      title: '숙소타입',
      dataIndex: 'type',
    },
  ];

  return columns;
};

export default Top10AccommodationsTableColumns;
