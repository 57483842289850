import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import CouponSchema from 'views/components/Coupon/CouponSchema';
import {
  ICouponSchemeTableData,
  ICouponSchemeTableProps,
} from 'views/components/Coupon/CouponSchema/CouponSchemaTable/CouponSchemaTable';
import IPagination, { initialPagination } from 'views/types/IPagination';
import ICouponListRequestParams from 'domain/coupon/ICouponListRequestParams';
import { fetchCouponSchemas } from 'api/couponSchema';
import ICouponListResponse from 'domain/coupon/ICouponListResponse';
import ICouponSchemaData from 'domain/coupon/couponSchema/ICouponSchemaData';
import { IssueMethod } from 'domain/coupon/couponSchema/IssueMethod';
import 'infra/NumberExtension'

const convertToTableData = (domain: ICouponSchemaData): ICouponSchemeTableData => {
  const discountKind = domain.discount.kind === 'Amount' ? '정액할인' : '';
  const discountFunction = `${domain.discount.amount.appendThousandComma()}원(최소 ${domain.usageRestriction.minimumOrderAmount.appendThousandComma()}원)`;

  const inclusiveStartTime = moment(domain.validityPeriod.inclusiveStartTimeUtc)
    .utc()
    .toDate();
  const exclusiveEndTimeUtc = moment(domain.validityPeriod.exclusiveEndTimeUtc)
    .utc()
    .toDate();

  const periodFormat = 'YYYY-MM-DD HH:mm';
  const formattedInclusiveStartTime = moment(inclusiveStartTime)
    .local()
    .format(periodFormat);
  const formattedExclusiveEndTimeUtc = moment(exclusiveEndTimeUtc)
    .local()
    .format(periodFormat);

  const IssueMethodEnumToText = function() {
    if (domain.issueMethod) {
      switch (domain.issueMethod) {
        case IssueMethod.Clip:
          return '다운로드';
        case IssueMethod.Present:
          return '지급';
        default:
          return '미지원';
      }
    } else {
      return '다운로드';
    }
  };

  const offeringPeriod = `${formattedInclusiveStartTime}~${formattedExclusiveEndTimeUtc}`;
  return {
    key: domain.id,
    id: domain.id,
    sequence: domain.sequence,
    name: domain.name,
    displayName: domain.displayName,
    applicableItem: '예약',
    discountKind: discountKind,
    discountFunction: discountFunction,
    offeringPeriod: offeringPeriod,
    coverage: '전상품',
    issueTarget: '전회원',
    issueMethod: IssueMethodEnumToText(),
  };
};

const convertToPagination = (
  request: ICouponListRequestParams,
  total: number,
  handlePageChange: (page: number, pageSize?: number) => void,
): IPagination => {
  return {
    total: total,
    current: request.skip / request.take + 1,
    pageSize: request.take,
    showSizeChanger: false,
    onShowSizeChange: () => {
    },
    onChange: handlePageChange,
  };
};

const CouponSchemaContainer: FC = () => {
  const [tableData, setTableData] = useState<ICouponSchemeTableData[]>([]);
  const [tablePagination, setTablePagination] = useState<IPagination>(initialPagination);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [registrationModalVisible, setRegistrationModalVisible] = useState(false);

  const fetchList = async (request: ICouponListRequestParams) => {
    setTableLoading(true);
    try {
      const result = await fetchCouponSchemas(request);
      const response: ICouponListResponse<ICouponSchemaData> = result.data;
      const formatted: ICouponSchemeTableData[] = response ? response.items.map(x => convertToTableData(x)) : [];
      const pagination: IPagination = response
        ? convertToPagination(request, response.total, handlePageChange)
        : initialPagination;
      setTableData(formatted);
      setTablePagination(pagination);
    } catch (e) {
      console.log(e);
    }
    setTableLoading(false);
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    const take = pageSize ? pageSize : 10;
    const skip = (page - 1) * take;
    fetchList({ skip: skip, take: take });
  };

  const handleShowModal = () => {
    setRegistrationModalVisible(true);
  };

  const handleCancel = () => {
    setRegistrationModalVisible(false);
  };

  useEffect(() => {
    fetchList({ skip: 0, take: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableProps: ICouponSchemeTableProps = {
    source: tableData,
    pagination: tablePagination,
    loading: tableLoading,
  };

  return (
    <CouponSchema
      tableProps={tableProps}
      showModal={handleShowModal}
      modalVisible={registrationModalVisible}
      handleCancel={handleCancel}
      fetchList={fetchList}
    />
  );
};

export default CouponSchemaContainer;
