import {Button, Modal} from "antd";
import React from "react";
import {ICancellationPolicy} from "domain/reservation/onda/IOndaReservation";
import OndaReservationCancellationPolicyModalTable
  from "views/components/Reservations/Onda/Modal/OndaReservationCancellationPolicyModalTable";

export interface IOndaReservationCancellationPolicyModalProp {
  visible: boolean;
  content: ICancellationPolicy | null;
  handleCancel: () => void;
}

function OndaReservationCancellationPolicyModal(
  {
    visible,
    content,
    handleCancel
  }: IOndaReservationCancellationPolicyModalProp
) {
  if (content !== null) {
    return (
      <Modal
        title="취소 수수료"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <OndaReservationCancellationPolicyModalTable source={content.penalties}/>
      </Modal>
    );
  } else {
    return <div></div>
  }
}

export default OndaReservationCancellationPolicyModal;
