import React from 'react';
import ITheme from 'domain/preReservationV2/ITheme';
import env from '../../../../../infra/envMap';

const ThemeTableColumns = () => {
  const columns = [
    {
      key: 'id',
      title: '상품목록코드',
      dataIndex: 'theme',
      render: (theme: ITheme) => (
        <a href={`${env.tourWebHost}/overseas/theme/${theme.id}`}>{theme.id}</a>
      ),
    },
    {
      key: 'displayName',
      title: '이름',
      dataIndex: 'theme',
      render: (theme: ITheme) => `${theme.displayName}`,
    },
    {
      key: 'isActive',
      title: 'isActive',
      dataIndex: 'theme',
      render: (theme: ITheme) => `${theme.isActive}`,
    },
  ];

  return columns;
};

export default ThemeTableColumns;
