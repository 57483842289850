import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';

export default function usePromise<T>(promiseCreator: Function, deps: []) {
  const [loading, setLoading] = useState<boolean>(false);
  const [resolved, setResolved] = useState<T | undefined>(undefined);
  const [error, setError] = useState<AxiosError | null>(null);

  const process = async () => {
    setLoading(true);

    try {
      const result = await promiseCreator();
      setResolved(result.data);
    } catch (err) {
      setError(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    process();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { loading, resolved, error };
}
