import ICouponListRequestParams from 'domain/coupon/ICouponListRequestParams';
import { admin } from '../infra/axios';
import {
  IAddCouponIssuePlan,
  AddCouponIssuePlanToPresent,
  AddCouponIssuePlanToClip,
} from 'domain/coupon/couponIssuePlan/AddCouponIssuePlan';
import ICouponIssuePlanChangeData from 'domain/coupon/couponIssuePlan/ICouponIssuePlanChangeData';

const fetchCouponIssuePlan = (params: ICouponListRequestParams) =>
  admin.get('/api/coupons/issue-plans', { params: params });

const addCouponIssuePlan = (addCouponIssuePlan: IAddCouponIssuePlan, couponSchemaId: string) => {
  const formData = new FormData();
  if (addCouponIssuePlan instanceof AddCouponIssuePlanToPresent) {
    formData.append('PresentationTimeUtc', addCouponIssuePlan.presentScheduleUtc);
    formData.append('UnitOfIssue', addCouponIssuePlan.unitOfIssue.toString());
    formData.append('UsersAsExcelFile', addCouponIssuePlan.userExcel);
  } else if (addCouponIssuePlan instanceof AddCouponIssuePlanToClip) {
    formData.append('IssuePeriod.InclusiveStartTimeUtc', addCouponIssuePlan.issuePeriod.inclusiveStartTimeUtc);
    formData.append('IssuePeriod.exclusiveEndTimeUtc', addCouponIssuePlan.issuePeriod.exclusiveEndTimeUtc);
    formData.append('MaximumIssueCount', addCouponIssuePlan.maximumIssueCount.toString());
    formData.append('UnitOfIssue', addCouponIssuePlan.unitOfIssue.toString());
  } else {
    throw '미지원 기능입니다.';
  }

  const header = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
    type: 'formData',
  };

  return admin.post(`/api/coupons/schemas/${couponSchemaId}/execute-command/add-coupon-issue-plan`, formData, {
    headers: header,
  });
};

const changeCouponIssuePlan = (parameters: ICouponIssuePlanChangeData, couponSchemaId: string) =>
  admin.post(`/api/coupons/schemas/${couponSchemaId}/execute-command/change-coupon-issue-plan-properties`, parameters);

const abortCouponIssuePlan = (couponSchemaId: string, couponIssuePlanId: string) =>
  admin.post(`/api/coupons/schemas/${couponSchemaId}/execute-command/abort-coupon-issue-plan`, {
    issuePlanId: couponIssuePlanId,
  });

const deleteCouponIssuePlan = (couponSchemaId: string, couponIssuePlanId: string) =>
  admin.post(`/api/coupons/schemas/${couponSchemaId}/execute-command/delete-coupon-issue-plan`, {
    issuePlanId: couponIssuePlanId,
  });

export { fetchCouponIssuePlan, addCouponIssuePlan, changeCouponIssuePlan, abortCouponIssuePlan, deleteCouponIssuePlan };
