import YouTubeExcelTemplate from 'domain/top10/accommodation/template/YouTubeExcelTemplate';
import AccommodationsExcelTemplate from 'domain/top10/accommodation/template/AccommodationsExcelTemplate';
import Address from 'domain/top10/accommodation/template/excel/Address';
import Location from 'domain/top10/accommodation/template/excel/Location';
import Locality from 'domain/top10/accommodation/template/excel/Locality';
import Photos from 'domain/top10/accommodation/template/excel/Photos';
import ExternalMedia from 'domain/top10/accommodation/template/excel/ExternalMedia';
import YouTube from 'domain/top10/accommodation/template/excel/YouTube';
import Classification from 'domain/top10/accommodation/template/excel/Classification';
import CheckInCheckOutTimes from 'domain/top10/accommodation/template/excel/CheckInCheckOutTimes';
import Benefits from 'domain/top10/accommodation/template/excel/Benefits';

export interface ITop10AccommodationInsertData {
  displayName: string;
  dataSource: DataSourceType;
  address: Address;
  location: Location;
  locality: Locality;
  photos: Photos;
  classifications: Classification[];
  checkInCheckOutTimes: CheckInCheckOutTimes;
  externalMedia: ExternalMedia;
  benefits: Benefits;
  notice: string;
}

class Top10AccommodationInsertData implements ITop10AccommodationInsertData {
  displayName: string;
  dataSource: DataSourceType;
  address: Address;
  location: Location;
  locality: Locality;
  photos: Photos;
  classifications: Classification[];
  checkInCheckOutTimes: CheckInCheckOutTimes;
  externalMedia: ExternalMedia;
  benefits: Benefits;
  notice: string;

  constructor(excelData: string[]) {
    const excelTemplateData = new AccommodationsExcelTemplate(excelData);
    this.displayName = excelTemplateData.displayName;
    this.dataSource = this.getDataSource(
      excelTemplateData.agodaCode,
      excelTemplateData.ondaCode,
    );
    this.address = new Address(
      excelTemplateData.country,
      excelTemplateData.state,
      excelTemplateData.city,
      excelTemplateData.area,
      excelTemplateData.street,
    );
    this.location = new Location(
      excelTemplateData.latitude,
      excelTemplateData.longitude,
    );
    this.locality = new Locality(
      excelTemplateData.customaryLocalityGroupId,
      excelTemplateData.customaryLocalityId,
    );
    this.photos = new Photos(
      Photos.constructListPhotos(
        excelTemplateData.listPhotoUrl01,
        excelTemplateData.listPhotoUrl02,
        excelTemplateData.listPhotoUrl03,
        excelTemplateData.listPhotoUrl04,
        excelTemplateData.listPhotoUrl05,
      ),
      Photos.constructHeaderPhotos(
        excelTemplateData.headerPhotoUrl01,
        excelTemplateData.headerPhotoUrl02,
        excelTemplateData.headerPhotoUrl03,
      ),
    );
    this.classifications = [
      new Classification(excelTemplateData.classificationDisplayName),
    ];
    this.checkInCheckOutTimes = new CheckInCheckOutTimes(
      excelTemplateData.checkIn,
      excelTemplateData.checkOut,
    );
    this.externalMedia = new ExternalMedia(
      YouTube.of(
        new YouTubeExcelTemplate(
          excelTemplateData.youtubeId01,
          excelTemplateData.youtubeChannel01,
        ),
        new YouTubeExcelTemplate(
          excelTemplateData.youtubeId02,
          excelTemplateData.youtubeChannel02,
        ),
        new YouTubeExcelTemplate(
          excelTemplateData.youtubeId03,
          excelTemplateData.youtubeChannel03,
        ),
      ),
    );

    this.benefits = new Benefits(
      Benefits.constructBenefitsFacilities(
        excelTemplateData.facilityDisplayName01,
        excelTemplateData.facilityDisplayName02,
        excelTemplateData.facilityDisplayName03,
        excelTemplateData.facilityDisplayName04,
        excelTemplateData.facilityDisplayName05,
        excelTemplateData.facilityDisplayName06,
        excelTemplateData.facilityDisplayName07,
        excelTemplateData.facilityDisplayName08,
        excelTemplateData.facilityDisplayName09,
        excelTemplateData.facilityDisplayName10,
        excelTemplateData.facilityDisplayName11,
        excelTemplateData.facilityDisplayName12,
      ),
      Benefits.constructBenefitsServices(excelTemplateData.serviceDisplayName),
      Benefits.constructBenefitsNearbyAttractions(
        excelTemplateData.nearbyAttractionDisplayName01,
        excelTemplateData.nearbyAttractionDisplayName02,
        excelTemplateData.nearbyAttractionDisplayName03,
        excelTemplateData.nearbyAttractionDisplayName04,
        excelTemplateData.nearbyAttractionDisplayName05,
        excelTemplateData.nearbyAttractionDisplayName06,
        excelTemplateData.nearbyAttractionDisplayName07,
        excelTemplateData.nearbyAttractionDisplayName08,
      ),
    );
    excelTemplateData.notice === undefined
      ? (this.notice = '')
      : (this.notice = excelTemplateData.notice);
  }

  private getDataSource(agodaCode: string, ondaCode: string) {
    const agodaAccommodationId = '5aee3161-c96e-43e1-b518-d11cd1fefcef';
    const ondaAccommodationId = '20e9e7bf-9a6a-4faf-b270-86db6bb5923a';
    let dataSource: DataSourceType = {};
    if (agodaCode) {
      dataSource[agodaAccommodationId] = {
        code: agodaCode,
      };
    }

    if (ondaAccommodationId) {
      dataSource[ondaAccommodationId] = {
        code: ondaCode,
      };
    }
    return dataSource;
  }
}

type DataSourceType = {
  [key: string]: {
    code: string;
  };
};

export default Top10AccommodationInsertData;
