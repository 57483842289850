export default function mergeObjectsInUnique<T extends object, U extends keyof T>(array: T[], property: U): T[] {
  const newArray = new Map();

  array.forEach((item: T) => {
    const propertyValue = item[property];
    newArray.has(propertyValue)
      ? newArray.set(propertyValue, { ...item, ...newArray.get(propertyValue) })
      : newArray.set(propertyValue, item);
  });

  return Array.from(newArray.values());
}
