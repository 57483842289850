import React, { FC, MouseEvent, useState, useEffect } from 'react';
import TripPlannerReservationSearchForm from '../../views/components/TripPlannerReservation/TripPlannerReservationSearchForm';
import Form, { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Input, DatePicker, Select } from 'antd';
import IListRequest from '../../domain/IListRequest';
import ITripPlannerReservationListFilter from '../../domain/tripPlannerReservation/ITripPlannerReservationListFilter';
import { fetchAgencies } from '../../api/agency';
import IAgency from '../../domain/IAgency';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface ITripPlannerSearchFormContainerProps {
  form: WrappedFormUtils;
  fetchList: (param: IListRequest<ITripPlannerReservationListFilter>) => void;
  param: IListRequest<ITripPlannerReservationListFilter>;
}
const TripPlannerSearchFormContainer: FC<ITripPlannerSearchFormContainerProps> = ({ form, fetchList, param }) => {
  const { getFieldDecorator, validateFields } = form;
  const [agencyLoading, setAgencyLoading] = useState<boolean>(false);
  const [agencyList, setAgencyList] = useState<IAgency[]>([]);

  const getAgencies = async () => {
    setAgencyLoading(true);
    try {
      const result = await fetchAgencies();
      setAgencyList(result.data);
    } catch (e) {
      console.log(e);
    }
    setAgencyLoading(false);
  };

  const formItems: { itemNode: React.ReactNode }[] = [
    {
      itemNode: getFieldDecorator('searchWord')(<Input placeholder="전화번호, 예약자명" />),
    },
    {
      itemNode: getFieldDecorator('reservationPeriod')(<RangePicker placeholder={['예약일(시작)', '예약일(종료)']} />),
    },
    {
      itemNode: getFieldDecorator('agencyId', {
        initialValue: '',
      })(
        <Select loading={agencyLoading}>
          <Option key="none" value="">
            여행사를 선택해주세요.
          </Option>
          {agencyList.map(it => (
            <Option key={it.id} value={it.id}>
              {it.id}. {it.agencyName}
            </Option>
          ))}
        </Select>,
      ),
    },
  ];

  const handleSearch = (e: MouseEvent) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const searchWord = values.searchWord;
        const agencyId = values.agencyId;
        const createdAtStart =
          values.reservationPeriod && values.reservationPeriod.length > 0
            ? values.reservationPeriod[0].format('YYYY-MM-DD')
            : '';
        const createdAtEnd =
          values.reservationPeriod && values.reservationPeriod.length > 0
            ? values.reservationPeriod[1].format('YYYY-MM-DD')
            : '';

        const changedParam: IListRequest<ITripPlannerReservationListFilter> = {
          ...param,
          filter: {
            ...param.filter,
            searchWord: searchWord,
            agencyId: agencyId,
            createdAtStart: createdAtStart,
            createdAtEnd: createdAtEnd,
          },
        };

        fetchList(changedParam);
      }
    });
  };

  useEffect(() => {
    getAgencies();
  }, []);

  return <TripPlannerReservationSearchForm formItems={formItems} handleSearch={handleSearch} />;
};

export default Form.create<ITripPlannerSearchFormContainerProps>()(TripPlannerSearchFormContainer);
