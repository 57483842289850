import React, { FC } from 'react';
import { PageHeader, Tabs } from 'antd';
import TripPlannerReservationTable, {
  ITripPlannerReservationTableProps,
} from './TripPlannerReservationTable/TripPlannerReservationTable';
import '../../styles/TripPlannerReservation/TripPlannerReservation.scss';
import TripPlannerSearchFormContainer from '../../../containers/tripPlannerReservation/TripPlannerReservationSearchFormContainer';
import ITripPlannerReservationListFilter from '../../../domain/tripPlannerReservation/ITripPlannerReservationListFilter';
import IListRequest from '../../../domain/IListRequest';
import {
  TripPlannerReservationQuestionModal,
  TripPlannerReservationClipBoardModal,
} from './TripPlannerReservationModal';
import TripPlannerHistoryModalContainer, {
  ITripPlannerHistoryModalContainerProp,
} from '../../../containers/tripPlannerReservation/TripPlannerReservationHistoryModalContainer';
import { ITripPlannerReservationQuestionModalProp } from './TripPlannerReservationModal/TripPlannerReservationQuestionModal';
import { ITripPlannerReservationClipboardModalProp } from './TripPlannerReservationModal/TripPlannerReservationClipBoardModal';

const { TabPane } = Tabs;

interface ITripPlannerReservationProps {
  tableProps: ITripPlannerReservationTableProps;
  handleTabChange: (key: string) => void;
  fetchList: (param?: IListRequest<ITripPlannerReservationListFilter>) => void;
  param: IListRequest<ITripPlannerReservationListFilter>;
  questionModalProps: ITripPlannerReservationQuestionModalProp;
  clipBoardModalProps: ITripPlannerReservationClipboardModalProp;
  historyModalProps: ITripPlannerHistoryModalContainerProp;
}

interface ITripPlanStatus {
  name: string;
  label: string;
}

const list: Array<ITripPlanStatus> = [
  {
    name: 'APPLY',
    label: '접수',
  },
  {
    name: 'COUNSEL',
    label: '상담',
  },
  {
    name: 'HOLD',
    label: '보류',
  },
  {
    name: 'SUCCESS',
    label: '처리',
  },
  {
    name: '',
    label: '전체',
  },
];

const TripPlannerReservation: FC<ITripPlannerReservationProps> = ({
  tableProps,
  handleTabChange,
  fetchList,
  param,
  questionModalProps,
  clipBoardModalProps,
  historyModalProps,
}) => {
  return (
    <div className="TripPlannerReservation">
      <PageHeader className="page-header" title={<h1>트립플래너 예약</h1>} />
      <TripPlannerSearchFormContainer fetchList={fetchList} param={param} />
      <Tabs className="page-inner-nav" defaultActiveKey="APPLY" onChange={handleTabChange}>
        {list.map(it => (
          <TabPane tab={it.label} key={it.name}></TabPane>
        ))}
      </Tabs>
      <TripPlannerReservationTable {...tableProps} />
      <TripPlannerReservationQuestionModal {...questionModalProps} />
      <TripPlannerReservationClipBoardModal {...clipBoardModalProps} />
      <TripPlannerHistoryModalContainer {...historyModalProps} />
    </div>
  );
};

export default TripPlannerReservation;
