import React, { FC, useCallback, useState } from 'react';
import { Table, Tag, message, Row, Col, Typography, InputNumber, Button, Modal, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import { getPackageSequenceId, addSpecialPricingPolicy } from 'api/specialPricing';
import env from 'infra/envMap';
import useWindowDimensions from 'infra/hooks/useWindowDimensions';
import { IPackage } from 'domain/Package';

interface ISpecialPricingSearchResultProps {
  source: IPackage[];
  loading: { getPackages: boolean };
  saveCheckedItems: (checkedItems: string[]) => void;
  checkedItems: string[];
}

interface ISearchResultTableView {
  key: string;
  id: string;
  title: string;
  badge: string | null;
  packageCode: string;
  packagePlanCode: string;
  supplierName: string;
  destinations: string[];
  adultListPrice: number;
  adultSellingPrice: number;
  departureDateTime: Date;
  guideWillAccompany: string;
  availStatus: AvailStatus;
  hasSpecialOffer: boolean;
}

enum AvailStatus {
  'DepartureGuaranteed' = '출발가능',
  'ReservationAvailable' = '예약가능',
  'Disabled' = '예약마감',
}

enum Guide {
  with = '동행',
  without = '미동행',
}

const SpecialPricingSearchResult: FC<ISpecialPricingSearchResultProps> = ({
  source,
  loading,
  saveCheckedItems,
  checkedItems,
}) => {
  const { height } = useWindowDimensions();
  const [state, setState] = useState<{ discountedAmount?: number | null }>({ discountedAmount: null });

  const formatTableSource = useCallback((): ISearchResultTableView[] => {
    return source.map(x => ({
      key: x.id,
      id: x.id,
      title: x.title,
      badge: x.specialOffer ? x.specialOffer.specialPricingPolicy.pricingStrategy.badges[0].label : null,
      packageCode: x.packageCode,
      packagePlanCode: x.packagePlanCode,
      supplierName: x.supplier.name,
      destinations: x.destinations.map(y => y.name),
      adultListPrice: x.listPrices.adultPrice,
      adultSellingPrice: x.specialOffer ? x.specialOffer.prices.sellingPrices.adultPrice : x.listPrices.adultPrice,
      departureDateTime: new Date(x.departureTimeLocal),
      guideWillAccompany: x.companions.guideWillAccompany ? Guide.with : Guide.without,
      availStatus: x.availability.isAvailable
        ? x.availability.departureGuaranteed
          ? AvailStatus.DepartureGuaranteed
          : AvailStatus.ReservationAvailable
        : AvailStatus.Disabled,
      hasSpecialOffer: x.specialOffer !== null,
    }));
  }, [source]);

  const handleTitleClick = useCallback(async (uuid: string) => {
    try {
      const res = await getPackageSequenceId(uuid);
      window.open(`${env.mobileWebHost}/detail/${res.data.id}`);
    } catch (err) {
      message.error(`행사 정보를 찾을 수 없습니다. ID: ${uuid}`);
      console.log(err);
    }
  }, []);

  const showConfirm = (discountedAmount: number) => {
    Modal.confirm({
      title: '특가를 등록하시겠습니까?',
      style: { maxWidth: 320, top: '30%' },
      icon: <ExclamationCircleOutlined />,
      okText: '확인',
      cancelText: '취소',
      onOk: async () => {
        try {
          const res = await addSpecialPricingPolicy(checkedItems, discountedAmount);
          notification.success({
            message: '특가가 성공적으로 등록되었습니다.',
          });
        } catch (err) {
          console.log(err);
        }
      },
      onCancel() {},
    });
  };

  return (
    <Table<ISearchResultTableView>
      dataSource={formatTableSource()}
      loading={loading.getPackages}
      pagination={false}
      size="middle"
      scroll={{ y: height - 132 - 360 }}
      rowSelection={{
        type: 'checkbox',
        columnWidth: '60px',
        onChange: (_, selectedRows: ISearchResultTableView[]) => {
          saveCheckedItems(selectedRows.map((x: ISearchResultTableView) => x.id));
        },
      }}
      footer={() => (
        <Row gutter={6} justify="end" align="middle">
          <Col>
            <Typography.Text strong>할인금</Typography.Text>
          </Col>
          <Col span={3}>
            <InputNumber
              onChange={v => setState(prev => ({ ...prev, discountedAmount: v ? Number(v) : null }))}
              style={{ width: '100%' }}
              min={0}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value!.replace(/(,*)/g, '')}
            />
          </Col>
          <Col>
            <Button
              disabled={checkedItems.length === 0 || !state.discountedAmount || loading.getPackages}
              loading={false}
              onClick={() => state.discountedAmount && showConfirm(state.discountedAmount)}
            >
              등록
            </Button>
          </Col>
        </Row>
      )}
    >
      <Table.Column
        title="제목"
        dataIndex="title"
        key="title"
        width="20%"
        render={(title: string, record: ISearchResultTableView) => (
          <>
            <a onClick={() => handleTitleClick(record.id)}>{title}</a>{' '}
            {record.hasSpecialOffer ? <Tag color="red">{record.badge}</Tag> : null}
          </>
        )}
      />
      <Table.Column title="상품코드" dataIndex="packagePlanCode" key="packagePlanCode" />
      <Table.Column title="행사코드" dataIndex="packageCode" key="packageCode" />
      <Table.Column title="여행사" dataIndex="supplierName" key="supplierName" />
      <Table.Column
        title="여행지"
        dataIndex="destinations"
        key="destinations"
        render={destinations =>
          destinations.map((x: string, i: number) => (
            <Tag key={`${x}-${i}`} color="blue" style={{ margin: 1, fontSize: '0.7rem', padding: '0 0.25rem' }}>
              {x}
            </Tag>
          ))
        }
      />
      <Table.Column
        title="정가"
        dataIndex="adultListPrice"
        key="adultListPrice"
        sorter={(left: ISearchResultTableView, right: ISearchResultTableView) =>
          left.adultListPrice - right.adultListPrice
        }
        sortDirections={['ascend', 'descend']}
        defaultSortOrder="ascend"
        render={(price: number) => price.appendThousandComma()}
      />
      <Table.Column
        title="판매가"
        dataIndex="adultSellingPrice"
        key="adultSellingPrice"
        sorter={(left: ISearchResultTableView, right: ISearchResultTableView) =>
          left.adultSellingPrice - right.adultSellingPrice
        }
        sortDirections={['ascend', 'descend']}
        render={(price: number, record: ISearchResultTableView) => (
          <Typography.Text {...(record.hasSpecialOffer ? { type: 'danger' } : {})}>
            {price.appendThousandComma()}
          </Typography.Text>
        )}
      />
      <Table.Column
        title="출발일"
        dataIndex="departureDateTime"
        key="departureDateTime"
        render={(date: Date) => format(date, 'yyyy-MM-dd')}
        sorter={(left: ISearchResultTableView, right: ISearchResultTableView) => {
          if (isBefore(left.departureDateTime, right.departureDateTime)) return -1;
          if (isAfter(left.departureDateTime, right.departureDateTime)) return 1;
          return 0;
        }}
        sortDirections={['ascend', 'descend']}
      />
      <Table.Column title="가이드" dataIndex="guideWillAccompany" key="guideWillAccompany" />
      <Table.Column
        title="상태"
        dataIndex="availStatus"
        key="availStatus"
        sorter={(left: ISearchResultTableView, right: ISearchResultTableView) =>
          left.availStatus.localeCompare(right.availStatus)
        }
        sortDirections={['ascend', 'descend']}
      />
    </Table>
  );
};

export default SpecialPricingSearchResult;
