import { IReservation } from 'domain/reservation/onda/IOndaReservation';
import { Table } from 'antd';
import React from 'react';
import { IOndaReservationTableData } from 'views/components/Reservations/Onda/table/OndaReservationTable';
import MaskText, { MaskType } from 'views/utils/MaskText';

export interface IOndaReservationDetailsModalTableProps {
  source: IOndaReservationTableData;
}

function OndaReservationDetailsModalTable({
  source,
}: IOndaReservationDetailsModalTableProps) {
  function OndaReservationDetailsModalTableColumns() {
    const columns = [
      {
        key: 'address',
        title: '지역',
        dataIndex: 'reservation',
        render: (reservation: IReservation) => {
          return `(${reservation.accommodation.address.state})${reservation.accommodation.address.city}`;
        },
      },
      {
        key: 'bookerEmail',
        title: '예약자 이메일',
        dataIndex: 'reservation',
        render: (reservation: IReservation) => {
          return (
            <MaskText
              maskType={MaskType.EMAIL}
              text={reservation.booker.email}
            />
          );
        },
      },
      {
        key: 'headCount',
        title: '인원',
        dataIndex: 'reservation',
        render: (reservation: IReservation) =>
          `성인 ${reservation.guest.adultCount}명, 아동 ${reservation.guest.childCount}명`,
      },
      {
        key: 'checkIn',
        title: '체크인 날짜',
        dataIndex: 'reservation',
        render: (reservation: IReservation) => `${reservation.checkIn}`,
      },
      {
        key: 'checkOut',
        title: '체크아웃 날짜',
        dataIndex: 'reservation',
        render: (reservation: IReservation) => `${reservation.checkOut}`,
      },
      {
        key: 'listPrice',
        title: '객실요금',
        dataIndex: 'reservation',
        render: (reservation: IReservation) =>
          `${reservation.pricing.listPrice.appendThousandComma()}`,
      },
      {
        key: 'discountPrice',
        title: '할인요금',
        dataIndex: 'reservation',
        render: (reservation: IReservation) => {
          return (
            reservation.pricing.listPrice - reservation.pricing.sellingPrice
          ).appendThousandComma();
        },
      },
      {
        key: 'couponDiscountPrice',
        title: '쿠폰할인요금 ',
        dataIndex: 'reservation',
        render: (reservation: IReservation) => {
          return reservation.coupons
            .map((it) => it.discountAmount)
            .reduce((a, b) => a + b, 0)
            .appendThousandComma();
        },
      },
      {
        key: 'cancellationRequesterType',
        title: '취소주체',
        dataIndex: 'reservation',
        render: (reservation: IReservation) => {
          if (reservation.cancellation !== null) {
            return `${reservation.cancellation.requesterType}`;
          }
        },
      },
    ];
    return columns;
  }

  return (
    <Table
      columns={OndaReservationDetailsModalTableColumns()}
      dataSource={[source]}
      pagination={false}
    />
  );
}

export default OndaReservationDetailsModalTable;
