import IPagination from '../../../../types/IPagination';
import React, { FC } from 'react';
import { Button, Col, Form, Input, Row, Table } from 'antd';
import IListRequest from 'domain/IListRequest';
import ProductTableColumns from './ProductTableColumns';
import IProduct from 'domain/preReservationV2/IProduct';
import { PlusOutlined } from '@ant-design/icons';
import IProductsFilter from 'domain/preReservationV2/IProductsFilter';
import { Link } from 'react-router-dom';

export interface IProductTableData {
  key: string;
  product: IProduct;
}

export interface IProductTableProps {
  source: IProductTableData[];
  pagination: IPagination;
  tableLoading: boolean;
  fetchList: (param: IListRequest<IProductsFilter>) => void;
}

const ProductTable: FC<IProductTableProps> = ({
  source,
  pagination,
  tableLoading,
  fetchList,
}) => {
  const columns = ProductTableColumns();
  const [form] = Form.useForm();
  async function handleSearch() {
    try {
      const values = await form.validateFields();
      const pageRequest: IListRequest<IProductsFilter> = {
        filter: {
          id: values.id,
          displayName: values.displayName,
          place: values.place,
        },
      };
      fetchList(pageRequest);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Form
        form={form}
        className="PreReservationV2ProductSearchForm"
        onFinish={handleSearch}
        initialValues={{
          id: '',
          displayName: '',
          place: '',
        }}
        style={{ marginBottom: 30 }}
      >
        <Row gutter={4} justify="end" align="top">
          <Form.Item name="id" style={{ margin: 3.5 }}>
            <Input placeholder="상품코드" />
          </Form.Item>
          <Form.Item name="displayName" style={{ margin: 3.5 }}>
            <Input placeholder="상품명" />
          </Form.Item>
          <Form.Item name="place" style={{ margin: 3.5 }}>
            <Input placeholder="여행지" />
          </Form.Item>
          <Col>
            <Button type="primary" htmlType="submit" style={{ margin: 3.5 }}>
              검색
            </Button>
          </Col>
          <Col className="btn-add" style={{ marginLeft: 'auto', order: 2 }}>
            <Link to="/pre-reservation-v2/products/create">
              <Button icon={<PlusOutlined />}>상품 등록</Button>
            </Link>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={source}
        pagination={pagination}
        loading={tableLoading}
      />
    </>
  );
};

export default ProductTable;
