import React, { FC } from 'react';
import { PageHeader } from 'antd';
import OndaReservationTable, {
  IOndaReservationTableProps,
} from './table/OndaReservationTable';
import OndaReservationSearchForm from 'views/components/Reservations/Onda/OndaReservationSearchForm';
import IListRequest from 'domain/IListRequest';
import IOndaReservationsFilter from 'domain/reservation/onda/IOndaReservationsFilter';
import OndaReservationCancellationPolicyModal, {
  IOndaReservationCancellationPolicyModalProp,
} from 'views/components/Reservations/Onda/Modal/OndaReservationCancellationPolicyModal';
import OndaReservationDetailsModal, {
  IOndaReservationDetailsModalProp,
} from 'views/components/Reservations/Onda/Modal/OndaReservationDetailsModal';

interface IOndaReservationProps {
  tableProps: IOndaReservationTableProps;
  fetchList: (param: IListRequest<IOndaReservationsFilter>) => void;
  param: IListRequest<IOndaReservationsFilter>;
  cancellationPolicyModalProps: IOndaReservationCancellationPolicyModalProp;
  detailsModalProps: IOndaReservationDetailsModalProp;
}

const OndaReservation: FC<IOndaReservationProps> = ({
  tableProps,
  fetchList,
  param,
  cancellationPolicyModalProps,
  detailsModalProps,
}) => {
  return (
    <div className="OndaReservation">
      <PageHeader className="page-header" title={<h1>국내숙소 예약</h1>} />
      <OndaReservationTable {...tableProps} />
      <OndaReservationCancellationPolicyModal
        {...cancellationPolicyModalProps}
      />
      <OndaReservationDetailsModal {...detailsModalProps} />
    </div>
  );
};

export default OndaReservation;
