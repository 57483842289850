import React, { FC, MouseEvent } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Row, Col, Select, DatePicker, Button } from 'antd';

const { RangePicker } = DatePicker;

interface CouponIssuePlanSearchFormProps {
  handleRegistrationModalShow: (e?: MouseEvent) => void;
}

const CouponIssuePlanSearchForm: FC<CouponIssuePlanSearchFormProps> = ({ handleRegistrationModalShow }) => {
  return (
    <Form className="search-box">
      <Row gutter={4}>
        <Col span={2}>
          <Select defaultValue="검색조건" disabled />
        </Col>
        <Col span={2}>
          <Select defaultValue="기간조건" disabled />
        </Col>
        <Col span={4}>
          <RangePicker placeholder={['시작일', '종료일']} disabled />
        </Col>
        <Col span={2}>
          <Select defaultValue="상태" disabled />
        </Col>
        <Col span={2}>
          <Button type="primary" icon={<SearchOutlined />} disabled>
            검색
          </Button>
        </Col>
        <Col className="btn-add" style={{ marginLeft: 'auto', order: 2 }}>
          <Button icon={<PlusOutlined />} onClick={handleRegistrationModalShow}>
            쿠폰발급 계획 등록
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CouponIssuePlanSearchForm;
