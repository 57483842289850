import { Button } from 'antd';
import { IOptionForm } from 'domain/preReservationV2/IProductCreateRequest';
import React, {
  createRef,
  forwardRef,
  RefObject,
  useImperativeHandle,
  useState,
} from 'react';
import '../../../styles/Overseas/PreReservationV2/ProductCreate.scss';
import OptionForm, { IOptionFormRefObject } from './OptionForm';

export interface IOptionSectionRefObject {
  getForm: () => Promise<IOptionForm[]>;
}

const OptionSection = forwardRef(({}, ref) => {
  const [options, setOptions] = useState<RefObject<IOptionFormRefObject>[]>([]);

  const handleAddOption = () => {
    setOptions([...options, createRef()]);
  };

  useImperativeHandle(ref, () => ({
    async getForm(): Promise<IOptionForm[]> {
      return Promise.all(
        options
          .filter(it => it.current !== undefined)
          .map(async it => await it.current!!.getForm()),
      );
    },
  }));

  const handleDeleteOption = (ref: RefObject<IOptionFormRefObject>) => {
    setOptions(options.filter(it => it !== ref));
  };

  return (
    <>
      {options?.map((value, index) => {
        return (
          <OptionForm
            index={index}
            key={index}
            ref={value}
            handleDelete={handleDeleteOption}
          />
        );
      })}

      <div className="center">
        <Button size="large" onClick={handleAddOption}>
          상품추가
        </Button>
      </div>
    </>
  );
});

export default OptionSection;
