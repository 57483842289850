const v2AdminHost = process.env.REACT_APP_V2_ADMIN_HOST;

export default [
  {
    title: '대시보드',
    key: 'dashboard',
    href: `${v2AdminHost}/dashboard`,
  },
  {
    title: '사용자관리',
    children: [
      {
        title: '사용자 목록',
        key: 'users?filter%5Brole%5D=ADMIN',
        href: `${v2AdminHost}/users?filter%5Brole%5D=ADMIN`,
      },
      {
        title: '앱 푸시',
        key: 'users/app-push',
        href: `${v2AdminHost}/users/app-push`,
      },
    ],
  },
  {
    title: '메인 관리',
    children: [
      {
        title: '홈 카테고리 관리',
        key: 'main/categories',
        href: `${v2AdminHost}/main/categories`,
      },
      {
        title: '커스텀 리스트 관리',
        key: 'main/categories/item',
        href: `${v2AdminHost}/main/categories/item`,
      },
      {
        title: '홈 레이아웃 관리',
        key: 'main/homeLayouts',
        href: `${v2AdminHost}/main/homeLayouts`,
      },
    ],
  },
  {
    title: '사전예약',
    children: [
      {
        title: '상품목록관리',
        key: 'pre-reservation-v2/themes',
        href: `/pre-reservation-v2/themes`,
      },
      {
        title: '상품관리',
        key: 'pre-reservation-v2/products',
        href: `/pre-reservation-v2/products`,
      },
      {
        title: '옵션관리',
        key: 'pre-reservation-v2/options',
        href: `/pre-reservation-v2/options`,
      },
    ],
  },
  {
    title: '상품관리',
    children: [
      {
        title: '상품관리',
        children: [
          {
            title: '상품관리',
            key: 'packages',
            href: `${v2AdminHost}/packages`,
          },
          {
            title: 'TS쿼리 생성',
            key: 'packages/tsquery',
            href: `${v2AdminHost}/packages/tsquery`,
          },
          {
            title: '상품 관리 ID조회',
            key: 'packages/ids',
            href: `${v2AdminHost}/packages/ids`,
          },
          {
            title: '재크롤링',
            key: 'packages/recrawling',
            href: `${v2AdminHost}/packages/recrawling`,
          },
          {
            title: '상태관리',
            key: 'statusManaging',
            href: `${v2AdminHost}/packages/statusManaging`,
          },
        ],
      },
      {
        title: '테마관리',
        children: [
          {
            title: '테마설정',
            key: 'packages/themes/settings',
            href: `${v2AdminHost}/packages/themes/settings`,
          },
          {
            title: '테마목록',
            key: 'theme',
            href: `${v2AdminHost}/packages/themes/list`,
          },
        ],
      },
      {
        title: '이벤트 라벨 관리',
        children: [
          {
            title: '테마설정',
            key: 'packages/eventLabels/settings',
            href: `${v2AdminHost}/packages/eventLabels/settings`,
          },
          {
            title: '이벤트라벨 설정 목록',
            key: 'packages/eventLabels/list',
            href: `${v2AdminHost}/packages/eventLabels/list`,
          },
        ],
      },
      {
        title: '월별 혜택 관리',
        children: [
          {
            title: '월 별 혜택 설정',
            key: 'benefits/0',
            href: `${v2AdminHost}/benefits/0`,
          },
          {
            title: '월 별 혜택 목록',
            key: 'benefits',
            href: `${v2AdminHost}/benefits`,
          },
        ],
      },
      {
        title: '특가관리',
        key: 'packages/pricePolicies',
        href: `${v2AdminHost}/packages/pricePolicies`,
      },
      {
        title: '특가관리(new)',
        key: 'packages/discountPolicies',
        href: `${v2AdminHost}/packages/discountPolicies`,
      },
      {
        title: '광고상품관리',
        key: 'advertising',
        href: `${v2AdminHost}/advertising`,
      },
      {
        title: '출발임박특가',
        key: 'packages/imminent-departure-manages',
        href: `${v2AdminHost}/packages/imminent-departure-manages`,
      },
      {
        title: '예약전용상품',
        key: 'packages/reservation-only',
        href: `${v2AdminHost}/packages/reservation-only`,
      },
    ],
  },
  {
    title: '쿠폰관리',
    children: [
      {
        title: '쿠폰발급 계획',
        key: 'coupon-issue-plan',
        link: '/coupon-issue-plan',
      },
      {
        title: '쿠폰스키마',
        key: 'coupon-scheme',
        link: '/coupon-scheme',
      },
    ],
  },
  // {
  //   title: '특가정책',
  //   children: [
  //     {
  //       title: '특가정책 등록',
  //       key: 'special-pricing-registration',
  //       link: '/special-pricing/registration',
  //     },
  //   ],
  // },
  {
    title: '예약관리',
    children: [
      {
        title: '간편예약',
        key: 'reserves',
        href: `${v2AdminHost}/reserves`,
      },
      {
        title: '간편예약 API',
        key: 'reserves/sended',
        href: `${v2AdminHost}/reserves/sended`,
      },
      {
        title: '예약 상태',
        children: [
          {
            title: '노랑풍선 예약',
            key: 'reserves/agencies',
            href: `${v2AdminHost}/reserves/agencies`,
          },
          {
            title: '온필 예약',
            key: 'reserves/agency/18',
            href: `${v2AdminHost}/reserves/agency/18`,
          },
          {
            title: '여행박사 예약',
            key: 'reserves/agency/21',
            href: `${v2AdminHost}/reserves/agency/21`,
          },
          {
            title: '한진관광 예약',
            key: 'reserves/agency/23',
            href: `${v2AdminHost}/reserves/agency/23`,
          },
          {
            title: '자유투어 예약',
            key: 'reserves/agency/11',
            href: `${v2AdminHost}/reserves/agency/11`,
          },
          {
            title: '웹투어 예약',
            key: 'reserves/agency/17',
            href: `${v2AdminHost}/reserves/agency/17`,
          },
          {
            title: '에어텔닷컴 예약',
            key: 'reserves/agency/10',
            href: `${v2AdminHost}/reserves/agency/10`,
          },
        ],
      },
      {
        title: '예약하기',
        key: 'cs/packages',
        href: `${v2AdminHost}/cs/packages`,
      },
      {
        title: '예약관리',
        key: 'cs/reserves',
        href: `${v2AdminHost}/cs/reserves`,
      },
      {
        title: '예약상담',
        key: 'cs/reserves/directs',
        href: `${v2AdminHost}/cs/reserves/directs`,
      },
      {
        title: '예약 전용',
        key: 'packages/reservation-only/reservations',
        href: `${v2AdminHost}/packages/reservation-only/reservations`,
      },
      {
        title: '트립플래너 예약',
        key: 'trip-planner-reservation',
        href: `/trip-planner-reservation`,
      },
      {
        title: '국내숙소 예약',
        key: 'reservations/onda',
        href: `/reservations/onda`,
      },
    ],
  },
  {
    title: 'TOP10관리',
    children: [
      {
        title: '숙소관리',
        key: 'top10/accommodations',
        href: `/top10/accommodations`,
      },
      {
        title: '순위관리',
        key: 'top10/weekly-ranked-sheet',
        href: `/top10/weekly-ranked-sheet`,
      },
    ],
  },
  {
    title: '정산관리',
    children: [
      {
        title: '예약내역 관리',
        key: 'accounts',
        href: `${v2AdminHost}/accounts`,
      },
      {
        title: '판매거래현황',
        children: [
          {
            title: '기준정보',
            key: 'accounts/daily/config',
            href: `${v2AdminHost}/accounts/daily/config`,
          },
          {
            title: '업데이트',
            key: 'accounts/daily/update',
            href: `${v2AdminHost}/accounts/daily/update`,
          },
          {
            title: '다운로드',
            key: 'accounts/daily/download',
            href: `${v2AdminHost}/accounts/daily/download`,
          },
        ],
      },
    ],
  },
  {
    title: '문의하기',
    children: [
      {
        title: '문의하기',
        key: 'cs/inquiry?status=APPLY',
        href: `${v2AdminHost}/cs/inquiry?status=APPLY`,
      },
      {
        title: '상품신고',
        key: 'userReports',
        href: `${v2AdminHost}/userReports`,
      },
      {
        title: '알림톡 발송',
        key: 'cs/alarmTalk/histories',
        href: `${v2AdminHost}/cs/alarmTalk/histories`,
      },
      {
        title: '단체여행 문의',
        key: 'cs/inquiry/groups',
        href: `${v2AdminHost}/cs/inquiry/groups`,
      },
      {
        title: '템플릿 관리',
        key: 'cs/inquiry/templates',
        href: `${v2AdminHost}/cs/inquiry/templates`,
      },
    ],
  },
  {
    title: '여행지관리',
    children: [
      {
        title: '여행지 목록',
        key: 'places',
        href: `${v2AdminHost}/places`,
      },
      {
        title: '국가 목록',
        key: 'places/countries',
        href: `${v2AdminHost}/places/countries`,
      },
      {
        title: '여행준비 알림톡',
        key: 'travelGuidAlarmHistories',
        href: `${v2AdminHost}/travelGuidAlarmHistories`,
      },
    ],
  },
  {
    title: '콘텐츠 관리',
    children: [
      {
        title: '배너 관리',
        key: 'contents/banners',
        href: `${v2AdminHost}/contents/banners`,
      },
      {
        title: '페이지 관리',
        key: 'contents/pages',
        href: `${v2AdminHost}/contents/pages`,
      },
      {
        title: '이벤트 라벨 관리',
        key: 'contents/eventlabels',
        href: `${v2AdminHost}/contents/eventlabels`,
      },
      {
        title: '테마 관리',
        key: 'contents/themes',
        href: `${v2AdminHost}/contents/themes`,
      },
      {
        title: '팝업 관리',
        key: 'popups',
        href: `${v2AdminHost}/popups`,
      },
      {
        title: '푸시 관리',
        key: 'pushs',
        href: `${v2AdminHost}/pushs`,
      },
      {
        title: '테마 달력 관리',
        key: 'themeCalendars',
        href: `${v2AdminHost}/themeCalendars`,
      },
      {
        title: '타임세일 관리',
        key: 'timesales',
        href: `${v2AdminHost}/timesales`,
      },
      {
        title: '컨텐츠 관리',
        children: [
          {
            title: '컨텐츠 정보 관리',
            key: 'contentsManagement/contents',
            href: `${v2AdminHost}/contentsManagement/contents`,
          },
          {
            title: '패턴 정보 관리',
            key: 'contentsManagement/patterns',
            href: `${v2AdminHost}/contentsManagement/patterns`,
          },
          {
            title: '필터세트 정보 관리',
            key: 'filterSets',
            href: `${v2AdminHost}/filterSets`,
          },
        ],
      },
    ],
  },
  {
    title: '통계 보기',
    children: [
      {
        title: '아웃링크 통계',
        key: 'stat/outhref',
        href: `${v2AdminHost}/stat/outhref`,
      },
    ],
  },
  {
    title: '환경 설정',
    children: [
      {
        title: '환경 설정',
        key: 'settings',
        href: `${v2AdminHost}/settings`,
      },
      {
        title: '여행사별 설정',
        key: 'settings/agencies',
        href: `${v2AdminHost}/settings/agencies`,
      },
      {
        title: '기타 설정',
        key: 'settings/etc',
        href: `${v2AdminHost}/settings/etc`,
      },
    ],
  },
  {
    title: '기타',
    children: [
      {
        title: '전화하기 로그',
        key: 'etc/calllogs',
        href: `${v2AdminHost}/etc/calllogs`,
      },
      {
        title: '로그 관리',
        key: 'etc/logs',
        href: `${v2AdminHost}/etc/logs`,
      },
      {
        title: '스케쥴러 관리',
        key: 'etc/scheduler',
        href: `${v2AdminHost}/etc/scheduler`,
      },
      {
        title: '상품별 크롤 관리',
        key: 'etc/packageCrawlerData',
        href: `${v2AdminHost}/etc/packageCrawlerData`,
      },
      {
        title: 'VOC 전송 실패 목록',
        key: 'voc-send-fail-history',
        link: '/voc-send-fail-history',
      },
    ],
  },
];
