export default class AccommodationsExcelTemplate {
  displayName: string;
  agodaCode: string;
  ondaCode: string;
  country: string;
  state: string;
  city: string;
  area: string;
  street: string;
  latitude: string;
  longitude: string;
  customaryLocalityGroupId: string;
  customaryLocalityId: string;
  listPhotoUrl01: string;
  listPhotoUrl02: string;
  listPhotoUrl03: string;
  listPhotoUrl04: string;
  listPhotoUrl05: string;
  headerPhotoUrl01: string;
  headerPhotoUrl02: string;
  headerPhotoUrl03: string;
  classificationDisplayName: string;
  checkIn: string;
  checkOut: string;
  youtubeId01: string;
  youtubeChannel01: string;
  youtubeId02: string;
  youtubeChannel02: string;
  youtubeId03: string;
  youtubeChannel03: string;
  facilityDisplayName01: string;
  facilityDisplayName02: string;
  facilityDisplayName03: string;
  facilityDisplayName04: string;
  facilityDisplayName05: string;
  facilityDisplayName06: string;
  facilityDisplayName07: string;
  facilityDisplayName08: string;
  facilityDisplayName09: string;
  facilityDisplayName10: string;
  facilityDisplayName11: string;
  facilityDisplayName12: string;
  serviceDisplayName: string;
  nearbyAttractionDisplayName01: string;
  nearbyAttractionDisplayName02: string;
  nearbyAttractionDisplayName03: string;
  nearbyAttractionDisplayName04: string;
  nearbyAttractionDisplayName05: string;
  nearbyAttractionDisplayName06: string;
  nearbyAttractionDisplayName07: string;
  nearbyAttractionDisplayName08: string;
  notice: string;

  constructor(excelData: string[]) {
    [
      this.displayName,
      this.agodaCode,
      this.ondaCode,
      this.country,
      this.state,
      this.city,
      this.area,
      this.street,
      this.latitude,
      this.longitude,
      this.customaryLocalityGroupId,
      this.customaryLocalityId,
      this.listPhotoUrl01,
      this.listPhotoUrl02,
      this.listPhotoUrl03,
      this.listPhotoUrl04,
      this.listPhotoUrl05,
      this.headerPhotoUrl01,
      this.headerPhotoUrl02,
      this.headerPhotoUrl03,
      this.classificationDisplayName,
      this.checkIn,
      this.checkOut,
      this.youtubeId01,
      this.youtubeChannel01,
      this.youtubeId02,
      this.youtubeChannel02,
      this.youtubeId03,
      this.youtubeChannel03,
      this.facilityDisplayName01,
      this.facilityDisplayName02,
      this.facilityDisplayName03,
      this.facilityDisplayName04,
      this.facilityDisplayName05,
      this.facilityDisplayName06,
      this.facilityDisplayName07,
      this.facilityDisplayName08,
      this.facilityDisplayName09,
      this.facilityDisplayName10,
      this.facilityDisplayName11,
      this.facilityDisplayName12,
      this.serviceDisplayName,
      this.nearbyAttractionDisplayName01,
      this.nearbyAttractionDisplayName02,
      this.nearbyAttractionDisplayName03,
      this.nearbyAttractionDisplayName04,
      this.nearbyAttractionDisplayName05,
      this.nearbyAttractionDisplayName06,
      this.nearbyAttractionDisplayName07,
      this.nearbyAttractionDisplayName08,
      this.notice,
    ] = excelData;
  }
}
